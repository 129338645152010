export default {
  data: [
    {
      title: "name",
      value: "",
      model: "contactName",
      type: "text",
      mandatory: true,
      validated: true
    },
    {
      title: "contactPhone",
      value: "",
      model: "contactPhone",
      type: "text",
    },
    {
      title: "email",
      value: "",
      model: "contactEmail",
      type: "text",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        var pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        //If the inputString is NOT a match
        if (!pattern.test(newValue)) {
          inputError.value = "message.emailValidationFail";
        } else{
          inputError.value = ""
        }
      },
    },
    {
      title: "rank",
      value: "",
      model: "contactRank",
      type: "text",
    },
  ]
}