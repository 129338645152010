<template>
  <div id="WFAdmin" class="h-screen max-h-screen">
    <BaseLayout>
      <template #leftButtons> </template>

      <template #mainContent>
        <div class="h-full h-max-full mx-auto px-2">
          <div class="bg-gray-800 px-6 pt-2 pb-6 rounded-lg mb-2">
            <div class="flex justify-between">
              <h1 class="text-lg font-sans font-semibold uppercase text-white p-4">
                {{ workflowName }} - {{ $t('message.version') }}: {{ workflowVersion }}
              </h1>
              <RemUpButton remType="wf-admin" class="pt-4" />
            </div>
          </div>
          <WFAdminAccordion />
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import RemUpButton from '@/components/REM/RemUpButton.vue';
import BaseLayout from '@/layouts/BaseLayout.vue';
import WFAdminAccordion from './WFAdminAccordion.vue';
import { useRoute } from 'vue-router';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import axios from '@/misc/axios.js';
import { onMounted, ref } from 'vue';

const router = useRoute();
const taskTypeId = router.params.taskTypeId;
const workflowId = router.params.workflowId;

const workflowName = ref(null);
const workflowVersion = ref(null);

const getWorkflowById = async () => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}`;

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        getWorkflowById();
      },
    });
    workflowName.value = response.data.name;
    workflowVersion.value = response.data.version;
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getWorkflowById();
});
</script>

<style scoped></style>
