const messages = {
  hu: {
    message: {
      creation: "Létrehozás",
      DATA_MODIFIED: "Adatmódosítás",
      STATE_TRANSITION: "Állapotátmenet",
      REMARK_MODIFIED: "Megjegyzések",
      DOCUMENTS_MODIFIED: "Dokumentumok",
      LINKS_MODIFIED: "Kapcsolódó ügyek",
      SYSTEM_EVENT: "Rendszeresemények",
      PriorityHigh: "Magas",
      PriorityLow: "Alacsony",
      PriorityMedium: "Közepes",
      WfBuildingData: 'Épület adatai',
      WfPremiseData: 'Helyiség adatai',
      WfPropertyData: 'Ingatlan adatai',
      abortCommentCreate: "Biztosan megszakítja a megjegyzés rögzítését?",
      abortCommentEdit: "Biztosan megszakítja a megjegyzés módosítását?",
      abortTaskAssign: "Biztosan megszakítja az ügy(ek) kiosztását?",
      abortTaskCreate: "Biztosan megszakítja az ügy létrehozását?",
      abortTaskEdit: "Biztosan megszakítja az ügy szerkesztését?",
      accountNumber: "Számlaszám",
      accounting: "Elszámolás",
      actions: "Műveletek",
      activate: "Aktiválás",
      activity: "Aktivitás",
      adapterConfig: "Adapter konfiguráció",
      adapterType: "Adapter típusa",
      add: "Hozzáadás",
      addDevice: "Eszköz hozzáadása",
      addLegalSituation: "Új jogi helyzet",
      addMetaData: "Metaadat hozzáadása",
      addNew: "Új hozzáadása",
      addNewAddress: "Új cím",
      addNewAggregation: "Új aggregáció hozzáadása",
      addNewBuilding: "Új épület hozzáadása",
      addNewContact: "Új kapcsolattartó felvétele",
      addNewMeter: "Új mérőóra",
      addNewPartner: "Partner hozzáadása",
      addNewPartnerType: "Partnertípus hozzáadása",
      addNewProperty: "Új ingatlan hozzáadása",
      addNewSiteDesign: "Új hozzáadása",
      addNewState: "Új állapot rögzítése",
      addNewUtilization: "Új hasznosítás",
      addPremise: "Új helyiség hozzáadása",
      addTaskType: "Ügytípus létrehozása",
      additionalInfo: "Kiegészítő adatok",
      address: "Cím",
      addressKind: "Cím fajtája",
      addressType: "Cím típus",
      addresses: "Címek",
      admin: "Admin",
      aggregationFunction: "Térbeli aggregációs függvény",
      aggregationNamePlaceholder: "pl. Aggregáció neve",
      aggregations: "Aggregációk",
      airConditionerOutdoorUnit: "Klíma kültéri egység",
      allRelatedPartner: "Minden kapcsolódó partner",
      allRelatedPartners: "Minden kapcsolódó partner",
      annualPerformance: "Éves teljesítmény (kW)",
      anonymus: "Névtelen",
      approvalDate: "Jóváhagyás dátuma",
      approvalDates: "Jóváhagyás dátuma",
      approvers: "Jóváhagyók",
      areaSize: "Alapterület (m2)",
      areas: "Areák",
      assetManager: "Vagyongazda",
      assignNew: "Új hozzárendelése",
      atleastOneCharacter: "Min. 1 karakter...",
      atomicFunction: "Időbeli aggregációs függvény",
      attach: "Hozzárendelés",
      attic: "Tetőtér van",
      atticCanBeUsed: "Hasznosítható",
      back: "Vissza",
      backToTheMap: "Vissza a térképhez",
      baseDatas: "Adatok",
      batteryCharge: "Akkumulátor töltöttsége",
      batteryInstallDate: "Akkumulátor telepítésének ideje",
      bearingMasonry: "Teherhordó falazat",
      boolean: "Boolean",
      breakdown: "Bontás",
      building: "Épület",
      buildingDeleteConfirm: "Biztos, hogy törölni szeretné ezt az épületet",
      buildingMarking: "Épület jelölése",
      buildingMarkingHelperText: "Alaprajz szerinti.",
      buildingMaxHeight: "Épület legnagyobb magassága",
      buildingPagePremiseNumber: "Helyiségjelölő szám",
      buildingPartnerModeSwitch:
        'Váltás a partner módok között (Kizárólag az épület vagy az épület és a hozzátartozó helyiségek partnereinek megjelenítése)',
      buildingPartners: 'Épülethez kapcsolódó partnerek',
      buildings: 'Épületek',
      builtInFireProtection: 'Kiépített tűzvédelem',
      businessType: 'Business type',
      canBeControlled: 'Vezérelhető',
      canBeCooled: 'Hűthető',
      canBeHeated: 'Fűthető',
      canBePhased: 'Szakaszolható',
      cancel: 'Mégse',
      cannotDeleteOptionWithCode:
        'Az érték nem törölhető, mert az alkalmazás működését befolyásolja',
      capabilities: 'Képességek',
      centralAirConditioner: 'Központi klíma',
      centralHeating: 'Központi fűtés',
      changed_this_field: 'megváltoztatta: ',
      choose: 'Kiválasztás',
      chooseLang: 'Válasszon nyelvet: ',
      choosingMandatory: ' kiválasztása kötelező',
      city: 'Település',
      classification: 'Besorolás',
      cleaner: 'Takarítás',
      close: 'BEZÁRÁS',
      code: 'Kód',
      codeFHelperText: 'F-es kód',
      commType: 'Kommunikáció típusa',
      comment: 'Megjegyzés',
      comments: 'Megjegyzések',
      company: 'Cég',
      confirm: 'Igen',
      confirmRemove: 'Biztos benne, hogy törli az eszközt',
      confirmRemoveLS: 'Biztos törölni szeretné ezt a jogi helyzetet',
      confirmRemovePartner: 'Biztos benne, hogy törli a partnert',
      confirmRemovePremise: 'Biztos benne, hogy törölni akarja ezt a helyiséget',
      confirmRemoveScenario: 'Biztos benne, hogy törli a scenariot',
      contactPhone: 'Telefonszám',
      contacts: 'Kapcsolattartók',
      contractIdentifier: 'Szerződésszám',
      contractingParty: 'Energia átadó partner',
      controlled: 'Vezérelt',
      cooling: 'Hűtő',
      coolingHeatingDevice: 'Hűtő-fűtő eszköz',
      coolingHeatingDevices: 'Hűtő-fűtő eszközök',
      coolingPerformance: 'Hűtőteljesítmény (kW)',
      copiedToClipboard: 'Másolva a vágólapra',
      copy: 'Másolás',
      country: 'Ország',
      county: 'Vármegye',
      create: 'Létrehozás',
      createdAt: 'Kezdő dátum',
      createdBy: 'Létrehozó',
      cremClassification: 'CREM besorolás',
      currency: 'Pénznem',
      currentPresenceGist: 'Aktuális jelenlét',
      data: 'Adatok',
      dataAndMtResp: 'Alapadatok és MT felelősök',
      dataOfPartner: 'Partner adatai',
      dataType: 'Adattípus',
      date: 'Dátum',
      dateOfOrigin: 'Kelt',
      day: 'nap',
      deadline: 'Határidő',
      deattach: 'Összerendelés megszüntetése',
      deattachConfirm: 'Biztos benne, hogy megszünteti a hozzárendelést',
      deattachCouldNotBeFinishedDueTo:
        'A hozzárendelés megszüntetése sikertelen. A partner az alábbi helyen használatban van: %s',
      deattachNotFound:
        'Az összerendelés megszüntetése sikertelen, az összerendelés nem található.',
      deattachNotSuccessful: 'A hozzárendelés megszüntetése sikertelen.',
      deattachSuccessful: 'Sikeresen megszüntette az összerendelést.',
      dedicatedOrgUnit: 'Dedikált szervezeti egység',
      delete: 'Törlés',
      deleteAddressConfirm: 'Biztos, hogy törölni szeretné ezt a címet',
      deleteCFConfirm: 'Biztos törölni szeretné ezt a custom fieldet?',
      deleteCommentConfirm: 'Biztosan törölni szeretné az ügyhöz rögzített megjegyzést?',
      deleteConfirm: 'Biztos, hogy törölni szeretné a rekordot',
      deleteDocumentConfirm: 'Biztos, hogy törölni szeretné ezt a dokumentumot',
      deleteLegalSituation: 'Jogi helyzet törlése',
      deleteLegalSituationConfirm: 'Biztos, hogy törölni szeretné ezt a jogi helyzetet',
      deleteMetaDataConfirm: 'Biztos, hogy törölni szeretné ezt a meta adatot?',
      deleteMeterConfirm: 'Biztos, hogy törölni szeretné ezt a mérőórát',
      deleteNotSuccessful: 'Sikertelen törlés...',
      deleteObligationConfirm: 'Biztos, hogy törölni szeretné ezt a kötelezettséget',
      deletePartner: 'Partner törlése',
      deleteStateConfirm: 'Biztos, hogy törölni szeretné ezt az állapotot?',
      deleteSuccessful: 'Sikeres törlés...',
      deleteTransitionConfirm: 'Biztos, hogy törölni szeretné ez az transitiont?',
      deleteUtilizationConfirm: 'Biztos, hogy törölni szeretné ezt a hasznosítást',
      deny: 'Mégse',
      description: 'Leírás',
      designation: 'Megnevezés',
      details: 'Részletek',
      device: 'Eszköz',
      deviceMap: 'Eszköztérkép',
      deviceNamePlaceholder: 'pl. Eszköz neve',
      deviceType: 'Eszköz típusa',
      dieselGenerator: 'Telepített dízel ellátás van',
      dieselGeneratorTitle: 'Telepített dízel ellátás',
      displayName: 'Megjelenítendő név',
      displayable: 'Megjeleníthetőség',
      district: 'Kerület',
      document: 'Dokumentum',
      documents: 'Dokumentumok',
      door: 'Ajtó',
      doorType: 'Ajtók típusa',
      double: 'Double',
      download: 'Letöltés',
      downloadEkrReport: 'EKR riport letöltése',
      edit: 'Szerkesztés',
      editAggregation: 'Aggregáció szerkesztése',
      editDevice: 'Eszköz szerkesztése',
      editDocument: 'Dokumentum szerkesztése',
      editMetaData: 'Metaadat szerkesztése',
      editMeter: 'Mérőóra szerkesztése',
      editPartner: 'Partner szerkesztése',
      editPremise: 'Helyiség szerkesztése',
      editScenario: 'Szkenárió szerkesztése',
      editSiteDesign: 'Szerkesztés',
      editUtilization: 'Hasznosítás szerkesztése',
      editZone: 'Zóna szerkesztése',
      editable: 'Szerkeszthetőség',
      egNumPlaceholder: 'pl. 100',
      electricityBasePricePerGj: 'Áram egységár ((Ft vagy €)/GJ)',
      electricityCostPerKwh: 'Áram költség ((Ft vagy €)/kWh)',
      electricityCostPerSqm: 'Áram havi költség ((Ft vagy €)/m2/hó)',
      elevator: 'Lift',
      elevatorType: 'Lift típusa',
      email: 'E-mail',
      emailValidationFail: 'Hibás e-mail formátum',
      empty: 'Üres',
      emptyFieldSave: 'Üres mező mentése nem lehetséges.',
      endDate: 'Záró dátum',
      endState: 'Cél állapot',
      energetics: 'Energetika',
      energyIntake: 'Energiafelvétel (kWh)',
      energyTradingPartner: 'Energiakereskedő Partner',
      english: 'Angol',
      entryNoteWarning: 'Maximum 2000 karakter adható meg',
      error: 'Hiba történt',
      errorAlt: 'Az oldal frissítése talán megoldja a problémát',
      evaluation: 'Véleményezés',
      events: 'Előzmények',
      expiration: 'Lejárati Idő',
      expirationDate: 'Lejárati idő',
      externalIdentifier: 'Külső azonosító',
      false: 'Nem',
      fenestration: 'Nyílászáró',
      fileFormatError: 'Érvénytelen formátum: ',
      fileFormatError2: ' fájlformátumú dokumentum feltöltésére van lehetőség.',
      fileName: 'Fájlnév',
      fileSizeError:
        'A feltöltendő fájl mérete meghaladja a feltölthető fájlok maximális méretét (1Gb)',
      fileType: 'Fájltípus',
      fireProtection: 'Tűzvédelem',
      fireProtectionClass: 'Tűzvédelmi besorolás',
      first_approval_date: 'Jóváhagyás dátuma',
      first_approval_user_form: 'Elsődleges jóváhagyó',
      first_approval_user_id: 'Jóváhagyó',
      flatRoof: 'Lapostető szerkezet',
      floc: 'FLOC Azonosító',
      floor: 'Emelet',
      floorArea: 'Alapterület (m2)',
      floorCovering: 'Burkolat',
      floors: 'Szintek',
      floorsAboveSurface: 'Emeletek száma a felszín felett',
      floorsBelowSurface: 'Emeletek száma a felszín alatt',
      foundList: 'Találtai lista',
      foundation: 'Alapozás',
      fromState: 'Kiinduló állapot',
      fuelCellGenerator: 'Üzemanyag cellás generátor van',
      fuelCellGeneratorTitle: 'Üzemanyag cellás generátor',
      function: 'Funkció',
      generateApiKeyButtonTitle: 'Új API kulcs generálása',
      generateApiKeyExample: 'Példa',
      generateApiKeyTitle: 'API kulcs',
      gist: 'Gist',
      globalSiteDesignNotFound: 'Site design nem található, frissítse ezt az adaptert.',
      goBack: 'Visszalépés',
      gpsCoordinatesLatitude: 'GPS koordináta - szélesség',
      gpsCoordinatesLongitude: 'GPS koordináta - hosszúság',
      groupName: 'Csoport név',
      guarantee: 'Garancia',
      heating: 'Fűtő',
      heatingBasePricePerGj: 'Távhő egységár ((Ft vagy €)/GJ)',
      heatingCostPerSqm: 'Távhő havi költség ((Ft vagy €)/m2/hó)',
      heatingPerformance: 'Fűtőteljesítmény (kW)',
      hour: 'óra',
      houseNumber: 'Házszám',
      hungarian: 'Magyar',
      identifier: 'Azonosító',
      infos: 'Infók',
      inputComponent: 'Bemeneti komponens',
      installTime: 'Telepítés ideje',
      installationDate: 'Üzembe helyezés ideje',
      insulation: 'Hőszigetelés',
      insulationMaterial: 'Szigetelés anyaga',
      insulationTime: 'Szigetelés ideje',
      integerOnly: 'Egész számot adjon meg',
      invalidMapView: 'A betöltött térkép adatai nem megfelelőek.',
      inverterManufacturer: 'Inverter gyártója',
      inverterPerformance: 'Inverter teljesítménye (kW)',
      inverterType: 'Inverter típusa',
      isAccessible: 'Akadálymentesített',
      isActive: 'Aktív',
      isCentral: 'Központi',
      isDedicated: 'Dedikált szervezeti egység',
      isEmergencyElevator: 'Mentő lift',
      isEscapeRoute: 'Menekülési útvonal van',
      isFalseCeiling: 'Álmennyezet',
      isFalseFloor: 'Álpadló',
      isSmokeFree: 'Füstmentes',
      kng: 'KING',
      latitude: 'Szélesség',
      legalRelationship: 'Alapviszony jellege',
      legalRelationshipEnd: 'Jogviszony vége',
      legalRelationshipStart: 'Jogviszony kezdete',
      legalRelationshipUtilization: 'Hasznosítás jogviszonya',
      legalSituation: 'Jogi helyzet',
      legalSituationEnd: 'Jogviszony vége',
      legalSituationStart: 'Jogviszony kezdete',
      legalSituations: 'Jogi helyzetek',
      lengthWarning: 'Érvénytelen formátum, {0} számjegy megadása kötelező',
      level: 'Szint',
      levels: 'Szintek',
      lidarView: 'LIDAR Nézet',
      liquidCoolerCoolingPerformance: 'Folyadékhűtő hűtési teljesítmény',
      liquidCoolerMaxElectricPerformance: 'Folyadékhűtő elektromos teljesítménye maximum',
      listOfDevices: 'Eszközök listája',
      listOfMetaData: 'Metaadatok listája',
      loadCapacity: 'Teherbírás',
      loadedAreaHasNoCoordinates:
        'A betöltött területnek nincsenek meghatározott földrajzi koordinátái.',
      loading: 'Betöltés...',
      loginAgain: 'Belépek újra',
      logoutInProgress: 'Kijelentkezés folyamatban...',
      longitude: 'Hosszúság',
      mailboxNumber: 'Postafiók száma',
      mainFunction: 'Fő funkció',
      mainType: 'Főtípus',
      maintainedBy: 'Karbantartást végzi (szervezeti egység)',
      maintenanceCycle: 'Karbantartási ciklus (hónap)',
      mandatoryField: ' mező kitöltése kötelező',
      mandatoryFieldSignal: 'A *-gal jelölt mezők kitöltése kötelező',
      manufacturer: 'Gyártó',
      mapImage: 'Térkép kép',
      mapView: 'Térképnézet',
      maxEnergyIntake: 'Maximális villamosenergia felvétele (kW)',
      maxHeight: 'Legnagyobb belmagasság (m)',
      maxLengthWarning: 'Maximum {0} karakter adható meg',
      maxValue: 'Max. érték',
      messages: 'Szövegezés',
      meterPartnerType: 'Szerződő fél',
      meterType: 'Mérőóra fajta',
      meters: 'Mérőórák',
      minHeight: 'Legkisebb belmagasság (m)',
      minValue: 'Min. érték',
      minutes: 'perc',
      missingApiKey: 'API kulcs még nincs generálva',
      missingFormFields: 'A csillaggal jelölt mezők kitöltése kötelező.',
      missingMandatoryField: 'Hiányos kötelező mezők',
      monthlyOfficeOperationalPricePerSqm: 'Üzemeltetési díj ((Ft vagy €)/m2/hó)',
      monthlyOfficeRentPricePerSqm: 'Irodabérleti díj ((Ft vagy €)/m2/hó)',
      mtArea: 'MT releváns négyzetméter',
      mtResponsibles: 'MT-n belüli felelősök',
      multiApprStep1: 'Első szintű jóváhagyásra vár',
      multiApprStep2: 'Második színtű jóváhagyásra',
      multiple: 'Több',
      name: 'Név',
      nature: 'Jelleg',
      new: 'Új',
      newInterest: 'Új érdeklődés',
      newOptionValue: 'Új Érték',
      newScenario: 'Új szkenárió',
      nextMaintenance: 'Következő karbantartás ideje',
      no: 'Nem',
      noAppliedFilters: 'Nincs beállított szűrőfeltétel...',
      noAssignedDevice: 'Nincs hozzárendelt eszköz',
      noBuildingsFound: 'Ehhez az ingatlanhoz nem találhatók épületek',
      noDevice: 'Jelenleg nincs eszköz hozzáadva',
      noFloorsFound: 'Ehhez az épülethez nem találhatók szintek.',
      noMatchingOptions: 'Nem található a keresési kifejezéssel megegyező érték.',
      noMetaData: 'Jelenleg nincs metaadat',
      noResults: 'Nincs találat...',
      noSDChildrenFound: 'Ehhez a site designhoz nem található al site design',
      noScenarioAddedCurrently: 'Jelenleg nincs scenario hozzáadva.',
      noSiteDesign: 'Az épülethez nem tartozik NRGmap site design.',
      noSubArea: 'Ehhez a területhez nem tartozik alterület.',
      noViewImage: 'A nézethez nincs elérhető képi információ!',
      notSuccessfulSave: 'Sikertelen mentés...',
      note: 'Megjegyzés',
      notes: 'Bejutási megjegyzések',
      number: 'Szám',
      numberCanBeSeated: 'Ültethető létszám',
      numberCanBeSeatedHelperText:
        'Számított adat, a helyiség alapterületéből 3nm/fő értékkel számítva.',
      numberDedicated: 'Dedikált létszám',
      numberDedicatedHelperText:
        'A HR által, a telefonkönyvben szerepeltetve az adott ingatlanhoz rendelt kollégák létszáma.',
      numberOfElevators: 'Felvonók száma',
      numberOfPerson: 'Személyek száma',
      numberOfPossibleWorkplaces: 'Lehetséges m.áll. száma',
      numberOfPremises: 'Helyiségek száma (db szám)',
      numberOfSolarPanels: 'Napelem táblák darabszáma',
      numberOfStairways: 'Lépcsőházak száma',
      numberOfWorkplaces: 'M. áll száma',
      numberOnly: 'Számot adjon meg',
      numberSeated: 'Ültetett létszám',
      numberSeatedHelperText: 'Az adott helyiségben kialakított munkaállomások száma.',
      obligation: 'Kötelezettség',
      obligations: 'Tulajdoni lap III. része',
      obliged: 'Kötelezett',
      offGrid: 'Szigetüzem',
      officeSpaceRate: 'Irodatér arány (%)',
      oneEurToCurrency: 'Euro pénznem arány',
      open: 'Megnyitás',
      openCustomFields: 'Minden custom field',
      openStates: 'Állapotok megnyitása',
      openableWindow: 'Nyitható ablak van',
      operation: 'Üzemeltetés',
      optionAdminEditorLink: 'Adminisztráció',
      optionEditorLink: 'Szerkesztés',
      optionEditorPage: 'Értékkészlet kezelés',
      optionValue: 'Értékkészlet',
      optionValueDuplicateTr: 'Ez a fordítás már létezik',
      optionValueTokenEmpty: 'Nincs megadott érték',
      optionsNumber: 'Opciók száma',
      order: 'Sorszám',
      otherRight: 'Bejegyzés/Feljegyzés',
      outdoorUnit: 'Klíma kültéri egység',
      outgoingIPAddress: 'Kimenő IP cím',
      ownerOfAssets: 'Eszközgazda',
      ownership: 'Tulajdoni hányad',
      parcelNumber: 'Helyrajzi szám',
      parent: 'Szülő',
      partner: 'Partner',
      partners: 'Partnerek',
      paymentObligations: 'Fizetési kötelezettség',
      performance: 'Teljesítmény',
      performanceOfSolarPanels: 'Napelem tábla teljesítménye (kW)',
      performanceTransfer: 'Teljesítmény átadás',
      permission: 'Jogosult',
      permissionDenied: 'Hozzáférés megtagadva',
      personnel: 'Létszám adat',
      placement: 'Elhelyezés',
      podIdentifier: 'POD azonosító',
      populationData: 'LÉTSZÁM ADATOK',
      position: 'Pozíció',
      positiveNumberOnly: 'Pozitív számot adjon meg',
      postal: 'Irányítószám',
      postalError: 'Hibás irányítószám',
      postalRegex: 'A megyék és települések megjelenítéséhez töltse ki az irányítószám mezőt!',
      powerIntake: 'Áramfelvétel (kW)',
      powerProduced: 'Napi termelt teljesítmény (kW)',
      premise: 'Helyiség',
      premiseNumber: 'Helyiségjelölő szám',
      premises: 'Helyiségek',
      preview: 'Előnézet',
      primary: 'Elsődleges',
      primaryAddress: 'Elsődleges cím',
      priority: 'Prioritás',
      privateIndividual: 'Magánszemély',
      profile: 'Profil',
      propAddressError: 'Mentés előtt az elsődleges cím szekció kitöltése kötelező!',
      propellant: 'Meghajtóanyag',
      properties: 'Ingatlanok',
      property: 'Ingatlan',
      propertyData: 'Ingatlan adat',
      propertyManagementModule: 'Ingatlankezelő modul',
      propertyPartnerModeSwitch:
        'Váltás a partner módok között (Kizárólag az ingatlan vagy az ingatlan és a hozzátartozó épületek valamint helyiségek partnereinek megjelenítése)',
      propertyPartners: 'Ingatlanhoz kapcsolódó partnerek',
      propertySheetAppellation: 'Tulajdoni lap szerinti megnevezés',
      protection: 'Védettség',
      protectionNote: 'Védettség leírás',
      publicAreaName: 'Közterület neve',
      publicAreaType: 'Közterület jellege',
      publicUtilityType: 'Közmű típus',
      rain: 'Csapadék: ',
      rank: 'Beosztás',
      readingFrequency: 'Leolvasások gyakorisága',
      readings: 'Leolvasások',
      regenerateApiKeyConfirmation: 'Biztosan újra generálja az API kulcsot',
      regenerateApiKeyConfirmationCancel: 'Mégse',
      regenerateApiKeyConfirmationOk: 'Igen',
      rejected: 'Elutasítva',
      relatedTasks: 'Kapcsolódó ügyek',
      reportCreationModule: 'Riportkészítés',
      reports: 'Riportok',
      required: 'Kötelező',
      resolution: 'Bontás',
      resolvedAt: 'Visszautasítás/Lezárás dátuma',
      responders: 'Szkenáriók',
      responsible: 'Felelős',
      review: 'Áttekintés',
      reviewedInterest: 'Véleményezett érdeklődés',
      roofType: 'Tető típusa',
      roofing: 'Tetőfedés',
      'rvg-automatic-list-refresh-1-min': 'Automatikus lista frissítés percenként',
      'rvg-automatic-list-refresh-30-sec': 'Automatikus lista frissítés 30 mp-ként',
      'rvg-automatic-list-refresh-5-min': 'Automatikus lista frissítés 5 percenként',
      rvg_automatic_list_refresh_1_min: 'Automatikus lista frissítés percenként',
      rvg_automatic_list_refresh_30_sec: 'Automatikus lista frissítés 30 mp-ként',
      rvg_automatic_list_refresh_5_min: 'Automatikus lista frissítés 5 percenként',
      sap: 'SAP kód',
      sapBuildingId: 'SAP épület azonosító',
      save: 'Mentés',
      saveConfirmation: 'Menti a módosításait',
      saveError: 'A mentés közben hibák léptek fel.',
      scenarios: 'Szkenáriók',
      search: 'Keresés',
      searchGeneric: 'Általános Kereső',
      searchSiteDesign: 'Terület Kereső',
      searchZone: 'Zóna Kereső',
      searchingDevice: 'Eszköz keresése...',
      secondApprovalDate: 'Második szintű jóváhagyás dátuma',
      second_approval_date: 'Második szintű jóváhagyás dátuma',
      second_approval_user_form: 'Másodlagos jóváhagyó ',
      second_approval_user_id: 'Második szintű jóváhagyó',
      secondary: 'Másodlagos',
      select: 'Kiválasztás',
      selectInputs: 'Bemenetek kiválasztása',
      selectOutput: 'Kimenet kiválasztása',
      selectTime: 'Időpont beállítása',
      sendToOrder: 'Küldd ide',
      serialNumber: 'Mérő gyári száma',
      sessionExpired: 'A munkamenet lejárt, jelentkezzen be újra!',
      setPerPremise: 'Helyiségenkénti beállítás',
      settlement: 'Település',
      sharedUse: 'Közös használatú',
      shortName: 'Rövid név',
      shortTextWarning: 'Maximum 255 karakter adható meg',
      simpleApproval: 'Jóváhagyásra vár',
      simple_approval_user_id: 'Jóváhagyó',
      single: 'Egy',
      siteDesign: 'Site design',
      siteDesignPlaceholder: 'Hozzárendelt NRGmap Site Design',
      slabStructClosing: 'Záró födémszerkezet',
      slabStructInter: 'Közbenső födémszerkezet',
      slabSubStructClosing: 'Záró födém altípus',
      slabSubStructInter: 'Közbenső födém altípus',
      solarPanel: 'Napelem',
      specialData: 'Speciális adatok',
      sqm: 'Terület (m2)',
      stairway: 'Lépcsőház',
      stairwayId: 'Lépcsőház azonosító',
      stairwayType: 'Lépcsőház típusa',
      startDate: 'Kezdő dátum',
      startDateLaterThanEndDate: 'A kezdő dátum értéke nem lehet kisebb, mint a záró dátum értéke',
      startState: 'Kiinduló állapot',
      state: 'Állapot',
      status: 'Státusz',
      string: 'String',
      structureTypes: 'Szerkezet típusok',
      subType: 'Altípus',
      subject: 'Tárgy',
      successfulSave: 'Sikeres mentés...',
      supplies: 'Teakonyha ellátmány',
      syncSites: 'Frissítése',
      system: 'Rendszer',
      szepaClass: 'SZEPA besorolás',
      tankSize: 'Tartály mérete',
      taskAssinment: 'Kiosztás',
      taskCreatedAt: 'Létrehozás dátuma',
      taskListTitle: 'Ügylistázó',
      taskMainTitle_: 'Ügy adatlap',
      taskMainTitle_edit: 'Ügy szerkesztése',
      taskMainTitle_new: 'Ügy létrehozása',
      taskMainTitle_newfromlist: 'Ügy létrehozása',
      taskMainTitle_read: 'Ügy áttekintése',
      taskRelationType: 'Kapcsolat típusa',
      taskToRelate: 'Kapcsolandó ügy',
      taskType: 'Ügytípus',
      taskTypes: 'Ügytípusok',
      taskUpdatedAt: 'Utolsó módosítás dátuma',
      tasks: 'Ügyek',
      tasksTitle: 'Ügykezelés',
      taxId: 'Adóazonosító jel',
      taxNumber: 'Adószám',
      tcafm: 'TCAFM kód',
      technicalData: 'Műszaki adatok',
      temperatureData: 'HŐMÉRSÉKLETI ADATOK',
      temperatureMap: 'Hőmérsékleti térkép',
      test: 'Teszt',
      toState: 'Cél állapot',
      tooLongTaxNumber: 'Túl hosszú adószám',
      tooShortTaxNumber: 'Túl rövid adószám',
      translations: 'Fordítások',
      true: 'Igen',
      tui: 'TU',
      type: 'Típus',
      typeGistName: 'Kérem kezdje el beírni a gist nevét!',
      unauthorizedTenant: 'Az Ön szervezete számára nem engedélyezett az alkalmazás használata!',
      updateAggregationGists: 'Aggregációs eszköz lista frissítése',
      updateContact: 'Kapcsolattartó adatainak szerkesztése',
      updateLegalSituation: 'Jogi helyzet szerkesztése',
      updateProperty: 'Ingatlan szerkesztése',
      updatedAt: 'Frissítve',
      upload: 'Feltöltés',
      uploadDate: 'Feltöltés dátuma',
      uploadFile: 'Fájl feltöltése',
      uploader: 'Feltöltő',
      ups: 'Szünetmentes táp',
      useOfGeneratedHeat: 'Felhasználása a keletkezett hőnek',
      usedOfficeSpaceRatePerSqm: 'Átlagosan használt irodatér (m2/fő)',
      userData: 'Felhasználói adat',
      utilization: 'Hasznosítás',
      utilizationDeleteConfirm: 'Biztos, hogy törölni szeretné ezt a hasznosítást',
      utilizations: 'Hasznosítások',
      value: 'Érték',
      valueConverterParam: 'Érték átalakító paraméter',
      valueType: 'Értéktípus',
      version: 'Verziószám',
      viewComponent: 'Megjelenítendő komponens',
      waitingForOffer: 'Ajánlatadásra vár',
      wfAddBuilding: 'Épület hozzárendelése',
      wfAddPremise: 'Helyiség hozzárendelése',
      wfAddProperty: 'Ingatlan hozzárendelése',
      wfRelatedObjects: 'Kapcsolódó objektumok',
      wfSearchResult: 'Találati lista',
      wfSelectedObjects: 'Kiválasztott objektumok',
      wfVersions: 'Workflow verziók',
      windowDepth: 'Ablak mélység (cm)',
      windowHeight: 'Ablak magasság (cm)',
      windowMaterial: 'Ablakok anyaga',
      windowNumber: 'Ablakok száma',
      windowSize: 'Ablakok mérete',
      windowWidth: 'Ablak szélesség (cm)',
      windows: 'Ablakok',
      withGiveDataNoPartnerFound: 'A megadott adatokkal nem található partner a rendszerben.',
      workflowActionCode: 'Kód',
      workflowAdmin: 'Ügykezelés admin',
      workflowIdentifier: 'Ügy azonosítója',
      workflowMetaData: 'Ügykezelés metadata',
      workflowName: 'Workflow név',
      workflowVersion: 'Workflow verzió',
      yearOfManufacture: 'Gyártási év',
      yes: 'Igen',
      zoneAdmin: 'Zóna admin',
      zoneGroups: 'Zónacsoportok',
      zoneMap: 'Zónatérkép',
      zoneName: 'A zóna neve',
      zoneNamePlaceholder: 'pl. Folyosó',
      zoneType: 'Zóna típusa',
      inactiveLabel: ' (inaktív)',

    },
  },
  en: {
    message: {
      DATA_MODIFIED: "Data modifications",
      STATE_TRANSITION: "State transitions",
      REMARK_MODIFIED: "Remark modifications",
      DOCUMENTS_MODIFIED: "Document modifications",
      LINKS_MODIFIED: "Linked tasks",
      SYSTEM_EVENT: "System events",
      PriorityHigh: 'High',
      PriorityLow: 'Low',
      PriorityMedium: 'Medium',
      WfBuildingData: 'Building data',
      WfPremiseData: 'Premise data',
      WfPropertyData: 'Property data',
      abortCommentCreate: 'Are you sure to abort comment creation?',
      abortCommentEdit: 'Are you sure to abort editing the comment?',
      abortTaskAssign: 'Are you sure to abort task assignment?',
      abortTaskCreate: "Are you sure you'd like to abort the creation of the task?",
      abortTaskEdit: "Are you sure you'd like to abort editing the task?",
      accountNumber: 'Account number',
      accounting: 'Accounting',
      actions: 'Actions',
      activate: 'Activate',
      activity: 'Activity',
      adapterConfig: 'Adapter Configuration',
      adapterType: 'Adapter Type',
      add: 'Add',
      addDevice: 'Add Device',
      addLegalSituation: 'Add legal situation',
      addMetaData: 'Add MetaData',
      addNew: 'Add new',
      addNewAddress: 'New Address',
      addNewAggregation: 'Add new Aggregation',
      addNewBuilding: 'New building',
      addNewContact: 'Add new contact',
      addNewMeter: 'New meter',
      addNewPartner: 'Add New Partner',
      addNewPartnerType: 'Add New Partner Type',
      addNewProperty: 'Add new property',
      addNewSiteDesign: 'Add New',
      addNewState: 'Add new state',
      addNewUtilization: 'New utilization',
      addPremise: 'New premise',
      addTaskType: 'New task type',
      additionalInfo: 'Additional Informations',
      address: 'Address',
      addressKind: 'Address variety',
      addressType: 'Address type',
      addresses: 'Addresses',
      admin: 'Admin',
      aggregationFunction: 'Spatial aggregation function',
      aggregationNamePlaceholder: 'eg. Aggregation Name',
      aggregations: 'Aggregations',
      airConditionerOutdoorUnit: 'Air conditioner outdoor Unit',
      allRelatedPartner: 'All related partner',
      allRelatedPartners: 'All related partners',
      annualPerformance: 'Annual Performance (kW)',
      anonymus: 'Anonymus',
      approvalDate: 'Approval date',
      approvalDates: 'Approval dates',
      approvers: 'Approvers',
      areaSize: 'Area',
      assetManager: 'Asset manager',
      assignNew: 'Assign new',
      atleastOneCharacter: 'Atleast one character...',
      atomicFunction: 'Temporal aggregation function',
      attach: 'Attach',
      attic: 'Is there an attic',
      atticCanBeUsed: 'Usable',
      back: 'Back',
      backToTheMap: 'Back to the map',
      baseDatas: 'Datas',
      batteryCharge: 'Battery charge',
      batteryInstallDate: 'Battery Install Date',
      bearingMasonry: 'Bearing masonry',
      boolean: 'Boolean',
      breakdown: 'Breakdown',
      building: 'Building',
      buildingDeleteConfirm: 'Are you sure you want to delete this building',
      buildingMarking: 'Building marking',
      buildingMarkingHelperText: 'According to the floor plan.',
      buildingMaxHeight: 'Max height of the building',
      buildingPagePremiseNumber: 'Premise identification number',
      buildingPartnerModeSwitch:
        "Switching between partner modes (Only building related partners or the partners related to any entity what's connected to this building)",
      buildingPartners: 'Partners connected to this building',
      buildings: 'Buildings',
      builtInFireProtection: 'Built in fire protection',
      businessType: 'Business type',
      canBeControlled: 'Can be controlled',
      canBeCooled: 'Can be cooled',
      canBeHeated: 'Can be heated',
      canBePhased: 'Can Be Phased',
      cancel: 'Cancel',
      cannotDeleteOptionWithCode:
        'The value cannot be deleted because it affects the behavior of the application.',
      capabilities: 'Capabilities',
      centralAirConditioner: 'Central air conditioner',
      centralHeating: 'Central heating',
      changed_this_field: 'changed filed: ',
      choose: 'Choose',
      chooseLang: 'Choose Language: ',
      choosingMandatory: ' choosing is mandatory',
      city: 'City',
      classification: 'Classification',
      cleaner: 'Cleaning',
      close: 'Close',
      code: 'Code',
      codeFHelperText: 'Code beginning with F',
      commType: 'Communication type',
      comment: 'Comment',
      comments: 'Comments',
      company: 'Company',
      confirm: 'Confirm',
      confirmRemove: 'Are you sure that you want to remove this device',
      confirmRemoveLS: 'Are you sure you want to delete this legal situation',
      confirmRemovePartner: 'Are you sure you want to delete this partner',
      confirmRemovePremise: 'Are you sure you want to delete this premise',
      confirmRemoveScenario: 'Are you sure that you want to remove this scenario',
      contactPhone: 'Phone',
      contacts: 'Contacts',
      contractIdentifier: 'Contract identifier',
      contractingParty: 'Energy transfer partner',
      controlled: 'Controlled',
      cooling: 'Cooling',
      coolingHeatingDevice: 'Cooling-Heating Device',
      coolingHeatingDevices: 'Cooling-heating devices',
      coolingPerformance: 'Cooling performance',
      copiedToClipboard: 'Copied to clipboard',
      copy: 'Copy',
      country: 'Country',
      county: 'County',
      create: 'Create',
      createdAt: 'Created at',
      createdBy: '',
      cremClassification: 'CREM classification',
      currency: 'Currency',
      currentPresenceGist: 'Current presence',
      data: 'Data',
      dataAndMtResp: 'Base data and MT Responsibles',
      dataOfPartner: 'Data of partner',
      dataType: 'Data type',
      date: 'Date',
      dateOfOrigin: 'Date of origin',
      day: 'day',
      deadline: 'Deadline',
      deattach: 'Deattach',
      deattachConfirm: 'Are you sure you want to deattach this partner',
      deattachCouldNotBeFinishedDueTo:
        'Deattach could not be finished due to the partner is used at: %s',
      deattachNotFound: 'Deattach was not successful, because the connection was not found',
      deattachNotSuccessful: 'Deattach not successful',
      deattachSuccessful: 'Deattach successful',
      dedicatedOrgUnit: 'Dedicated Organization Unit',
      delete: 'Delete',
      deleteAddressConfirm: 'Are you sure you want to delete this address',
      deleteCFConfirm: 'Are you sure you want to delete this custom field?',
      deleteCommentConfirm: 'Are you sure to delete the comment?',
      deleteConfirm: 'Are you sure you want to delete the record',
      deleteDocumentConfirm: 'Are you sure you want to delete this document',
      deleteLegalSituation: 'Delete legal situation',
      deleteLegalSituationConfirm: 'Are you sure you want to delete this legal situation',
      deleteMetaDataConfirm: 'Are you sure you want to delete this metadata?',
      deleteMeterConfirm: 'Are you sure you want to delete this meter-',
      deleteNotSuccessful: 'Delete was not successful',
      deleteObligationConfirm: 'Are you sure you want to delete the obligation',
      deletePartner: 'Delete partner',
      deleteStateConfirm: 'Are you sure you want to delete this state?',
      deleteSuccessful: 'Delete successful',
      deleteTransitionConfirm: 'Are you sure you want to delete this transition?',
      deleteUtilizationConfirm: 'Are you sure you want to delete this utilization',
      deny: 'Deny',
      description: 'Description',
      designation: 'Designation',
      details: 'Details',
      device: 'Device',
      deviceMap: 'Device Map',
      deviceNamePlaceholder: 'eg. Device Name',
      deviceType: 'Device Type',
      dieselGenerator: 'Diesel Generator',
      dieselGeneratorTitle: 'Diesel Generator',
      displayName: 'Display Name',
      displayable: 'Displayable toggle',
      district: 'District',
      document: 'Document',
      documents: 'Documents',
      door: 'Door',
      doorType: 'Door type',
      double: 'Double',
      download: 'Download',
      downloadEkrReport: 'Download EKR Report',
      edit: 'Edit',
      editAggregation: 'Edit Aggregation',
      editDevice: 'Edit Device',
      editDocument: 'Edit document',
      editMetaData: 'Edit MetaData',
      editMeter: 'Edit meter',
      editPartner: 'Edit Partner',
      editPremise: 'Edit premise',
      editScenario: 'Edit Scenario',
      editSiteDesign: 'Edit',
      editUtilization: 'Edit utilization',
      editZone: 'Edit Zone',
      editable: 'Editable toggle',
      egNumPlaceholder: 'eg. 100',
      electricityBasePricePerGj: 'Electricity base price per GJ ((Ft|€)/GJ)',
      electricityCostPerKwh: 'Electricity cost per kWh ((Ft|€)/kWh)',
      electricityCostPerSqm: 'Electricity cost per sqm ((Ft|€)/m2)/hó',
      elevator: 'Elevator',
      elevatorType: 'Elevator type',
      email: 'E-mail',
      emailValidationFail: 'Invalid e-mail format',
      empty: 'Empty',
      emptyFieldSave: 'Missing value',
      endDate: 'End date',
      endState: 'End state',
      energetics: 'Energetics',
      energyIntake: 'Energy Intake (kWh)',
      energyTradingPartner: 'Energy Trading Partner',
      english: 'English',
      entryNoteWarning: 'The maximum number of characters is 2000',
      error: 'Error',
      errorAlt: 'A page refresh may solve the problem',
      evaluation: 'Evaluation',
      events: 'Events',
      expiration: 'Expiration',
      expirationDate: 'Expiration date',
      externalIdentifier: 'External Identifier',
      false: 'No',
      fenestration: 'Doors and windows',
      fileFormatError: 'Bad file format: The approved extensions are the following: ',
      fileFormatError2: '.',
      fileName: 'Filename',
      fileSizeError:
        "The size of the file to upload is exceeding the maximum size what's uploadable (1Gb).",
      fileType: 'File type',
      fireProtection: 'Fire Protection',
      fireProtectionClass: 'Fire protection class',
      first_approval_date: 'First approval date',
      first_approval_user_form: 'First Approver',
      first_approval_user_id: 'First Approver',
      flatRoof: 'Flatroof',
      floc: 'FLOC ID',
      floor: 'Floor',
      floorArea: 'Floor area',
      floorCovering: 'Floor covering',
      floors: 'Floors',
      floorsAboveSurface: 'Floors above surface',
      floorsBelowSurface: 'Floors below surface',
      foundList: 'Found list',
      foundation: 'Foundation',
      fromState: 'From state',
      fuelCellGenerator: 'Fuel Cell Generator',
      fuelCellGeneratorTitle: 'Fuel Cell Generator',
      function: 'Function',
      generateApiKeyButtonTitle: 'New API key',
      generateApiKeyExample: 'Example',
      generateApiKeyTitle: 'API key',
      gist: 'Gist',
      globalSiteDesignNotFound: 'Site design not found, please update this adapter.',
      goBack: 'Go to the previous page',
      gpsCoordinatesLatitude: 'GPS coordinates - latitude',
      gpsCoordinatesLongitude: 'GPS coordinates - longitude',
      groupName: 'Group name',
      guarantee: 'Guarantee',
      heating: 'Heating',
      heatingBasePricePerGj: 'Heating base price per GJ ((Ft|€)/GJ)',
      heatingCostPerSqm: 'Heating cost per sqm',
      heatingPerformance: 'Heating performance',
      hour: 'hour',
      houseNumber: 'House number',
      hungarian: 'Hungarian',
      identifier: 'Identifier',
      infos: 'Infos',
      inputComponent: 'Input component',
      installTime: 'Install Time',
      installationDate: 'Installation date',
      insulation: 'Insulation',
      insulationMaterial: 'Insulation material',
      insulationTime: 'Time of insulation',
      integerOnly: 'Please enter an integer',
      invalidMapView: 'The data of the loaded map is incorrect',
      inverterManufacturer: 'Inverter Manufacturer',
      inverterPerformance: 'Inverter Performance (kW)',
      inverterType: 'Inverter Type',
      isAccessible: 'Accessible',
      isActive: 'Active',
      isCentral: 'Central',
      isDedicated: 'Dedicated org unit',
      isEmergencyElevator: 'Emergency elevator',
      isEscapeRoute: 'Escape route',
      isFalseCeiling: 'False ceiling',
      isFalseFloor: 'False floor',
      isSmokeFree: 'Smoke free',
      kng: 'KING',
      latitude: 'Latitude',
      legalRelationship: 'Legal relationship type',
      legalRelationshipEnd: 'Legal relationship end',
      legalRelationshipStart: 'Legal relationship start',
      legalRelationshipUtilization: 'Lagal Relationship',
      legalSituation: 'Legal situation type',
      legalSituationEnd: 'Legal situation end date',
      legalSituationStart: 'Legal situation start date',
      legalSituations: 'Legal situations',
      lengthWarning: 'Bad format, the mandatory number of characters is {0}',
      levels: 'Levels',
      lidarView: 'LIDAR View',
      liquidCoolerCoolingPerformance: 'Liquid Cooler Cooling Performance',
      liquidCoolerMaxElectricPerformance: 'Liquid Cooler Max Electric Performance',
      listOfDevices: 'List of Devices',
      listOfMetaData: 'List of Meta Data',
      loadCapacity: 'Load capacity',
      loadedAreaHasNoCoordinates: 'The loaded area does not have geographical coordinates.',
      loading: 'Loading...',
      loginAgain: 'I will log in again',
      logoutInProgress: 'Logout in progress...',
      longitude: 'Longitude',
      mailboxNumber: 'Mailbox number',
      mainFunction: 'Main function',
      mainType: 'Main type',
      maintainedBy: 'Maintained By',
      maintenanceCycle: 'Maintenance cycle (month)',
      mandatoryField: 'field is mandatory',
      mandatoryFieldSignal: 'Please fill the mandatory fields marked with *.',
      manufacturer: 'Manufacturer',
      mapImage: 'Map Image',
      mapView: 'Map view',
      maxEnergyIntake: 'Max Energy Intake (kW)',
      maxHeight: 'Max ceiling height',
      maxLengthWarning: 'The maximum number of characters is {0}',
      maxValue: 'Max. value',
      messages: 'Text tokens',
      meterPartnerType: "Contracting partner's type",
      meterType: 'Meter type',
      meters: 'Meters',
      minHeight: 'Min ceiling height',
      minValue: 'Min. value',
      minutes: 'minutes',
      missingApiKey: 'API key is not generated yet',
      missingFormFields: 'Please fill the form correctly',
      missingMandatoryField: 'Missing mandatory fields',
      monthlyOfficeOperationalPricePerSqm:
        'Monthly office operational price per sqm ((Ft|€)/m2/hó)',
      monthlyOfficeRentPricePerSqm: 'Monthly office rent price per sqm ((Ft|€)/m2/hó)',
      mtArea: 'MT relevant sqm',
      mtResponsibles: 'MT Responsibles',
      multiApprStep1: 'Multi approval step 1',
      multiApprStep2: 'Multi approval step 2',
      multiple: 'Multiple',
      name: 'Name',
      nature: 'Nature',
      new: 'New',
      newInterest: 'New interest',
      newOptionValue: 'New value',
      newScenario: 'New scenario',
      nextMaintenance: 'Next Maintenance',
      no: 'No',
      noAppliedFilters: 'No applied filters...',
      noAssignedDevice: 'No assigned device',
      noBuildingsFound: 'There are no buildings for the property',
      noDevice: 'There is no device added right now',
      noFloorsFound: 'There are no floors for this building.',
      noMatchingOptions: 'No matching options!',
      noMetaData: 'There is no meta data',
      noResults: 'The search has no result...',
      noSDChildrenFound: 'There are no sub entity on this site design',
      noScenarioAddedCurrently: 'No scenarios configured yet.',
      noSiteDesign: "Didn't find any attached NRGmap site design.",
      noSubArea: 'There are no subareas attached to this area.',
      noViewImage: 'There is no available image for this view!',
      notSuccessfulSave: 'Save is not successful...',
      note: 'Note',
      notes: 'Entry notes',
      number: 'Number',
      numberCanBeSeated: 'Number of seats',
      numberCanBeSeatedHelperText:
        'Calculated data, based on the floor area of ​​the room with a value of 3 square meters/person.',
      numberDedicated: 'Number of dedicated seats',
      numberDedicatedHelperText:
        'The number of colleagues assigned to the given property by HR, included in the phone book.',
      numberOfElevators: 'Number of elevators',
      numberOfPerson: 'Maximum number of persons',
      numberOfPossibleWorkplaces: 'Number of possible workplaces',
      numberOfPremises: 'Number of premises',
      numberOfSolarPanels: 'Number of solar panels',
      numberOfStairways: 'Number of stairways',
      numberOfWorkplaces: 'Number of workplaces',
      numberOnly: 'Please enter a number',
      numberSeated: 'Number of seated persons',
      numberSeatedHelperText: 'The number of workstations in the given room.',
      obligation: 'Obligation',
      obligations: '3rd part of the property sheet',
      obliged: 'Obliged',
      offGrid: 'Off Grid',
      officeSpaceRate: 'Office space rate (%)',
      oneEurToCurrency: 'One EUR to currency',
      open: 'Open',
      openCustomFields: 'All custom fields',
      openStates: 'Open states',
      openableWindow: 'Openable windows',
      operation: 'Operation',
      optionAdminEditorLink: 'Administration',
      optionEditorLink: 'Edit',
      optionEditorPage: 'Option Value Editor',
      optionValue: 'Option Value',
      optionValueDuplicateTr: 'Duplicate translation',
      optionValueTokenEmpty: 'Missing token value',
      optionsNumber: 'Option Number',
      order: 'Order',
      otherRight: 'Other right name',
      outdoorUnit: 'Outdoor unit',
      outgoingIPAddress: 'Outgoing IP address',
      ownerOfAssets: 'Owner of assets',
      ownership: 'Ownership',
      parcelNumber: 'Parcel number',
      parent: 'Parent',
      partner: 'Partner',
      partners: 'Partners',
      paymentObligations: 'Payment obligations',
      performance: 'Performance',
      performanceTransfer: 'Performance Transfer',
      permission: 'Permission',
      permissionDenied: 'Permission Denied',
      personnel: 'Personnel',
      placement: 'Placement',
      podIdentifier: 'POD Identifier',
      populationData: 'POPULATION DATA',
      position: 'Position',
      postal: 'Postal code',
      postalError: 'Incorrect postalcode',
      postalRegex: 'To display the counties and settlements, fill the postal code field!',
      powerIntake: 'Power Intake (kW)',
      powerProduced: 'Daily power Produced (kW)',
      premise: 'Premise',
      premiseNumber: 'Premise number',
      premises: 'Premises',
      preview: 'Preview',
      primary: 'Primary',
      primaryAddress: 'Primary address',
      priority: 'Priority',
      privateIndividual: 'Private Individual',
      profile: 'Profile',
      propAddressError: 'Filling in the address section is mandatory before saving!',
      propellant: 'Propellant',
      properties: 'Properties',
      property: 'Property',
      propertyData: 'Property data',
      propertyManagementModule: 'Property Management Module',
      propertyPartnerModeSwitch:
        "Switching between partner modes (Only property related partners or the partners related to any entity what's connected to this property)",
      propertyPartners: 'Partners connected to this property',
      propertySheetAppellation: 'Name according to the Ownership Sheet',
      protection: 'Protection',
      protectionNote: 'Protection description',
      publicAreaName: 'Public area name',
      publicAreaType: 'Public area type',
      publicUtilityType: 'Public utility type',
      rain: 'Rain: ',
      rank: 'Rank',
      readingFrequency: 'Reading frequency',
      readings: 'Readings',
      regenerateApiKeyConfirmation: 'Are you sure you want to regenerate the API key',
      regenerateApiKeyConfirmationCancel: 'Cancel',
      regenerateApiKeyConfirmationOk: 'confirm',
      rejected: 'Rejected',
      relatedTasks: 'Related Tasks',
      reports: 'Reports',
      required: 'Required toggle',
      resolution: 'Resolution',
      resolvedAt: 'Resolved at',
      responders: 'Scenarios',
      responsible: 'Responsible',
      review: 'Review',
      reviewedInterest: 'Reviewed interest',
      roofType: 'Roof type',
      roofing: 'Roofing',
      'rvg-automatic-list-refresh-1-min': 'Automatic list refresh every minute',
      'rvg-automatic-list-refresh-30-sec': 'Automatic list refresh every 30 sec',
      'rvg-automatic-list-refresh-5-min': 'Automatic list refresh every minute',
      rvg_automatic_list_refresh_1_min: 'Automatic list refresh every minute',
      rvg_automatic_list_refresh_30_sec: 'Automatic list refresh every 30 sec',
      rvg_automatic_list_refresh_5_min: 'Automatic list refresh every minute',
      sap: 'SAP Code',
      sapBuildingId: 'SAP building identifier',
      save: 'Save',
      saveConfirmation: 'Do you want to save your modifications',
      saveError: 'Errors occured during save',
      scenarios: 'Scenarios',
      search: 'Search',
      searchGeneric: 'Generic Search',
      searchSiteDesign: 'Site Design Search',
      searchZone: 'Zone Search',
      searchingDevice: 'Searching Device...',
      secondApprovalDate: 'Secondary approval date',
      second_approval_date: 'Second approval date',
      second_approval_user_form: 'Second approver',
      second_approval_user_id: 'Second Approver',
      secondary: 'Secondary',
      select: 'Select',
      selectInputs: 'Select Inputs',
      selectOutput: 'Select Output',
      selectTime: 'Set start time',
      sendToOrder: 'Sent to order',
      serialNumber: 'Serial number',
      sessionExpired: 'The session expired, please try to log in again!',
      setPerPremise: 'Can Be Set Per Premise',
      settlement: 'Settlement',
      sharedUse: 'Shared use',
      shortName: 'Short name',
      shortTextWarning: 'The maximum number of characters is 255',
      simpleApproval: 'Simple approval',
      simple_approval_user_id: 'Approver',
      single: 'Single',
      siteDesign: 'Site design',
      siteDesignPlaceholder: 'Assigned NRGmap Site Design',
      slabStructClosing: 'Slab structure closing',
      slabStructInter: 'Slab structure intermediate',
      slabSubStructClosing: 'Slab substructure closing',
      slabSubStructInter: 'Slab substructure intermediate',
      solarPanel: 'Solar Panel',
      specialData: 'Special data',
      sqm: 'sqm',
      stairway: 'Stairway',
      stairwayId: 'Stairway ID',
      stairwayType: 'Stairway type',
      startDate: 'Start date',
      startDateLaterThanEndDate: 'Value of start date must not be later than the value of end date',
      startState: 'Start state',
      state: 'State',
      status: 'Status',
      string: 'String',
      structureTypes: 'Structure types',
      subType: 'Sub type',
      subject: 'Subject',
      successfulSave: 'Successful save...',
      supplies: 'Kitchen supplies',
      syncSites: 'Sync',
      system: 'System',
      szepaClass: 'SZEPA classification',
      tankSize: 'Tank Size',
      taskAssinment: 'Task assignment',
      taskCreatedAt: 'Created at',
      taskListTitle: 'Tasks',
      taskMainTitle_: 'Task',
      taskMainTitle_edit: 'Edit task',
      taskMainTitle_new: 'New task',
      taskMainTitle_newfromlist: 'New task',
      taskMainTitle_read: 'Task',
      taskRelationType: 'Relation type',
      taskToRelate: 'Tasks',
      taskType: 'Task type',
      taskTypes: 'Task types',
      taskUpdatedAt: 'Task updated at',
      tasks: 'Tasks',
      tasksTitle: 'Tasks',
      taxId: 'Tax ID',
      taxNumber: 'Tax number',
      tcafm: 'TCAFM code',
      technicalData: 'Technical data',
      temperatureData: 'TEMPERATURE DATA',
      temperatureMap: 'Temperature Map',
      test: 'Test',
      toState: 'To state',
      tooLongTaxNumber: 'Too long taxnumber',
      tooShortTaxNumber: 'Too short taxmunber',
      translations: 'Translations',
      true: 'Yes',
      tui: 'TU',
      type: 'Type',
      typeGistName: 'Please start typing the gist/s name!',
      unauthorizedTenant: 'The usage of this application is not allowed for your company!',
      updateAggregationGists: 'Update Aggregation Gists',
      updateContact: 'Edit contact',
      updateLegalSituation: 'Edit legal situation',
      updateProperty: 'Edit property',
      updatedAt: 'Updated at',
      upload: 'Upload',
      uploadDate: 'Upload date',
      uploadFile: 'Upload File',
      uploader: 'Uploader',
      ups: 'UPS',
      useOfGeneratedHeat: 'Use Of Generated Heat',
      userData: 'User data',
      utilization: 'Utilization',
      utilizationDeleteConfirm: 'Are you sure you want to delete this utilization',
      utilizations: 'Utilizations',
      value: 'Value',
      valueConverterParam: 'Value converter param',
      valueType: 'Value type',
      version: 'Version',
      viewComponent: 'View component',
      waitingForOffer: 'Waiting for offer',
      wfAddBuilding: 'Add building',
      wfAddPremise: 'Add premise',
      wfAddProperty: 'Add property',
      wfRelatedObjects: 'Related objects',
      wfSearchResult: 'Search result',
      wfSelectedObjects: 'Selected objects',
      wfVersions: 'Workflow versions',
      windowDepth: 'Depth',
      windowHeight: 'Height',
      windowMaterial: 'Material',
      windowNumber: 'Number of windows',
      windowSize: 'Size',
      windowWidth: 'Width',
      windows: 'Windows',
      withGiveDataNoPartnerFound: 'No partner found with the given data.',
      workflowActionCode: 'Action code',
      workflowAdmin: 'Workflow admin',
      workflowIdentifier: 'Task identifier',
      workflowMetaData: 'Workflow metadata',
      workflowName: 'Workflow name',
      workflowVersion: 'Workflow version',
      yearOfManufacture: 'Year of manufacture',
      yes: 'Yes',
      zoneAdmin: 'Zone Admin',
      zoneGroups: 'Zone groups',
      zoneMap: 'Zone Map',
      zoneName: 'The name of the zone',
      zoneNamePlaceholder: 'eg. Corridor',
      zoneType: 'Zone type',
      inactiveLabel: ' (inactive)',

    },
  },
  es: {
    message: {
      creation: "Creation",
      PriorityHigh: '',
      PriorityLow: '',
      PriorityMedium: '',
      WfBuildingData: '',
      WfPremiseData: '',
      WfPropertyData: '',
      abortCommentCreate: '',
      abortCommentEdit: '',
      abortTaskAssign: '',
      abortTaskCreate: '',
      abortTaskEdit: '',
      accountNumber: 'Número de cuenta',
      accounting: '',
      actions: 'Comportamiento',
      activity: '',
      adapterConfig: 'Configuración del adaptador',
      adapterType: 'Tipo de adaptador',
      add: '',
      addDevice: 'Agregar dispositivo',
      addLegalSituation: 'Nueva situación jurídica',
      addMetaData: 'Agregar metadatos',
      addNew: 'Agregar nuevo dispositivo',
      addNewAggregation: 'Agregar nueva agregación',
      addNewBuilding: 'Añadir nuevo edificio',
      addNewPartner: 'Añadir Nuevo Socio',
      addNewPartnerType: 'Añadir nuevo tipo de socio',
      addNewProperty: 'Προσθήκη νέας ιδιοκτησίας',
      addNewSiteDesign: 'Añadir nuevo',
      address: 'Dirección',
      admin: 'Admin',
      aggregationFunction: 'Función de agregación espacial',
      aggregationNamePlaceholder: 'ej. Nombre de la agregación',
      aggregations: 'Agregaciones',
      airConditionerOutdoorUnit: '',
      allRelatedPartners: '',
      annualPerformance: '',
      anonymus: 'Anónimo',
      approvalDates: '',
      approvers: '',
      areaSize: '',
      areas: 'Areas',
      assignNew: 'Asignar nuevo',
      atleastOneCharacter: 'Al menos un carácter...',
      atomicFunction: 'Función de agregación temporal',
      attach: 'Adjuntar',
      back: 'Volver',
      backToTheMap: 'Volver al mapa',
      baseDatas: 'Datos',
      batteryCharge: 'Bateria cargada',
      batteryInstallDate: '',
      breakdown: 'Desglose',
      building: 'Edificio',
      buildings: 'Edificios',
      canBePhased: '',
      capabilities: 'Capacidades',
      centralAirConditioner: '',
      centralHeating: '',
      changed_this_field: '',
      choose: 'Elegir',
      chooseLang: 'Elegir idioma: ',
      city: 'Ciudad',
      classification: 'Clasificación',
      close: 'Cerrar',
      comment: '',
      comments: '',
      company: 'Empresa',
      confirm: 'Confirmar',
      confirmRemove: '¿Está seguro de que desea eliminar este dispositivo',
      confirmRemoveLS: '¿Está seguro de que desea eliminar esta situación legal',
      confirmRemovePartner: '¿Estás seguro de que deseas eliminar a este socio',
      confirmRemoveScenario: '¿Está seguro de que desea eliminar este escenario',
      contactPhone: 'Número de teléfono',
      contacts: 'Contactos',
      controlled: '',
      cooling: '',
      coolingHeatingDevice: '',
      copiedToClipboard: 'Copiado al portapapeles',
      copy: 'Copiar',
      country: 'País',
      county: 'Condado',
      create: 'Crear',
      createdAt: '',
      createdBy: '',
      currentPresenceGist: 'Presencia actual',
      data: 'Datos',
      dataOfPartner: 'Datos del socio',
      date: 'Fecha',
      day: 'día',
      deadline: '',
      deattach: 'Desvincular',
      deattachConfirm: '¿Está seguro de que desea desvincular a este socio',
      deattachNotSuccessful: 'Desvinculación no exitosa',
      deattachSuccessful: 'Desvinculación exitosa',
      dedicatedOrgUnit: '',
      delete: 'Eliminar',
      deleteCommentConfirm: '',
      deleteConfirm: '',
      deleteLegalSituation: 'Eliminar situación legal',
      deleteNotSuccessful: 'Eliminación no exitosa',
      deleteSuccessful: 'Eliminación exitosa',
      deny: 'Negar',
      description: '',
      designation: 'Designación',
      details: '',
      device: 'Dispositivo',
      deviceMap: 'Mapa de dispositivos',
      deviceNamePlaceholder: 'ej. Nombre del dispositivo',
      deviceType: 'Tipo de dispositivo',
      dieselGenerator: '¿Generador de diesel',
      dieselGeneratorTitle: '',
      displayName: 'Nombre para mostrar',
      district: 'Distrito',
      documents: 'Documentos',
      door: 'Puerta',
      download: '',
      downloadEkrReport: 'Descargar informe EKR',
      edit: '',
      editAggregation: 'Editar agregación',
      editDevice: 'Editar dispositivo',
      editMetaData: 'Editar metadatos',
      editPartner: 'Editar Socio',
      editScenario: 'Editar escenario',
      editSiteDesign: 'Editar',
      editZone: 'Editar zona',
      egNumPlaceholder: 'ej. 100',
      email: '',
      emailValidationFail: '',
      empty: '',
      emptyFieldSave: '',
      energyTradingPartner: '¿Socio comercial de energía',
      english: 'Inglés',
      error: 'Error',
      errorAlt: 'Una actualización de la página puede resolver el problema',
      evaluation: '',
      events: '',
      expiration: '',
      externalIdentifier: 'Identificador Externo',
      false: '',
      fenestration: '',
      fileName: 'Nombre',
      fireProtection: '',
      first_approval_date: '',
      first_approval_user_form: '',
      first_approval_user_id: '',
      floc: 'FLOC ID',
      floor: 'Piso',
      floorArea: 'Superficie del piso',
      floors: 'Pisos',
      foundList: 'Lista encontrada',
      fuelCellGenerator: '¿Generador de pila de combustible',
      fuelCellGeneratorTitle: '',
      generateApiKeyButtonTitle: 'Nueva clave API',
      generateApiKeyExample: 'Ejemplo',
      generateApiKeyTitle: 'Clave API',
      globalSiteDesignNotFound: 'Site design no encontrado, actualice este adaptador.',
      goBack: 'Ir a la página anterior',
      heating: '',
      hour: 'hora',
      houseNumber: 'Número de casa',
      hungarian: 'Húngaro',
      infos: 'Informaciones',
      insulationMaterial: '',
      invalidMapView: 'Los datos del mapa cargado son incorrectos',
      inverterManufacturer: '',
      inverterPerformance: '',
      inverterType: '',
      isActive: '¿Activo',
      kng: 'KING',
      latitude: 'Latitud',
      legalRelationship: 'Relación jurídica',
      legalRelationshipEnd: '',
      legalRelationshipStart: '',
      legalRelationshipUtilization: '',
      legalSituation: 'Situación jurídica',
      legalSituationEnd: 'Fin de la situación legal',
      legalSituationStart: 'Situación legal de inicio',
      legalSituations: 'Situaciones legales',
      levels: 'Niveles',
      lidarView: 'Vista LIDAR',
      liquidCoolerCoolingPerformance: '',
      liquidCoolerMaxElectricPerformance: '',
      listOfDevices: 'Lista de dispositivos',
      listOfMetaData: 'Lista de metadatos',
      loadedAreaHasNoCoordinates: 'El área cargada no tiene coordenadas geográficas.',
      loading: 'Cargando...',
      loginAgain: 'Iniciaré sesión nuevamente',
      logoutInProgress: 'Cierre de sesión en progreso...',
      longitude: 'Longitud',
      mainFunction: 'Función principal',
      mainType: 'Tipo principal',
      maintainedBy: '',
      mandatoryFieldSignal: '',
      mapImage: 'Imagen del mapa',
      mapView: 'Vista del mapa',
      maxEnergyIntake: '',
      maxValue: 'Valor máximo',
      messages: '',
      minValue: 'Valor mínimo',
      minutes: 'minutos',
      missingApiKey: 'La clave API no se ha generado aún',
      missingFormFields: '',
      missingMandatoryField: '',
      mtArea: 'MT metro cuadrado relevante',
      mtResponsibles: 'MT Responsables',
      name: 'Nombre',
      new: '',
      newOptionValue: 'Nuevo',
      newScenario: 'Nuevo escenario',
      nextMaintenance: '',
      no: 'No',
      noAppliedFilters: 'No hay filtros aplicados...',
      noAssignedDevice: 'No hay dispositivo asignado',
      noBuildingsFound: 'No hay edificios para la propiedad.',
      noDevice: 'No hay dispositivo agregado actualmente',
      noFloorsFound: 'No hay pisos para este edificio.',
      noResults: 'La búsqueda no tiene resultados...',
      noSDChildrenFound: 'No hay subentidades en este diseño de sitio',
      noScenarioAddedCurrently: 'No hay escenarios configurados aún.',
      noSubArea: 'No hay subáreas adjuntas a esta área.',
      noViewImage: '¡No hay imagen disponible para esta vista!',
      notSuccessfulSave: 'Guardado no exitoso...',
      note: 'Nota',
      notes: 'Notas de entrada',
      number: '',
      numberCanBeSeated: 'Numero de asientos',
      numberDedicated: 'Personal dedicado',
      numberOfElevators: 'Número de ascensores',
      numberOfPossibleWorkplaces: 'Número de posibles lugares de trabajo',
      numberOfStairways: 'Número de escaleras',
      numberOfWorkplaces: 'Número de lugares de trabajo',
      numberSeated: 'Número de sentados',
      offGrid: '',
      open: '',
      optionAdminEditorLink: 'Administración',
      optionEditorLink: 'Editar',
      optionEditorPage: 'Editor de valores de opciones',
      optionValue: 'Valores de opciones',
      optionValueDuplicateTr: '',
      optionValueTokenEmpty: '',
      optionsNumber: 'Valores de opciones',
      order: '',
      outgoingIPAddress: 'Dirección IP de salida',
      ownership: 'Posesión',
      parcelNumber: 'Número de parcela',
      parent: '',
      partner: '',
      partners: 'interlocutores',
      paymentObligations: 'Obligaciones de pago',
      performance: '',
      performanceTransfer: '',
      permissionDenied: 'Permiso denegado',
      personnel: '',
      populationData: 'DATOS DE POBLACIÓN',
      position: 'Posición',
      postal: 'Postal',
      postalError: '',
      postalRegex:
        'Para mostrar los condados y asentamientos, complete el campo del código postal.',
      powerProduced: '',
      premise: '',
      preview: '',
      priority: '',
      privateIndividual: 'Persona privada',
      profile: 'Perfil',
      propellant: '',
      properties: 'Propiedades',
      property: 'Propiedad',
      propertyManagementModule: 'Módulo de gestión de propiedades',
      propertySheetAppellation: 'Nombre según la Hoja de Propiedad',
      protection: 'Proteccion',
      protectionNote: 'Descripción de la protección',
      publicAreaName: 'Nombre del área pública',
      publicAreaType: 'Tipo de área pública',
      rain: 'Lluvia: ',
      rank: 'Rango',
      regenerateApiKeyConfirmation: '¿Está seguro de que desea regenerar la clave API',
      regenerateApiKeyConfirmationCancel: 'Cancelar',
      regenerateApiKeyConfirmationOk: 'Sí',
      relatedTasks: '',
      reports: 'Informes',
      resolution: 'Resolución',
      responders: 'Escenarios',
      responsible: '',
      review: '',
      'rvg-automatic-list-refresh-1-min': 'Actualización automática de la lista cada minuto',
      'rvg-automatic-list-refresh-30-sec': 'Actualización automática de la lista cada 30 segundos',
      'rvg-automatic-list-refresh-5-min': 'Actualización automática de la lista cada 5 minutos',
      rvg_automatic_list_refresh_1_min: 'Actualización automática de la lista cada minuto',
      rvg_automatic_list_refresh_30_sec: 'Actualización automática de la lista cada 30 segundos',
      rvg_automatic_list_refresh_5_min: 'Actualización automática de la lista cada 5 minutos',
      sap: 'SAP código',
      save: 'Guardar',
      saveError: 'Se produjeron errores durante el guardado',
      scenarios: 'Escenarios',
      search: 'Buscar',
      searchGeneric: 'Búsqueda Genérica',
      searchSiteDesign: 'Búsqueda de Sitio',
      searchZone: 'Búsqueda de Zona',
      searchingDevice: 'Buscando dispositivo...',
      second_approval_date: '',
      second_approval_user_form: '',
      second_approval_user_id: '',
      select: 'Seleccionar',
      selectInputs: 'Seleccionar entradas',
      selectOutput: 'Seleccionar salida',
      selectTime: 'Establecer hora de inicio',
      sendToOrder: '',
      sessionExpired: 'La sesión ha expirado, ¡por favor intente iniciar sesión nuevamente!',
      setPerPremise: '',
      settlement: '',
      simple_approval_user_id: '',
      siteDesignPlaceholder: 'ej. Nombre del Site Design',
      solarPanel: '¿Panel solar',
      sqm: 'm²',
      stairway: 'Escalera',
      state: '',
      status: 'Estado',
      structureTypes: 'Tipos de estructura',
      subType: 'Subtipo',
      subject: '',
      successfulSave: 'Guardado exitosamente...',
      syncSites: 'Sincronizar',
      system: 'Sistema',
      tankSize: '',
      taskAssinment: '',
      taskCreatedAt: '',
      taskListTitle: '',
      taskMainTitle_: '',
      taskMainTitle_edit: '',
      taskMainTitle_new: '',
      taskMainTitle_newfromlist: '',
      taskMainTitle_read: '',
      taskRelationType: '',
      taskToRelate: '',
      taskType: '',
      tasks: '',
      tasksTitle: '',
      taxId: 'Identificación del impuesto',
      taxNumber: 'Número de identificación fiscal',
      technicalData: '',
      temperatureData: 'DATOS DE TEMPERATURA',
      temperatureMap: 'Mapa de temperatura',
      test: 'Probar',
      tooLongTaxNumber: '',
      tooShortTaxNumber: '',
      translations: '',
      true: '',
      type: 'Tipo',
      unauthorizedTenant: '¡El uso de esta aplicación no está permitido para su empresa!',
      updateAggregationGists: 'Actualizar resúmenes de agregación',
      updateContact: 'Editar contacto',
      updateLegalSituation: 'Editar situación legal',
      updateProperty: 'Editar propiedad',
      updatedAt: '',
      upload: 'Subir',
      uploadDate: 'Fecha de carga',
      uploadFile: 'Subir archivo',
      uploader: 'Cargador',
      ups: '',
      useOfGeneratedHeat: '',
      value: 'Valor',
      wfAddBuilding: '',
      wfAddPremise: '',
      wfAddProperty: '',
      wfRelatedObjects: '',
      wfSearchResult: '',
      wfSelectedObjects: '',
      withGiveDataNoPartnerFound: 'No se encontró ningún socio con los datos proporcionados.',
      yes: 'Sí',
      zoneAdmin: 'Administrador de zonas',
      zoneGroups: 'Grupos de zonas',
      zoneMap: 'Mapa de zonas',
      zoneName: 'Nombre de la zona',
      zoneNamePlaceholder: 'ej. Pasillo',
      zoneType: 'Tipo de zona',
    },
  },
  el: {
    message: {
      PriorityHigh: '',
      PriorityLow: '',
      PriorityMedium: '',
      WfBuildingData: '',
      WfPremiseData: '',
      WfPropertyData: '',
      abortCommentCreate: '',
      abortCommentEdit: '',
      abortTaskAssign: '',
      abortTaskCreate: '',
      abortTaskEdit: '',
      accountNumber: 'Αριθμός λογαριασμού',
      accounting: '',
      actions: 'δράση',
      activity: '',
      adapterConfig: 'Διαμόρφωση προσαρμογέα',
      adapterType: 'Τύπος προσαρμογέα',
      add: '',
      addDevice: 'Προσθήκη Συσκευής',
      addLegalSituation: 'Νέα νομική κατάσταση',
      addMetaData: 'Προσθήκη μεταδεδομένων',
      addNew: 'Προσθήκη νέας συσκευής',
      addNewAggregation: 'Προσθήκη νέας Συγκέντρωσης',
      addNewBuilding: 'Προσθήκη νέου κτιρίου',
      addNewPartner: 'Προσθέστε νέο συνεργάτη',
      addNewPartnerType: 'Προσθέστε νέο τύπο συνεργάτη',
      addNewProperty: 'Agregar nueva propiedad',
      addNewSiteDesign: 'Προσθήκη νέου',
      address: 'Διεύθυνση',
      admin: 'Διαχειριστής',
      aggregationFunction: 'Συνάρτηση χωρικής συνάθροισης',
      aggregationNamePlaceholder: 'π.χ. Όνομα Συγκέντρωσης',
      aggregations: 'Συγκεντρώσεις',
      airConditionerOutdoorUnit: '',
      allRelatedPartners: '',
      annualPerformance: '',
      anonymus: 'Ανώνυμος',
      approvalDates: '',
      approvers: '',
      areaSize: '',
      areas: 'Περιοχές',
      assignNew: 'Ανάθεση νέου',
      atleastOneCharacter: 'Τουλάχιστον ένας χαρακτήρας...',
      atomicFunction: 'Συνάρτηση χρονικής συνάθροισης',
      attach: 'Συνδέστε',
      back: 'πίσω',
      backToTheMap: 'Επιστροφή στον χάρτη',
      baseDatas: 'Δεδομένα',
      batteryCharge: 'Φόρτιση μπαταρίας',
      batteryInstallDate: '',
      breakdown: 'Ανάλυση',
      building: 'Κτίριο',
      buildings: 'κτίρια',
      canBePhased: '',
      capabilities: 'Δυνατότητες',
      centralAirConditioner: '',
      centralHeating: '',
      changed_this_field: '',
      choose: 'Επιλέξτε',
      chooseLang: 'Επιλογή Γλώσσας: ',
      city: 'Πόλη',
      classification: 'Ταξινόμηση',
      close: 'Κλείσιμο',
      comment: '',
      comments: '',
      company: 'εταιρεία',
      confirm: 'Επιβεβαίωση',
      confirmRemove: 'Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτή τη συσκευή;',
      confirmRemoveLS: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν τη νομική κατάσταση;',
      confirmRemovePartner: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον συνεργάτη;',
      confirmRemoveScenario: 'Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτό το σενάριο;',
      contactPhone: 'Αριθμός τηλεφώνου',
      contacts: 'επαφές',
      controlled: '',
      cooling: '',
      coolingHeatingDevice: '',
      copiedToClipboard: 'Αντιγράφηκε στο πρόχειρο',
      copy: 'Αντιγραφή',
      country: 'Χώρα',
      county: 'Κομητεία',
      create: 'Δημιουργία',
      createdAt: '',
      createdBy: '',
      currentPresenceGist: 'Παρούσα κατάσταση',
      data: 'Δεδομένα',
      dataOfPartner: 'Δεδομένα συνεργάτη',
      date: 'ημερομηνία',
      day: 'ημέρα',
      deadline: '',
      deattach: 'Αποσύνδεση',
      deattachConfirm: 'Είστε σίγουροι ότι θέλετε να αποσυνδέσετε αυτόν τον συνεργάτη;',
      deattachNotSuccessful: 'Αποσύνδεση μη επιτυχής',
      deattachSuccessful: 'Επιτυχής αποσύνδεση',
      dedicatedOrgUnit: '',
      delete: 'Διαγραφή',
      deleteCommentConfirm: '',
      deleteConfirm: '',
      deleteLegalSituation: 'Διαγραφή νομικής κατάστασης',
      deleteNotSuccessful: 'Η διαγραφή δεν ήταν επιτυχής',
      deleteSuccessful: 'Η διαγραφή ήταν επιτυχής',
      deny: 'Άρνηση',
      description: '',
      designation: 'Ονομασία',
      details: '',
      device: 'Συσκευή',
      deviceMap: 'Χάρτης Συσκευών',
      deviceNamePlaceholder: 'π.χ. Όνομα Συσκευής',
      deviceType: 'Τύπος Συσκευής',
      dieselGenerator: 'Γεννήτρια ντίζελ;',
      dieselGeneratorTitle: '',
      displayName: 'Ονομασία',
      district: 'Περιοχή',
      documents: 'Έγγραφα',
      door: 'Πόρτα',
      download: '',
      downloadEkrReport: 'Λήψη αναφοράς EKR',
      edit: '',
      editAggregation: 'Επεξεργασία Συγκέντρωσης',
      editDevice: 'Επεξεργασία Συσκευής',
      editMetaData: 'Επεξεργασία μεταδεδομένων',
      editPartner: 'Επεξεργασία συνεργάτη',
      editScenario: 'Επεξεργασία Σενάριο',
      editSiteDesign: 'Επεξεργασία',
      editZone: 'Επεξεργασία ζώνης',
      egNumPlaceholder: 'π.χ. 100',
      email: '',
      emailValidationFail: '',
      empty: '',
      emptyFieldSave: '',
      energyTradingPartner: 'Συνεργάτης Εμπορίου Ενέργειας;',
      english: 'Αγγλικά',
      error: 'Σφάλμα',
      errorAlt: 'Η ανανέωση της σελίδας μπορεί να λύσει το πρόβλημα',
      evaluation: '',
      events: '',
      expiration: '',
      externalIdentifier: 'Εξωτερικός Αναγνωριστικός Κωδικός',
      false: '',
      fenestration: '',
      fileName: 'Όνομα_αρχείου',
      fireProtection: '',
      first_approval_date: '',
      first_approval_user_form: '',
      first_approval_user_id: '',
      floc: 'Αναγνωριστικό FLOC',
      floor: 'Πάτωμα',
      floorArea: 'Περιοχή ορόφου',
      floors: 'Όροφοι',
      foundList: 'Λίστα βρέθηκε',
      fuelCellGenerator: 'Γεννήτρια κυψελών καυσίμου;',
      fuelCellGeneratorTitle: '',
      generateApiKeyButtonTitle: 'Νέο κλειδί API',
      generateApiKeyExample: 'Παράδειγμα',
      generateApiKeyTitle: 'Κλειδί API',
      globalSiteDesignNotFound: 'Το site design δεν βρέθηκε, ενημερώστε αυτόν τον προσαρμογέα.',
      goBack: 'Επιστροφή στην προηγούμενη σελίδα',
      heating: '',
      hour: 'ώρα',
      houseNumber: 'Αριθμός κατοικίας',
      hungarian: 'Ουγγρικά',
      infos: 'Πληροφορίες',
      insulationMaterial: '',
      invalidMapView: 'Τα δεδομένα του φορτωμένου χάρτη είναι εσφαλμένα',
      inverterManufacturer: '',
      inverterPerformance: '',
      inverterType: '',
      isActive: 'Ενεργό;',
      kng: 'KING',
      latitude: 'Γεωγραφικό πλάτος',
      legalRelationship: 'Νομική σχέση',
      legalRelationshipEnd: '',
      legalRelationshipStart: '',
      legalRelationshipUtilization: '',
      legalSituation: 'Νομική κατάσταση',
      legalSituationEnd: 'Τέλος νομικής κατάστασης',
      legalSituationStart: 'Έναρξη νομικής κατάστασης',
      legalSituations: 'Νομικές καταστάσεις',
      levels: 'Επίπεδα',
      lidarView: 'Προβολή LIDAR',
      liquidCoolerCoolingPerformance: '',
      liquidCoolerMaxElectricPerformance: '',
      listOfDevices: 'Λίστα Συσκευών',
      listOfMetaData: 'Λίστα μεταδεδομένων',
      loadedAreaHasNoCoordinates: 'Η φορτωμένη περιοχή δεν έχει γεωγραφικές συντεταγμένες.',
      loading: 'Φόρτωση...',
      loginAgain: 'Θα συνδεθώ ξανά',
      logoutInProgress: 'Αποσύνδεση σε εξέλιξη...',
      longitude: 'Γεωγραφικό μήκος',
      mainFunction: 'Κύρια λειτουργία',
      mainType: 'Κύριος τύπος',
      maintainedBy: '',
      mandatoryFieldSignal: '',
      mapImage: 'Εικόνα χάρτη',
      mapView: 'προβολή χάρτη',
      maxEnergyIntake: '',
      maxValue: 'Μέγιστη αξία',
      messages: '',
      minValue: 'Ελάχιστο αξία',
      minutes: 'λεπτά',
      missingApiKey: 'Το κλειδί API δεν έχει δημιουργηθεί ακόμη',
      missingFormFields: '',
      missingMandatoryField: '',
      mtArea: 'ΜΤ σχετικό τ.μ',
      mtResponsibles: 'MT Υπεύθυνοι',
      name: 'Όνομα',
      new: '',
      newOptionValue: 'νέα αξία',
      newScenario: 'Νέο Σενάριο',
      nextMaintenance: '',
      no: 'όχι',
      noAppliedFilters: 'Δεν εφαρμόζονται φίλτρα...',
      noAssignedDevice: 'Δεν έχει ανατεθεί συσκευή',
      noBuildingsFound: 'Δεν υπάρχουν κτίρια για το ακίνητο',
      noDevice: 'Δεν έχει προστεθεί συσκευή αυτή τη στιγμή',
      noFloorsFound: 'Δεν βρέθηκαν όροφοι για αυτό το κτίριο.',
      noResults: 'Η αναζήτηση δεν έχει αποτελέσματα...',
      noSDChildrenFound: 'Δεν υπάρχουν υπο-περιοχές σε αυτό το σχέδιο τοποθεσίας',
      noScenarioAddedCurrently: 'Δεν έχουν διαμορφωθεί ακόμη σενάρια.',
      noSubArea: 'Δεν υπάρχουν υποπεριοχές συνδεδεμένες με αυτήν την περιοχή.',
      noViewImage: 'Δεν υπάρχει διαθέσιμη εικόνα για αυτή την προβολή!',
      notSuccessfulSave: 'Η αποθήκευση δεν ήταν επιτυχής...',
      note: 'Σημείωση',
      notes: 'Σημειώσεις εισόδου',
      number: '',
      numberCanBeSeated: 'αριθμός θέσεων',
      numberDedicated: 'Αφοσιωμένο προσωπικό',
      numberOfElevators: 'Αριθμός Ανελκυστήρων',
      numberOfPossibleWorkplaces: 'Αριθμός πιθανών θέσεων εργασίας',
      numberOfStairways: 'Αριθμός κλιμακοστασίων',
      numberOfWorkplaces: 'Αριθμός θέσεων εργασίας',
      numberSeated: 'Αριθμός καθισμένων',
      offGrid: '',
      open: '',
      optionAdminEditorLink: 'διαχείριση',
      optionEditorLink: 'Επεξεργασία',
      optionEditorPage: 'Σελίδα επεξεργασίας επιλογών',
      optionValue: 'Timí epilogís',
      optionValueDuplicateTr: '',
      optionValueTokenEmpty: '',
      optionsNumber: 'αριθμός επιλογών',
      order: '',
      outgoingIPAddress: 'Εξερχόμενη διεύθυνση IP',
      ownership: 'Ιδιοκτησία',
      parcelNumber: 'Αριθμός δέματος',
      parent: '',
      partner: '',
      partners: 'Συνεργάτες',
      paymentObligations: 'Υποχρεώσεις πληρωμής',
      performance: '',
      performanceTransfer: '',
      permissionDenied: 'Άρνηση πρόσβασης',
      personnel: '',
      populationData: 'ΔΕΔΟΜΕΝΑ ΠΛΗΘΥΣΜΟΥ',
      position: 'Θέση',
      postal: 'Ταχυδρομικός',
      postalError: '',
      postalRegex:
        'Για να εμφανίσετε τους νομούς και τους οικισμούς, συμπληρώστε το πεδίο ταχυδρομικός κώδικας!',
      powerProduced: '',
      premise: '',
      preview: '',
      priority: '',
      privateIndividual: 'άτομο',
      profile: 'Προφίλ',
      propellant: '',
      properties: 'Ιδιότητες',
      property: 'Ιδιοκτησία',
      propertyManagementModule: 'Ενότητα διαχείρισης ακινήτων',
      propertySheetAppellation: 'Όνομα σύμφωνα με το Φύλλο Ιδιοκτησίας',
      protection: 'ΠΡΟΣΤΑΣΙΑ',
      protectionNote: 'Προστατευτικό σημείωμα',
      publicAreaName: 'Όνομα δημόσιου χώρου',
      publicAreaType: 'Τύπος κοινόχρηστου χώρου',
      rain: 'Βροχή: ',
      rank: 'Βαθμός',
      regenerateApiKeyConfirmation: 'Είστε σίγουροι ότι θέλετε να αναγεννήσετε το κλειδί API;',
      regenerateApiKeyConfirmationCancel: 'Ακύρωση',
      regenerateApiKeyConfirmationOk: 'Ναι',
      relatedTasks: '',
      reports: 'Αναφορές',
      resolution: 'Ανάλυση',
      responders: 'Σενάρια',
      responsible: '',
      review: '',
      'rvg-automatic-list-refresh-1-min': 'Αυτόματη ανανέωση λίστας κάθε λεπτό',
      'rvg-automatic-list-refresh-30-sec': 'Αυτόματη ανανέωση λίστας κάθε 30 δευτερόλεπτα',
      'rvg-automatic-list-refresh-5-min': 'Αυτόματη ανανέωση λίστας κάθε 5 λεπτά',
      rvg_automatic_list_refresh_1_min: 'Αυτόματη ανανέωση λίστας κάθε λεπτό',
      rvg_automatic_list_refresh_30_sec: 'Αυτόματη ανανέωση λίστας κάθε 30 δευτερόλεπτα',
      rvg_automatic_list_refresh_5_min: 'Αυτόματη ανανέωση λίστας κάθε 5 λεπτά',
      sap: 'Κωδικός SAP',
      save: 'Αποθήκευση',
      saveError: 'Παρουσιάστηκαν σφάλματα κατά την αποθήκευση',
      scenarios: 'Σενάρια',
      search: 'Αναζήτηση',
      searchGeneric: 'γενική αναζήτηση',
      searchSiteDesign: 'αναζήτηση ιστότοπου',
      searchZone: 'αναζήτηση ζώνης',
      searchingDevice: 'Αναζήτηση συσκευής...',
      second_approval_date: '',
      second_approval_user_form: '',
      second_approval_user_id: '',
      select: 'Επιλογή',
      selectInputs: 'Επιλογή Εισόδων',
      selectOutput: 'Επιλογή Εξόδου',
      selectTime: 'Ρύθμιση ώρας έναρξης',
      sendToOrder: '',
      sessionExpired: 'Η συνεδρία έληξε, παρακαλώ προσπαθήστε να συνδεθείτε ξανά!',
      setPerPremise: '',
      settlement: '',
      simple_approval_user_id: '',
      siteDesignPlaceholder: 'π.χ. Όνομα Site Design',
      solarPanel: 'Ηλιακά πάνελ;',
      sqm: 'τ.μ.',
      stairway: 'Σκάλα',
      state: '',
      status: 'Κατάσταση',
      structureTypes: 'Τύποι δομών',
      subType: 'Δευτερεύων τύπος',
      subject: '',
      successfulSave: 'Η αποθήκευση ήταν επιτυχής...',
      syncSites: 'Συγχρονισμός',
      system: 'Σύστημα',
      tankSize: '',
      taskAssinment: '',
      taskCreatedAt: '',
      taskListTitle: '',
      taskMainTitle_: '',
      taskMainTitle_edit: '',
      taskMainTitle_new: '',
      taskMainTitle_newfromlist: '',
      taskMainTitle_read: '',
      taskRelationType: '',
      taskToRelate: '',
      taskType: '',
      tasks: '',
      tasksTitle: '',
      taxId: 'τον αριθμό φορολογικού μητρώου',
      taxNumber: 'Αριθμός φορολογικού μητρώου',
      technicalData: '',
      temperatureData: 'ΔΕΔΟΜΕΝΑ ΘΕΡΜΟΚΡΑΣΙΑΣ',
      temperatureMap: 'Χάρτης Θερμοκρασίας',
      test: 'Δοκιμή',
      tooLongTaxNumber: '',
      tooShortTaxNumber: '',
      translations: '',
      true: '',
      type: 'τύπος',
      unauthorizedTenant: 'Η χρήση αυτής της εφαρμογής δεν επιτρέπεται για την εταιρεία σας!',
      updateAggregationGists: 'Ενημέρωση Συγκέντρωσης Gists',
      updateContact: 'Επεξεργασία επαφής',
      updateLegalSituation: 'Επεξεργασία Νομική κατάσταση',
      updateProperty: 'Επεξεργασία ιδιοκτησίας',
      updatedAt: '',
      upload: 'Μεταφόρτωση',
      uploadDate: 'Ημερομηνία μεταφόρτωσης',
      uploadFile: 'Ανέβασμα αρχείου',
      uploader: 'Μεταφορτωτής',
      ups: '',
      useOfGeneratedHeat: '',
      value: 'αξία',
      wfAddBuilding: '',
      wfAddPremise: '',
      wfAddProperty: '',
      wfRelatedObjects: '',
      wfSearchResult: '',
      wfSelectedObjects: '',
      withGiveDataNoPartnerFound: 'Δεν βρέθηκε συνεργάτης με τα δεδομένα που δώσατε.',
      yes: 'Ναί',
      zoneAdmin: 'Διαχειριστής Ζωνών',
      zoneGroups: 'Ομάδες ζωνών',
      zoneMap: 'Χάρτης Ζωνών',
      zoneName: 'Το όνομα της ζώνης',
      zoneNamePlaceholder: 'π.χ. Διάδρομος',
      zoneType: 'Τύπος ζώνης',
    },
  },
  de: {
    message: {
      PriorityHigh: '',
      PriorityLow: '',
      PriorityMedium: '',
      WfBuildingData: '',
      WfPremiseData: '',
      WfPropertyData: '',
      abortCommentCreate: '',
      abortCommentEdit: '',
      abortTaskAssign: '',
      abortTaskCreate: '',
      abortTaskEdit: '',
      accountNumber: 'ΑKontonummer',
      accounting: '',
      actions: 'Aktionen',
      activity: '',
      adapterConfig: 'Adapterkonfiguration',
      adapterType: 'Adaptertyp',
      add: '',
      addDevice: 'Gerät hinzufügen',
      addLegalSituation: 'Neue Rechtslage',
      addMetaData: 'Metadaten hinzufügen',
      addNew: 'Neues Gerät hinzufügen',
      addNewAggregation: 'Neue Aggregation hinzufügen',
      addNewBuilding: 'Neues Gebäude hinzufügen',
      addNewPartner: 'Neuen Partner hinzufügen',
      addNewPartnerType: 'Neuer Partner hinzufügen',
      addNewProperty: 'Neue Eigenschaft hinzufügen',
      addNewSiteDesign: 'Neu hinzufügen',
      address: 'Adresse',
      admin: 'Admin',
      aggregationFunction: 'Räumliche Aggregationsfunktion',
      aggregationNamePlaceholder: 'z.B. Aggregatname',
      aggregations: 'Aggregationen',
      airConditionerOutdoorUnit: '',
      allRelatedPartners: '',
      annualPerformance: '',
      anonymus: 'Anonym',
      approvalDates: '',
      approvers: '',
      areaSize: '',
      areas: 'Bereiche',
      assignNew: 'Neu zuweisen',
      atleastOneCharacter: 'Mindestens ein Zeichen...',
      atomicFunction: 'Zeitliche Aggregationsfunktion',
      attach: 'Anhängen',
      back: 'Zurück',
      backToTheMap: 'Zurück zur Karte',
      baseDatas: 'Daten',
      batteryCharge: 'Akku-Ladung',
      batteryInstallDate: '',
      breakdown: 'Aufschlüsselung',
      building: 'Gebäude',
      buildings: 'Gebäude',
      canBePhased: '',
      capabilities: 'Fähigkeiten',
      centralAirConditioner: '',
      centralHeating: '',
      changed_this_field: '',
      choose: 'Wählen',
      chooseLang: 'Sprache wählen: ',
      city: 'Stadt',
      classification: 'Einstufung',
      close: 'Schließen',
      comment: '',
      comments: '',
      company: 'Unternehmen',
      confirm: 'Bestätigen',
      confirmRemove: 'Sind Sie sicher, dass Sie dieses Gerät entfernen möchten',
      confirmRemoveLS: 'Möchten Sie diese Rechtslage wirklich löschen',
      confirmRemovePartner: 'Möchten Sie diesen Partner wirklich löschen',
      confirmRemoveScenario: 'Sind Sie sicher, dass Sie dieses Szenario entfernen möchten',
      contactPhone: 'Telefonnummer',
      contacts: 'Kontakte',
      controlled: '',
      cooling: '',
      coolingHeatingDevice: '',
      copiedToClipboard: 'In die Zwischenablage kopiert',
      copy: 'Kopieren',
      country: 'Land',
      county: 'Bezirk',
      create: 'Erstellen',
      createdAt: '',
      createdBy: '',
      currentPresenceGist: 'Aktuelle Anwesenheit',
      data: 'Daten',
      dataOfPartner: 'Partnerdaten',
      date: 'Datum',
      day: 'Tag',
      deadline: '',
      deattach: 'Deattach',
      deattachConfirm: 'Sind Sie sicher, dass Sie diesen Partner',
      deattachNotSuccessful: 'Deattach nicht erfolgreich',
      deattachSuccessful: 'Erfolgreich deattach',
      dedicatedOrgUnit: '',
      delete: 'Löschen',
      deleteCommentConfirm: '',
      deleteConfirm: '',
      deleteLegalSituation: 'Rechtslage löschen',
      deleteNotSuccessful: 'Löschen war nicht erfolgreich',
      deleteSuccessful: 'Löschen erfolgreich',
      deny: 'Ablehnen',
      description: '',
      designation: 'Bezeichnung',
      details: '',
      device: 'Gerät',
      deviceMap: 'Gerätekarte',
      deviceNamePlaceholder: 'z.B. Gerätename',
      deviceType: 'Gerätetyp',
      dieselGenerator: 'Dieselgenerator',
      dieselGeneratorTitle: '',
      displayName: 'Anzeigename',
      district: 'Bezirk',
      documents: 'Dokumente',
      door: 'Tür',
      download: '',
      downloadEkrReport: 'EKR-Bericht herunterladen',
      edit: '',
      editAggregation: 'Aggregation bearbeiten',
      editDevice: 'Gerät bearbeiten',
      editMetaData: 'Metadaten bearbeiten',
      editPartner: 'Partner bearbeiten',
      editScenario: 'Szenario bearbeiten',
      editSiteDesign: 'Bearbeiten',
      editZone: 'Zone bearbeiten',
      egNumPlaceholder: 'z.B. 100',
      email: '',
      emailValidationFail: '',
      empty: '',
      emptyFieldSave: '',
      energyTradingPartner: 'Energiehandelspartner',
      english: 'Englisch',
      error: 'Fehler',
      errorAlt: 'Ein Neuladen der Seite könnte das Problem lösen',
      evaluation: '',
      events: '',
      expiration: '',
      externalIdentifier: 'Externe Kennung',
      false: '',
      fenestration: '',
      fileName: 'Dateiname',
      fireProtection: '',
      first_approval_date: '',
      first_approval_user_form: '',
      first_approval_user_id: '',
      floc: 'FLOC-Nummer',
      floor: 'Boden',
      floorArea: 'Wohnfläche',
      floors: 'Etagen',
      foundList: 'Liste gefunden',
      fuelCellGenerator: 'Brennstoffzellengenerator',
      fuelCellGeneratorTitle: '',
      generateApiKeyButtonTitle: 'Neuer API-Schlüssel',
      generateApiKeyExample: 'Beispiel',
      generateApiKeyTitle: 'API-Schlüssel',
      globalSiteDesignNotFound: 'Site Design nicht gefunden, aktualisieren Sie diesen Adapter.',
      goBack: 'Zur vorherigen Seite gehen',
      heating: '',
      hour: 'Stunde',
      houseNumber: 'Hausnummer',
      hungarian: 'Ungarisch',
      infos: 'Informationen',
      insulationMaterial: '',
      invalidMapView: 'Die Daten der geladenen Karte sind inkorrekt',
      inverterManufacturer: '',
      inverterPerformance: '',
      inverterType: '',
      isActive: 'Aktiv',
      kng: 'KING',
      latitude: 'Breitengrad',
      legalRelationship: 'Rechtsverhältnis',
      legalRelationshipEnd: '',
      legalRelationshipStart: '',
      legalRelationshipUtilization: '',
      legalSituation: 'Rechtliche Situation',
      legalSituationEnd: 'Rechtslage Ende',
      legalSituationStart: 'Rechtslage Beginn',
      legalSituations: 'Rechtliche Situationen',
      levels: 'Ebenen',
      lidarView: 'LIDAR-Ansicht',
      liquidCoolerCoolingPerformance: '',
      liquidCoolerMaxElectricPerformance: '',
      listOfDevices: 'Liste der Geräte',
      listOfMetaData: 'Liste der Metadaten',
      loadedAreaHasNoCoordinates: 'Das geladene Gebiet hat keine geografischen Koordinaten.',
      loading: 'Wird geladen...',
      loginAgain: 'Ich werde mich erneut anmelden',
      logoutInProgress: 'Abmeldung läuft...',
      longitude: 'Längengrad',
      mainFunction: 'Hauptfunktion',
      mainType: 'Haupttyp',
      maintainedBy: '',
      mandatoryFieldSignal: '',
      mapImage: 'Kartenbild',
      mapView: 'Kartenansicht',
      maxEnergyIntake: '',
      maxValue: 'Maximalwert',
      messages: '',
      minValue: 'Mindestwert',
      minutes: 'Minuten',
      missingApiKey: 'API-Schlüssel ist noch nicht generiert',
      missingFormFields: '',
      missingMandatoryField: '',
      mtArea: 'MT relevanter Quadratmeter',
      mtResponsibles: 'MT Verantwortliche',
      name: 'Name',
      new: '',
      newOptionValue: 'Neuer Wert',
      newScenario: 'Neues Szenario',
      nextMaintenance: '',
      no: 'NEIN',
      noAppliedFilters: 'Keine Filter angewendet...',
      noAssignedDevice: 'Kein zugewiesenes Gerät',
      noBuildingsFound: 'Es gibt keine Gebäude für das Grundstück',
      noDevice: 'Derzeit ist kein Gerät hinzugefügt',
      noFloorsFound: 'Keine Etagen für dieses Gebäude gefunden.',
      noResults: 'Die Suche hat keine Ergebnisse...',
      noSDChildrenFound: 'Es gibt keine Unterentitäten in diesem Standortdesign',
      noScenarioAddedCurrently: 'Derzeit sind keine Szenarien konfiguriert.',
      noSubArea: 'Es sind keine Unterbereiche an diesem Bereich angehängt.',
      noViewImage: 'Es ist kein Bild für diese Ansicht verfügbar!',
      notSuccessfulSave: 'Speichern nicht erfolgreich...',
      note: 'Anmerkung',
      notes: 'Eintragshinweise',
      number: '',
      numberCanBeSeated: 'Anzahl Sitzplätze',
      numberDedicated: 'Engagiertes Personal',
      numberOfElevators: 'Anzahl der Aufzüge',
      numberOfPossibleWorkplaces: 'Anzahl der möglichen Arbeitsplätze',
      numberOfStairways: 'Anzahl der Treppen',
      numberOfWorkplaces: 'Anzahl der Arbeitsplätze',
      numberSeated: 'Anzahl der Sitzplätze',
      offGrid: '',
      open: '',
      optionAdminEditorLink: 'Verwaltung',
      optionEditorLink: 'Bearbeiten',
      optionEditorPage: 'Optionseditorseite',
      optionValue: 'Optionswert',
      optionValueDuplicateTr: '',
      optionValueTokenEmpty: '',
      optionsNumber: 'Anzahl der Optionen',
      order: '',
      outgoingIPAddress: 'Ausgehende IP-Adresse',
      ownership: 'Eigentum',
      parcelNumber: 'Paketnummer',
      parent: '',
      partner: '',
      partners: 'Partner',
      paymentObligations: 'Zahlungsverpflichtungen',
      performance: '',
      performanceTransfer: '',
      permissionDenied: 'Zugriff verweigert',
      personnel: '',
      populationData: 'BEVÖLKERUNGSDATEN',
      position: 'Position',
      postal: 'Postleitzahl',
      postalError: '',
      postalRegex: 'Zur Anzeige der Landkreise und Ortschaften füllen Sie bitte das PLZ-Feld aus!',
      powerProduced: '',
      premise: '',
      preview: '',
      priority: '',
      privateIndividual: 'Privatperson',
      profile: 'Profil',
      propellant: '',
      properties: 'Eigenschaften',
      property: 'Eigentum',
      propertyManagementModule: 'Modul Immobilienverwaltung',
      propertySheetAppellation: 'Name laut Eigentumsverzeichnis',
      protection: 'Schutz',
      protectionNote: 'Schutzbeschreibung',
      publicAreaName: 'Name des öffentlichen Bereichs',
      publicAreaType: 'Art des öffentlichen Bereichs',
      rain: 'Regen: ',
      rank: 'Rang',
      regenerateApiKeyConfirmation:
        'Sind Sie sicher, dass Sie den API-Schlüssel regenerieren möchten',
      regenerateApiKeyConfirmationCancel: 'Abbrechen',
      regenerateApiKeyConfirmationOk: 'Ja',
      relatedTasks: '',
      reports: 'Berichte',
      resolution: 'Auflösung',
      responders: 'Szenarien',
      responsible: '',
      review: '',
      'rvg-automatic-list-refresh-1-min': 'Automatische Listenaktualisierung jede Minute',
      'rvg-automatic-list-refresh-30-sec': 'Automatische Listenaktualisierung alle 30 Sekunden',
      'rvg-automatic-list-refresh-5-min': 'Automatische Listenaktualisierung alle 5 Minuten',
      rvg_automatic_list_refresh_1_min: 'Automatische Listenaktualisierung jede Minute',
      rvg_automatic_list_refresh_30_sec: 'Automatische Listenaktualisierung alle 30 Sekunden',
      rvg_automatic_list_refresh_5_min: 'Automatische Listenaktualisierung alle 5 Minuten',
      sap: 'SAP-Code',
      save: 'Speichern',
      saveError: 'Fehler beim Speichern aufgetreten',
      scenarios: 'Szenarien',
      search: 'Suchen',
      searchGeneric: 'Generische Suche',
      searchSiteDesign: 'Seitensuche',
      searchZone: 'Zonensuche',
      searchingDevice: 'Gerät wird gesucht...',
      second_approval_date: '',
      second_approval_user_form: '',
      second_approval_user_id: '',
      select: 'Auswählen',
      selectInputs: 'Eingaben auswählen',
      selectOutput: 'Ausgabe auswählen',
      selectTime: 'Startzeit festlegen',
      sendToOrder: '',
      sessionExpired: 'Die Sitzung ist abgelaufen, bitte versuchen Sie erneut, sich anzumelden!',
      setPerPremise: '',
      settlement: '',
      simple_approval_user_id: '',
      siteDesignPlaceholder: 'z.B. Site Design Name',
      solarPanel: 'Sonnenkollektor',
      sqm: 'qm',
      stairway: 'Treppe',
      state: '',
      status: 'Status',
      structureTypes: 'Strukturtypen',
      subType: 'Subtyp',
      subject: '',
      successfulSave: 'Erfolgreich gespeichert...',
      syncSites: 'Synchronisieren',
      system: 'System',
      tankSize: '',
      taskAssinment: '',
      taskCreatedAt: '',
      taskListTitle: '',
      taskMainTitle_: '',
      taskMainTitle_edit: '',
      taskMainTitle_new: '',
      taskMainTitle_newfromlist: '',
      taskMainTitle_read: '',
      taskRelationType: '',
      taskToRelate: '',
      taskType: '',
      tasks: '',
      tasksTitle: '',
      taxId: 'Steuer ID',
      taxNumber: 'Steuernummer',
      technicalData: '',
      temperatureData: 'TEMPERATURDATEN',
      temperatureMap: 'Temperaturkarte',
      test: 'Testen',
      tooLongTaxNumber: '',
      tooShortTaxNumber: '',
      translations: '',
      true: '',
      type: 'Typ',
      unauthorizedTenant: 'Die Nutzung dieser Anwendung ist für Ihr Unternehmen nicht gestattet!',
      updateAggregationGists: 'Aggregations-Gists aktualisieren',
      updateContact: 'Kontakt bearbeiten',
      updateLegalSituation: 'Rechtliche Situation bearbeiten',
      updateProperty: 'Eigenschaft bearbeiten',
      updatedAt: '',
      upload: 'Hochladen',
      uploadDate: 'Datum des Hochladens',
      uploadFile: 'Datei hochladen',
      uploader: 'Hochladen',
      ups: '',
      useOfGeneratedHeat: '',
      value: 'Wert',
      wfAddBuilding: '',
      wfAddPremise: '',
      wfAddProperty: '',
      wfRelatedObjects: '',
      wfSearchResult: '',
      wfSelectedObjects: '',
      withGiveDataNoPartnerFound: 'Kein Partner mit den gegebenen Daten gefunden.',
      yes: 'Ja',
      zoneAdmin: 'Zonen-Admin',
      zoneGroups: 'Zonengruppen',
      zoneMap: 'Zonenkarten',
      zoneName: 'Zonenname',
      zoneNamePlaceholder: 'z.B. Korridor',
      zoneType: 'Zonentyp',
    },
  },
};
export default messages;
