let uniqueField = 'partnersGrid';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";
import router from "@/misc/router";

export default (state, t, propertyId, deatachFn, partnerMainTypeMapping, partnerSubTypeMapping) => {
  return {
    endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/property/${propertyId}/partner`,
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: true
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min'),
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.open'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'partnerEditButton',
                dropdowned: false,
                onClick: () => {
                  state.value.partnerEdit = true;
                  state.value.partnerId = record.id;
                  state.value.isPerson = record.privatePerson;
                  state.value.partnerData.forEach((e) => {
                    if (e.type == "option" ) {
                      e.value = record[e.model + "Id"];
                      e.valueLabel = record[e.model + "Value"];
                    } else {
                      e.value = record[e.model];
                    }
                  });
                  store.commit("setRemTab", 14);
                  store.commit("setRemFormEdit", false);
                  router.push({ name: 'partnerPage', params: { partnerId: record.id } });
                }
              })
              
              buttons.push({
                title: '',
                tooltip: t('message.deattach'),
                icon: '<svg class="w-4 h-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.2 11.8L11.8 6.19998M6.6 2.6V1M11.4 15.4V17M2.6 6.6H1M15.4 11.4H17M3.33137 3.33137L2.2 2.2M14.6687 14.6686L15.8 15.7999M9.00003 13.5254L7.30297 15.2225C6.05329 16.4721 4.02717 16.4721 2.77749 15.2225C1.52781 13.9728 1.52781 11.9467 2.77749 10.697L4.47454 8.99993M13.5255 8.99993L15.2226 7.30287C16.4722 6.0532 16.4722 4.02707 15.2226 2.77739C13.9729 1.52771 11.9468 1.52771 10.6971 2.77739L9.00003 4.47445" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'partnerDeleteButton',
                dropdowned: false,
                onClick: () => {
                  deatachFn(record.id, () =>{callMeToRefreshTheGrid() });
                }
              })
              return buttons;
            }
          }
        }
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "name": {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.name ? record.name : 'N/A'
        }
      },
      "partnerMainTypeValue": {
        title: t("message.mainType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.partnerMainTypeValue ? record.partnerMainTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: partnerMainTypeMapping,
        },             
      },
      "partnerSubTypeValue": {
        title: t("message.subType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.partnerSubTypeValue ? record.partnerSubTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: partnerSubTypeMapping,
        },             
      },
      "privatePerson": {
        title: t("message.privateIndividual"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "taxId": {
        title: t("message.taxId"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Integer'
        },
        exportable: true,
        orderable: true,
      },
      "taxNumber": {
        title: t("message.taxNumber"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'text'
        },
        middleware: (value) => {    
          return value || 'N/A'
        },
        exportable: true,
        orderable: true,
      },
    }
  }
}
