<template>
  <div v-if="state.config.visible == true" class="mt-4">
    <WFLink :method="() => {
      state.openPanel = !state.openPanel;
    }
      " class="uppercase mb-4">{{ $t("message.wfRelatedObjects") }}</WFLink>
    <div v-if="state.openPanel == true">
      <div v-if="state.data.PROPERTY_ID">
        <div class="flex flex-row justify-between content-center mb-4">
          <span class="text-md text-white font-bold mr-4 my-auto">{{
            $t("message.property")
          }}</span>
          <hr class="w-full my-auto" />
          <RemPrimaryButtonSmall v-if="formReadonly == false" :label="$t('message.wfAddProperty')" class="ml-4"
            @click="openPropertyModal(0)">
          </RemPrimaryButtonSmall>
        </div>
        <div v-if="state.data.PROPERTY_ID.value.length > 0">
          <RobberVueGrid ref="propertiesGrid" :config="propertiesBasicGridConfigComputed"
          :key="state.propertyUpdateKey" />
        </div>
        
      </div>

      <div v-if="state.data.BUILDING_ID">
        <div class="flex flex-row justify-between content-center mb-4">
          <span class="text-md text-white font-bold mr-4 my-auto">{{
            $t("message.building")
          }}</span>
          <hr class="w-full my-auto" />
          <RemPrimaryButtonSmall v-if="formReadonly == false" :label="$t('message.wfAddBuilding')" class="ml-4"
            @click="openBuildingModal(0)">
          </RemPrimaryButtonSmall>
        </div>
        <div v-if="state.data.BUILDING_ID.value.length > 0">
          <RobberVueGrid ref="buildingsGrid" :config="buildingsBasicGridConfigComputed" />
        </div>

      </div>

      <div v-if="state.data.PREMISE_ID">
        <div class="flex flex-row justify-between content-center mb-4">
          <span class="text-md text-white font-bold mr-4 my-auto">{{
            $t("message.premise")
          }}</span>
          <hr class="w-full my-auto" />
          <RemPrimaryButtonSmall v-if="formReadonly == false" :label="$t('message.wfAddPremise')" class="ml-4"
            @click="openPremiseModal(0)">
          </RemPrimaryButtonSmall>
        </div>
        <div v-if="state.data.PREMISE_ID.value.length > 0">
          <RobberVueGrid ref="buildingsGrid" :config="premiseBasicGridConfigComputed" />
        </div>
        
      </div>

      <div></div>
    </div>
    <!-- eslint-disable -->
    <PropertySelectorModal v-if="state.openPropertyModal == true" v-model:openModal="state.openPropertyModal"
      v-model:inputValue="state.data.PROPERTY_ID.value" v-model:modalSaveEvent="state.propertyModalSaveEvent">
    </PropertySelectorModal>
    <BuildingSelectorModal v-if="state.openBuildingModal == true" v-model:openModal="state.openBuildingModal"
      v-model:inputValue="state.data.BUILDING_ID.value" v-model:modalSaveEvent="state.buildingModalSaveEvent">
    </BuildingSelectorModal>
    <PremiseSelectorModal v-if="state.openPremiseModal == true" v-model:openModal="state.openPremiseModal"
      v-model:inputValue="state.data.PREMISE_ID.value" v-model:modalSaveEvent="state.premiseModalSaveEvent">
    </PremiseSelectorModal>
    <!-- eslint-enable -->
  </div>
</template>

<script setup>
//import SVGRenderer from '../../SVGRenderer.vue';
import RemPrimaryButtonSmall from "../../RemPrimaryButtonSmall.vue";
import RobberVueGrid from "../../../RVG/RobberVueGrid.vue";
import WFLink from "./../WFLink.vue";
import PropertySelectorModal from "./PropertySelectorModal.vue";
import BuildingSelectorModal from "./BuildingSelectorModal.vue";
import PremiseSelectorModal from "./PremiseSelectorModal.vue";

import { useI18n } from "vue-i18n";
import { ref, onMounted, defineModel, watch, computed } from "vue";
import Axios from "@/misc/axios";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import { useRoute, useRouter } from "vue-router";
import toast from "@/misc/toast.js";

import property from "@/misc/remFormdata/workflow/custom_fields/property.js";
import PropertySelectorConfigs from "./PropertySelectorConfigs.js";
import utils from "@/misc/fieldConfigs/utils";

let route = useRoute();
let router = useRouter();

//const props = defineProps(['taskId'])
//const taskId = defineModel("taskId");
const taskType = defineModel("taskType");
const customFieldSaveEvent = defineModel("customFieldSaveEvent");

const { t } = useI18n();

const state = ref({
  openPanel: false,
  openPropertyModal: false,
  propertyEndpoint: "/rem/property/filtered-properties",
  propertyFilterinit: false,
  propertyUpdateKey: 0,
  propertyModalSaveEvent: 0,
  openBuildingModal: false,
  buildingEndpoint: "/rem/building/filtered-buildings",
  buildingFilterinit: false,
  buildingUpdateKey: 0,
  buildingModalSaveEvent: 0,
  openPremiseModal: false,
  premiseEndpoint: "/rem/premise/filtered-premises",
  premiseFilterinit: false,
  premiseUpdateKey: 0,
  premiseModalSaveEvent: 0,
  propertyModal: {
    tab: "PROPERTY",
  },
  config: {
    visible: false,
  },
  form: structuredClone(property.form),
  data: [],
});

const formReadonly = computed(() => {
  route.params.view;
  return route.params.view == "read";
});

onMounted(() => {
  setMeUp();
});

watch(
  () => taskType.value,
  () => {
    if (taskType.value != "" && taskType.value != null) setMeUp();
  }
);

watch(
  () => state.value.propertyModalSaveEvent,
  () => {
    saveCustomFields(null, 1);
  }
);

if (route.params.taskId == 0) {
  watch(
    () => customFieldSaveEvent.value.counter,
    async () => {
      await setMeUp();
      if(state.value.data.PROPERTY_ID && state.value.data.PROPERTY_ID.value.length > 0) saveCustomFields(customFieldSaveEvent.value.id, 1);
      if(state.value.data.BUILDING_ID && state.value.data.BUILDING_ID.value.length > 0) saveCustomFields(customFieldSaveEvent.value.id, 2);
      if(state.value.data.PREMISE_ID && state.value.data.PREMISE_ID.value.length > 0) saveCustomFields(customFieldSaveEvent.value.id, 3);
     
     
     
    }
  );
}

const setMeUp = async (id) => {
  state.value.data = [];
  if (!id) {
    id = route.params.taskId;
  }
  if (id == 0) {
    if (!taskType.value) {
      return;
    }
    try {
      const resp = await Axios.get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/workflow/task/type/" +
        taskType.value +
        "/start-state-custom-fields",
        {
          onTokenRefreshed: () => {
            this.setMeUp(state);
          },
        }
      );

      if (resp && resp.status == 200) {
        if (resp.data.length == 0) {
          state.value.config.visible = false;
          return;
        }

        let i = 0;
        let found = 0;

        while (i < resp.data.length && found < 3) {
          if (resp.data[i].displayable == false) {
            i++;
            continue;
          }
          const field = state.value.form.find(
            (e) => e.model == resp.data[i].code
          );

          if (field) {
            state.value.data[field.model] = field;
            state.value.data[field.model].customFieldId = resp.data.field.id;
            found++;
          }
          i++;
        }

        if (found > 0) {
          state.value.config.visible = true;
        } else {
          state.value.config.visible = false;
        }
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      const resp = await Axios.get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/workflow/task/" +
        id +
        "/custom-field-values",
        {
          onTokenRefreshed: () => {
            this.setMeUp(state);
          },
        }
      );

      if (resp && resp.status == 200) {
        if (resp.data.length == 0) {
          state.value.config.visible = false;
          return;
        }

        const section = resp.data.find((s) => s.section == "BODY");

        if (section) {
          const group = section.groups.find(
            (e) => e.groupName == "Ingatlan adatai"
          );

          if (group) {
            let i = 0;
            let found = 0;
            while (i < group.customFields.length && found < 3) {
              if (group.displayable == false) {
                i++;
                continue;
              }
              const field = state.value.form.find(
                (e) => e.model == group.customFields[i].code
              );

              if (field) {
                state.value.data[field.model] = field;
                state.value.data[field.model].customFieldId =
                  group.customFields[i].customFieldId;
                state.value.data[field.model].value =
                  group.customFields[i].values;
                found++;
              }
              i++;
            }
             /*state.value.data.BUILDING_ID = {
               customFieldId: 12,
               value: []
             }
 
             state.value.data.PREMISE_ID = {
               customFieldId: 13,
               value: []
             }*/

            if (found > 0) {
              state.value.config.visible = true;
            } else {
              state.value.config.visible = false;
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  setupPropertyEndpoint();
  setupBuildingEndpoint();
  setupPremiseEndpoint();
};

const openPropertyModal = (index) => {
  if (
    formReadonly.value == true ||
    state.value.data[index.defaultReadonly] == true
  ) {
    return;
  }
  state.value.openPropertyModal = true;
};

const openBuildingModal = (index) => {
  if (
    formReadonly.value == true ||
    state.value.data[index.defaultReadonly] == true
  ) {
    return;
  }
  state.value.openBuildingModal = true;
};

const openPremiseModal = (index) => {
  if (
    formReadonly.value == true ||
    state.value.data[index.defaultReadonly] == true
  ) {
    return;
  }
  state.value.openPremiseModal = true;
};

const saveCustomFields = async (id, saveTag) => {
  if (!id) {
    id = route.params.taskId;
  }
  if (id == 0) {
    return;
  }

  let field;
  switch (saveTag) {
    case 1:
      field = state.value.data.PROPERTY_ID;
      break;
    case 2:
      field = state.value.data.BUILDING_ID;
      break;
    case 3:
      field = state.value.data.PREMISE_ID;
      break;
  }

  const resp = utils.saveWFCustomField(id, field);

  if (resp && resp.status == 200) {
    toast.success(t("message.successfulSave"));
  } else {
    toast.error(t("message.notSuccessfulSave"));
  }

  switch (saveTag) {
    case 1:
      setupPropertyEndpoint();

      propertiesBasicGridConfigComputed =
        PropertySelectorConfigs.getPropertyBasicConfig(
          state,
          t,
          router,
          state.value.propertyEndpoint
        );
      break;
    case 2:
      setupBuildingEndpoint()
      buildingsBasicGridConfigComputed =
        PropertySelectorConfigs.getBuildingBasicConfig(state, t, router);
      break;
    case 3:
      setupPremiseEndpoint()
      premiseBasicGridConfigComputed =
        PropertySelectorConfigs.getPremiseBasicConfig(state, t, router);
      break;
  }
};

//GRID CONFIGS

let propertiesBasicGridConfigComputed =
  PropertySelectorConfigs.getPropertyBasicConfig(
    state,
    t,
    router,
    state.value.propertyEndpoint
  );

let buildingsBasicGridConfigComputed =
  PropertySelectorConfigs.getBuildingBasicConfig(state, t, router, state.value.buildingEndpoint)

let premiseBasicGridConfigComputed =
  PropertySelectorConfigs.getPremiseBasicConfig(state, t, router, state.value.premiseEndpoint);

const setupPropertyEndpoint = () => {
  state.value.propertyEndpoint = "/rem/property/filtered-properties";
  state.value.propertyFilterinit = false
  if (state.value.data.PROPERTY_ID.value.length == 0) {
    addArraytoEndpoint(['0'], "include", 'property');
  } else {
    addArraytoEndpoint(state.value.data.PROPERTY_ID.value, "include", "property");

  }

  propertiesBasicGridConfigComputed =
    PropertySelectorConfigs.getPropertyBasicConfig(
      state,
      t,
      router,
      state.value.propertyEndpoint
    );

  state.value.propertyUpdateKey = state.value.propertyUpdateKey + 1;
};

const setupBuildingEndpoint = () => {
  state.value.buildingEndpoint = "/rem/building/filtered-buildings";
  state.value.buildingFilterinit = false


  if (state.value.data.BUILDING_ID.value.length == 0) {
    addArraytoEndpoint(['0'], "include", 'building');
  } else {

    addArraytoEndpoint(state.value.data.BUILDING_ID.value, "include", "building");

  }

  buildingsBasicGridConfigComputed =
    PropertySelectorConfigs.getBuildingBasicConfig(state, t, router, state.value.buildingEndpoint)

  state.value.buildingUpdateKey = state.value.buildingUpdateKey + 1;
};

const setupPremiseEndpoint = () => {
  state.value.premiseEndpoint = "/rem/premise/filtered-premises";
  state.value.premiseFilterInit = false
  if (state.value.data.PREMISE_ID.value.length == 0) {
    addArraytoEndpoint(['0'], "include", 'premise');
  } else {
    addArraytoEndpoint(state.value.data.PREMISE_ID.value, "include", "premise");
  }

  premiseBasicGridConfigComputed =
    PropertySelectorConfigs.getPremiseBasicConfig(state, t, router, state.value.premiseEndpoint);

  state.value.premiseUpdateKey = state.value.premiseUpdateKey + 1;
};

const addArraytoEndpoint = async (array, filter, tag) => {
  if (array.length > 0) {
    state.value[`${tag}Endpoint`] +=
      state.value[`${tag}Filterinit`] == false ? "?" : "";
    state.value[`${tag}Filterinit`] = true;

    for (let i = 0; i < array.length; i++) {
      if (i != 0) {
        state.value[`${tag}Endpoint`] += "&";
      }
      state.value[`${tag}Endpoint`] += `${filter}=${array[i]}`;
    }
  }
};
</script>

<style scoped>
.wf-field-title {
  padding: 10px 0 10px 0;
  border-radius: 10px;
}

.wf-border {
  padding: 10px 0 10px 0;
  border-radius: 10px;
}

.wf-title-hover:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.im-mandatory {
  color: red;
}
</style>
