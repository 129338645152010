<template>
    <div>
        <div v-for="event in events" :key="event.eventType">
            <div v-if="event.eventType == 'LINKS_MODIFIED'"
                class="items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600">
                <time class="mb-1 text-xs font-normal text-end text-gray-400 sm:order-last sm:mb-0">{{ new
                    Date(event.triggeredAt).toLocaleString() }}</time>
                <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300"><a href="#"
                        class="font-semibold text-blue-600 dark:text-secondary hover:underline">{{ event.triggeredByUserName
                        }}</a> a következő(k)re módosította a kapcsolódó ügyeket: <span
                        class="font-semibold text-gray-900 dark:text-white">{{ event.eventData.relatedTasks }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue"
import store from "@/misc/vuex-store"

const events = computed(() => {
    return store.getters.getEvent
})

</script>

<style></style>