export default {
    EMAIL:{
        name: 'Email',
        script: (options, optionsRepo, value, newValue, oldValue, inputValue, inputError, inputErrorVariables) => {
            inputErrorVariables
            const re = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            if (re.test(newValue) == false) { 
              inputError.value = "message.emailValidationFail";
            } else {
              inputError.value = "";
            }
        }
    }
}