<template>
  <div id="WFMetaData" class="h-screen max-h-screen">
    <BaseLayout>
      <template #leftButtons>
        <BackToMapButton />
      </template>

      <template #mainContent>
        <div class="h-full h-max-full mx-auto px-2 k">
          <div class="bg-gray-800 px-6 pt-2 pb-6 rounded-lg mb-2">
            <h2 class="text-lg font-sans font-semibold uppercase text-white my-8">Metadata</h2>

            <RemPrimaryButtonSmall
              class="mr-4"
              :label="$t('message.create')"
              :method="
                () => {
                  addNewMetaData();
                }
              "
            ></RemPrimaryButtonSmall>
          </div>
          <RobberVueGrid
            v-if="!state.forceRefresh"
            ref="metaDataGrid"
            :config="metaDataGridConfigComputed"
          />
        </div>

        <AddMetaDataModal
          @refreshGrid="refreshGrid"
          :showModal="modalStatus"
          :metaDataGrid="metaDataGrid"
          :metaData="state.metaDataById"
          :editMode="state.editMode"
        />

        <!--CONFIRMATION MODAL-->
        <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
        <div class="modal">
          <div class="modal-box">
            <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
              {{ $t('message.' + state.confirmationModal.message) }}
            </h3>
            <div class="modal-action">
              <label
                class="btn btn-sm text-white font-light text-xs"
                @click="
                  () => {
                    state.confirmationModal.confirmed = false;
                    state.confirmationModal.show = false;
                  }
                "
                >{{ $t('message.no') }}</label
              >
              <label
                class="btn btn-sm bg-magenta text-white font-light text-xs"
                @click="
                  () => {
                    state.confirmationModal.confirmed = true;
                    state.confirmationModal.show = false;
                  }
                "
                >{{ $t('message.yes') }}</label
              >
            </div>
          </div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from '@/layouts/BaseLayout.vue';
import BackToMapButton from '../../components/BackToMapButton.vue';
import RobberVueGrid from '@/components/RVG/RobberVueGrid.vue';
import RemPrimaryButtonSmall from '../../components/REM/RemPrimaryButtonSmall.vue';
import AddMetaDataModal from './AddMetaDataModal.vue';

// utils
// import { computedAsync } from '@vueuse/core'
// import RVGUtils from "../../components/RVG/RobberVueGridUtils.js";
// import router from "@/misc/router";
import { ref, computed, watch } from 'vue';
//import User from "../misc/User";
import Axios from '@/misc/axios';
import store from '@/misc/vuex-store';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import toast from '@/misc/toast';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// RVGS
import metaDataGridConfig from '@/misc/gridConfigs/workflowAdmin/wfMetaDataGridConfig.js';

const metaDataGrid = ref();

const state = ref({
  forceRefresh: false,
  metaDataById: {},
  editMode: false,
  confirmationModal: {
    confirmed: false,
    show: false,
    message: '',
  },
});

const refreshGrid = () => {
  state.value.forceRefresh = true;
  setTimeout(() => {
    state.value.forceRefresh = false;
  }, 100);
};

const addNewMetaData = () => {
  state.value.editMode = false;
  store.commit('setIsModalOpen', true);
};

const editMetaData = async (id) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/workflow/admin/metadata/${id}`;

  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        editMetaData(id);
      },
    });
    state.value.metaDataById = response.data;
  } catch (error) {
    console.log(error);
  }

  state.value.editMode = true;
};

const setConfirmation = (message, deleteId) => {
  state.value.confirmationModal.message = message;
  state.value.confirmationModal.show = true;
  watch(
    () => state.value.confirmationModal.confirmed,
    () => {
      if (state.value.confirmationModal.confirmed) {
        if (deleteId) {
          deleteMetaData(deleteId);
        }
      } else {
        return;
      }
    }
  );
};

const deleteMetaData = async (id) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/workflow/admin/metadata/${id}`;
  try {
    await Axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteMetaData(id);
      },
    });
    toast.success(t('message.deleteSuccessful'));
  } catch (error) {
    console.log(error);
    toast.error(t('message.deleteNotSuccessful'));
  }
  metaDataGrid?.value.refresh();
  refreshGrid();
};

const metaDataGridConfigComputed = computed(() => {
  return metaDataGridConfig(state, t, setConfirmation, editMetaData);
});

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});
</script>

<style scoped></style>
