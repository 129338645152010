<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div class="h-full overflow-y-auto w-full">
    <div>
      <div class="flex flex-row items-center gap-2">
        <SVGRenderer svgKey="pin_icon" class="w-10 h-10 stroke-magenta mt-1 ml-3" />
        <div class="p-5 pl-0 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
          <div class="flex flex-row items-center justify-between">
            <h2 class="text-sm font-bold uppercase mb-1 text-secondary flex flex-row items-center gap-1">
              <span class="text-white">{{ sidebarEntity.name }}</span>
              <button v-if="User.hasPermission('ViewGists') && sidebarEntity.type !== 'area' && sidebarEntity.type != null" @click="showGistEditorSidebar" class="text-magenta font-light">
                <SVGRenderer svgKey="open_view_gist_icon" class="w-4 h-4" />
              </button>
              <button v-if="User.hasPermission('EditMetaData')" @click="showMetaDataEditorSidebar" class="text-magenta font-light">
                <SVGRenderer svgKey="meta_data_icon" class="w-4 h-4" />
              </button>
              <button v-if="User.hasPermission('EditSiteDesign') && sidebarEntity.type != null && sidebarEntity.adapterName == 'manualsitedesign'" @click="showSiteDesignEdtorSidebar(sidebarEntity, sidebarEntity.type, sidebarEntity.parentId)" class="text-magenta font-light">
                <SVGRenderer svgKey="edit_icon" class="w-4 h-4" />
              </button>
              <PropertyHierarchyButton :sidebarEntity="sidebarEntity" />
            </h2>
            <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
              <SVGRenderer svgKey="x_icon" class="w-6 h-6" />
            </a>
          </div>
          <h2 class="text-sm text-info-200 flex flex-row w-full gap-2">
            <div class="max-w-xs text-sm breadcrumbs pt-0">
              <ul class="flex flex-row">
                <li v-for="(bc, key) in breadcrumbs" :key="key" class="flex flex-row text-xs">
                  {{ bc }}
                </li>
              </ul>
            </div>
          </h2>
        </div>
      </div>
      <div v-if="sidebarEntity.type != 'floor'" class="w-full">
        <div class="tabs w-full flex flex-row flex-nowrap pl-5">
          <a v-if="sidebarEntity.type == 'building'"  @click=" () => { store.commit('setSiteDesignSidebarCurrentTab', 'floors'); }" 
            class="tab tab-lifted text-secondary flex flex-row gap-1" :class="[{ 'tab-active': currentTab == 'floors' }]">
            <SVGRenderer svgKey="floors_icon" class="w-4 h-4" />
            <span class="text-xs text-white">{{ $t("message.floors") }}</span>
          </a>
          <a v-if="sidebarEntity.type == 'building'" @click="() => { store.commit('setSiteDesignSidebarCurrentTab', 'infos');}"
            class="tab tab-lifted text-secondary-focus flex flex-row gap-1" :class="[{ 'tab-active': currentTab == 'infos' }]">
            <SVGRenderer svgKey="info_icon" class="w-4 h-4" />
            <span class="text-xs text-white">{{ $t("message.infos") }}</span>
          </a>
          <a v-if="sidebarEntity.type == 'area'" @click="() => { store.commit('setSiteDesignSidebarCurrentTab', 'buildings');}"
            class="tab tab-lifted text-secondary flex flex-row gap-1" :class="[{ 'tab-active': currentTab == 'buildings' }]">
            <SVGRenderer svgKey="building_icon" class="w-4 h-4" />
            <span class="text-xs text-white">{{ $t("message.buildings")}}</span>
          </a>
          <a v-if="sidebarEntity.type == null" @click=" () => { store.commit('setSiteDesignSidebarCurrentTab', 'areas');}"
            class="tab tab-lifted text-secondary flex flex-row gap-1" :class="[{ 'tab-active': currentTab == 'areas' }]">
            <SVGRenderer svgKey="area_icon" class="w-4 h-4" />
            <span class="text-xs text-white">{{ $t('message.areas')}}</span>
          </a>
        </div>
      </div>
    </div>
    <div v-if=" (currentTab == 'floors' || currentTab == 'areas' || currentTab == 'buildings') && sidebarEntity.type != 'floor'" class="p-5">
      <div v-if="currentSiteDesignChildren.length <= 0 && sidebarEntity.adapterName != 'manualsitedesign'" class="alert alert-warning shadow-lg">
        <div>
          <SVGRenderer svgKey="sd_children_not_found_icon" class="stroke-current flex-shrink-0 h-6 w-6" />
          <span>{{ $t("message.noSDChildrenFound") }}</span>
        </div>
      </div>
      <div v-else class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-2" :class="[{ 'md:grid-cols-1': sidebarEntity.type == 'area' }]">
        <div v-for="(siteDesign, index) in currentSiteDesignChildren" :key="index" class="relative flex">
          <button @click=" () => { onTileClick(siteDesign.id); }" type="button" class="btn btn-sm flex flex-col gap-2 items-center justify-center p-2 py-10 flex-nowrap bg-base-300 hover:bg-[#191e24] text-neutral-content rounded-md flex-1">
            <div v-if="siteDesign.type == 'floor'" class="w-6 items-center">
              <SVGRenderer svgKey="floor_icon" class="w-6 h-6" :class="!siteDesign.map ? 'text-gray-500' : 'text-secondary'" />
            </div>
            <div v-if="siteDesign.type == 'building'" class="text-secondary">
              <SVGRenderer svgKey="building_icon" class="w-6 h-6"/>
            </div>
            <div v-if="siteDesign.type == 'area'" class="text-secondary">
              <SVGRenderer svgKey="area_icon" class="w-6 h-6"/>
            </div>
            <div class="grow flex flex-row items-start justify-start gap-2">
              <span class="text-slate-200 font-light text-center text-xs lowercase first-letter:uppercase">{{ siteDesign.name }}</span>
            </div>
          </button>
          <div v-if="siteDesign.adapterName == 'manualsitedesign'" class="absolute -top-4 -right-2">
            <div v-if="User.hasPermission('EditSiteDesign')" @click="showSiteDesignEdtorSidebar(siteDesign, getTypeFromParent(sidebarEntity), sidebarEntity.id)" class="btn btn-sm btn-circle bg-slate-600 hover:bg-slate-700 border-none text-white mr-1">
              <SVGRenderer svgKey="edit_icon" class="w-4 h-4"/>
            </div>
            <div v-if="User.hasPermission('DeleteSiteDesign')" @click="() => { state.showRemoveConfirmation = true; state.deleteId = siteDesign.id }" class="btn btn-sm btn-circle bg-slate-600 hover:bg-slate-700 border-none text-red-700">
              <SVGRenderer svgKey="delete_icon" class="w-4 h-4"/>
            </div>
          </div>
        </div>
        <div v-if="sidebarEntity.adapterName == 'manualsitedesign' && User.hasPermission('AddNewSiteDesign')" @click="showSiteDesignEdtorSidebar(null, getTypeFromParent(sidebarEntity), sidebarEntity.id)" class="btn btn-sm flex items-center justify-center p-2 py-10 border-dashed border-secondary bg-base-300 hover:bg-[#191e24] hover:border-dashed hover:border-secondary border-2 relative">
            <SVGRenderer svgKey="plus_icon" class="w-6 h-6 text-secondary absolute top-auto right-auto"/>
        </div>
      </div>
    </div>
    <div v-if="currentTab == 'infos' || sidebarEntity.type == 'floor'" class="p-5">
      <GistsBaseComponent :entity="sidebarEntity" type="site-design" />
      <AggregationBaseComponent :entity="sidebarEntity" type="site-design" />  
    </div>
    <input type="checkbox" class="modal-toggle" v-model="state.showRemoveConfirmation" />
    <div class="modal">
        <div class="modal-box">
            <h3 class="text-lg text-left font-normal">{{ $t("message.confirmRemove") }}</h3>
            <div class="modal-action">
                <label class="btn btn-sm text-white font-light text-xs" @click="() => { state.showRemoveConfirmation = false }">{{ $t("message.deny") }}</label>
                <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="deleteConfirmed">{{ $t("message.confirm") }}</label>
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>
import User from "@/misc/User";
import GistsBaseComponent from "@/components/Gists/GistsBaseComponent.vue";
import AggregationBaseComponent from '@/components/Gists/AggregationBaseComponent.vue';
import PropertyHierarchyButton from "@/components/PropertyHierarchyButton.vue";
import Utils from "@/misc/Utils";
import store from "@/misc/vuex-store";
import { computed, reactive } from "vue";
import router from "@/misc/router";
import SVGRenderer from "../../components/SVGRenderer.vue";
import Axios from '@/misc/axios';

import TenantConfigHandler from '@/misc/TenantConfigHandler';

const currentTab = computed(() => {
  return store.getters.getSiteDesignSidebarCurrentTab;
});
const state = reactive({
  showRemoveConfirmation: false,
  deleteId: null,
});

const hideSidebar = () => {
  store.commit("hideSidebar");
};

const onTileClick = (siteDesignId) => {
  let siteDesign = Utils.getSiteDesignById(siteDesignId);
  store.commit("showSidebar", { type: "siteDesign", entity: siteDesign });
  switch(siteDesign.type)
  {
    case 'area':
      store.commit('setSiteDesignSidebarCurrentTab', 'buildings')
      break;
    case 'building':
      store.commit('setSiteDesignSidebarCurrentTab', 'floors')
      break;
  }
  if (siteDesign.type == "building" || siteDesign.type == "area") {
    return;
  }

  let defaultRoute = "map";
  if (!siteDesign.map) {
    return;
  }

  store.commit("setSelectedSiteDesign", siteDesign);
  router.push({ name: defaultRoute, params: { siteDesignId: siteDesign.id } });
};

const showGistEditorSidebar = () => {
  store.commit("showSidebar", {
    type: "gistManager",
    entity: sidebarEntity.value,
    params: {
      entityType: "site-design",
      back: () => {
        store.commit("showSidebar", {
          type: "siteDesign",
          entity: sidebarEntity.value,
        });
      },
    },
  });
};
const showMetaDataEditorSidebar = () => {
  store.commit("showSidebar", {
    type: "metadataEditor",
    entity: sidebarEntity.value,
    params: {
      entityType: "site-design",
      back: () => {
        store.commit("showSidebar", {
          type: "siteDesign",
          entity: sidebarEntity.value,
        });
      },
    },
  });
};
const showSiteDesignEdtorSidebar = (siteDesign, type, parentId) => {
  let currentEntity = sidebarEntity.value;
  store.commit("showSidebar", {
    type: "siteDesignEditor",
    entity: siteDesign,
    params: {
      parentId: parentId,
      type: type,
      back: () => {
        store.commit("showSidebar", {
          type: "siteDesign",
          entity: currentEntity,
        });
      },
    },
  });
};

const sidebarEntity = computed(() => {
  let entity = store.getters.getSidebarEntity;
  return entity.entity;
});

const siteDesigns = computed(() => {
  return store.getters.getSiteDesigns;
});

const currentSiteDesignChildren = computed(() => {
  if (!sidebarEntity.value) {
    return [];
  }

  let children = [];
  siteDesigns.value.forEach((siteDesign) => {
    if (siteDesign.parentId == sidebarEntity.value.id) {
      children.push(siteDesign);
    }
  });

  return children.sort(function (a, b) {
    let x = a["name"];
    let y = b["name"];
    return x < y ? -1 : x > y ? 1 : 0;
  });
});

const breadcrumbs = computed(() => {
  let breadcrumbs = [];
  let items = sidebarEntity.value.siteNameHierarchy.split("/");
  items.shift();
  if(sidebarEntity.value.type == null)
  {
    breadcrumbs.push(sidebarEntity.value.name);
  }
  breadcrumbs.push(...items)
  return breadcrumbs;
});
const getTypeFromParent = (currentEntity) => {
  switch (currentEntity.type) {
    case null:
        return 'area'
    case 'area':
        return 'building'
    case 'building':
        return 'floor'
  }
}

const deleteConfirmed = () => {
  state.showRemoveConfirmation = false;
  Axios.delete(TenantConfigHandler.getVariable("ADMINV2_API_URL") + '/site-design/' + state.deleteId + '/manual').then(() => {
    store.commit("removeSiteDesign", state.deleteId);
  }).catch((err) => {
    console.error(err);
  });
}
</script>
