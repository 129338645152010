import typeConfigs from "./typeConfigs"
import optionValueConfig from "./optionValueConfig"
import validationConfigs from "./validationConfigs"

export default {

    createConfig (field, formReadonly)  {

        //console.log(formReadonly);
        
        if(field.displayable == false){
            return false
        }

        let config = structuredClone(typeConfigs.basic[field.metaDataBusinessType]?.config)

        

        if(!config){
            console.log(`No config was found for ${field.metaDataBusinessType}`);
            
            return false
        }

        if(config.type == "option"){
            config.optionValue = optionValueConfig[field.metaDataValueConverterParam]
        }

        config = this.addGenericFields(field, config, formReadonly)

        if(field.validationName){
            config.validationMethod = validationConfigs[field.validationName].script
        }
        
        return config
    },

    addGenericFields (field, config, formReadonly)  {

        config.value = "";
        config.title = field.metaDataName;
        config.defaultReadonly = formReadonly == true ? true : !field.editable
        config.model = field.code
        config.backendLocalization = true

        if(field.dataType == 'SINGLE' && field.values && field.values[0]){
            config.value = field.values[0]
            config.valueLabel = field.valueDtos[0][config.backendName]
        }else if(field.dataType == 'MULTIPLE' && field.values ){
            config.value = field.values
            config.valueLabel = []
            field.valueDtos.forEach((e) => {
                config.valueLabel.push(e[config.backendName])
            })
            
        }

        config.id = field.customFieldValueId ? field.customFieldValueId : 0
        if(!field.customFieldId){
            config.customFieldId = field.id 
        }else{
            config.customFieldId = field.customFieldId 
        }
         
        config.metaDataType = field.dataType ? field.dataType : (field.metaDataType ? field.metaDataType: "")


        return config
    },
    

}