import { createWebHistory, createRouter } from "vue-router";
import MapPage from "../pages/MapPage.vue";
import SiteDesignSelectorPage from "../pages/SiteDesignSelectorPage.vue";
import ZoneEditorPage from "../pages/ZoneEditorPage.vue";
import DevicesPage from "../pages/DevicesPage.vue";
import ReportCreationPage from "../pages/ReportCreationPage.vue";
//import HeatmapPage from '../pages/HeatmapPage.vue'
import User from "../misc/User";
import PermissionDeniedPage from "../pages/PermissionDeniedPage/PermissionDeniedPage.vue";
import UnauthorizedTenantPage from "../pages/UnauthorizedTenantPage/UnauthorizedTenantPage.vue";
import TokenExpiredPage from "../pages/TokenExpiredPage/TokenExpiredPage.vue";
import TemperatureMapPage from "../pages/TemperatureMapPage.vue";
import ResponderPage from "../pages/ResponderPage.vue";
import msalInstance from "./msalInstance";
import RespondersPage from "../pages/RespondersPage.vue";
//import msalHelper from './msalHelper';
import ReportsPage from "../pages/ReportsPage.vue";

import TenantConfigHandler from "./TenantConfigHandler"
import PropertyPage from "../pages/PropertyPageComponents/PropertyPage.vue";
import PropertiesPage from "../pages/PropertiesPage.vue";
import BuildingPage from "../pages/BuildingPageComponents/BuildingPage.vue";
import PremisePage from "../pages/PremisePage.vue"
import EnergeticsPage from "../pages/EnergeticsPage.vue";
import VocabularyAdminEditorPage from "../pages/VocabularyAdminEditorPage.vue"
import DocumentsPage from "../pages/DocumentsPageComponents/DocumentsPage.vue"
import PartnerPage from "../pages/PartnerPage.vue";
import Utils from "./Utils";
import MessageEditorPage from "../pages/MessageEditorPage.vue";
import OptionValueEditorPage from "../pages/OptionValueEditorPage.vue";
import WorkflowMainPage from "../pages/WorkflowMainPage.vue";
import WorkflowTaskPage from "../pages/WorkflowTaskPage.vue";
import WorkflowAssignPage from "../pages/WorkflowAssignPage.vue";
import WorkflowTaskTypes from "../pages/WorkflowAdminComponents/WorkflowTaskTypes.vue";
import WorkflowVersions from "../pages/WorkflowAdminComponents/WorkflowVersions.vue";
import workflowAdmin from "../pages/WorkflowAdminComponents/workflowAdmin.vue";
import workflowCustomFields from "../pages/WorkflowAdminComponents/workflowCustomFields.vue";
import WorkflowMetaData from "@/pages/WorkflowAdminComponents/WorkflowMetaData.vue";

let appConfigLoaded = false;

async function requireAuthAndValidTenant(to, from, next) {
  if (User.isLoggedIn()) {
    // already logged in
    const allowedTenants = process.env.VUE_APP_ALLOWED_TENANTS.split(",");
    if (allowedTenants.includes(User.getUserTenantId())) {
      if (!appConfigLoaded) {
        await Utils.storeAppConfig();
        appConfigLoaded = true;
      }
      if(typeof to.meta.module !== 'undefined' && !Utils.isModuleEnabled(to.meta.module)) {
        console.log('Module not enabled');
        next('/error/permission-denied');
        return;
      }
      if (!to.meta.permission || User.hasPermission(to.meta.permission)) {
        next();
      } else {
        next("/error/permission-denied");
      }
    } else {
      next("/error/unauthorized-tenant");
    }
  } else {
    const response = await msalInstance.handleRedirectPromise();
    //msalHelper.fetchMainToken(msalInstance);

    if (response) {
      // handling login response
      User.login(
        response.accessToken,
        response.idToken,
        response.idTokenClaims.roles
      );
      //msalHelper.fetchGraphToken(msalInstance);
      next();
    } else {
      // invoke login
      await msalInstance.loginRedirect({
        scopes: [TenantConfigHandler.getVariable('AZURE_API_TOKEN_REQUEST')]
      });
    }
  }
}

let router = createRouter({

  history: createWebHistory(),
  routes: [
    {
      path: "/rem/documents",
      component: DocumentsPage,
      name: "documentsPage",
      meta: {
        requiresAuth: true,
        permission: "DocumentsPage",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/rem/partners/:partnerId?",
      component: PartnerPage,
      name: "partnerPage",
      meta: {
        requiresAuth: true,
        permission: "PartnerPage",
        module: "rem"
      },
      children: [
        {
          name: 'partner-contacts',
          path: '/rem/partners/:partnerId?/contacts',
          component: PartnerPage,
        },
        {
          name: 'partner-addresses',
          path: '/rem/partners/:partnerId?/addresses',
          component: PartnerPage,
        },
      ],
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/rem/tasks",
      component: WorkflowMainPage,
      name: "WorkflowMainPage",
      meta: {
        requiresAuth: true,
        permission: "WorkflowUser",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/rem/tasks/:taskId/:view",
      component: WorkflowTaskPage,
      name: "WorkflowTaskPage",
      meta: {
        requiresAuth: true,
        permission: "WorkflowUser",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/rem/tasks/assign",
      component: WorkflowAssignPage,
      name: "WorkflowTaskAssign",
      meta: {
        requiresAuth: true,
        permission: "WorkflowUser",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/messages",
      component: MessageEditorPage,
      name: "messageEditorPage",
      meta: {
        requiresAuth: true,
        permission: "PartnerPage",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/rem/properties",
      component: PropertiesPage,
      name: "propertiesPage",
      meta: {
        requiresAuth: true,
        permission: "PropertiesPage",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties/:propertyId",
      component: PropertyPage,
      // name: "propertyPage",
      meta: {
        requiresAuth: true,
        permission: "PropertiesPage",
        module: "rem"
      },
      children: [
        {
          name: 'property-buildings',
          path: '/properties/:propertyId/buildings',
          component: PropertyPage,
        },
        {
          name: 'addresses',
          path: '/properties/:propertyId/addresses',
          component: PropertyPage,
        },
        {
          name: 'partners',
          path: '/properties/:propertyId/partners',
          component: PropertyPage,
        },
        {
          name: 'documents',
          path: '/properties/:propertyId/documents',
          component: PropertyPage,
        },
        {
          name: 'baseDatas',
          path: '/properties/:propertyId/baseDatas',
          component: PropertyPage,
        },
        {
          name: 'legal-situations',
          path: '/properties/:propertyId/legal-situations',
          component: PropertyPage,
        },
        {
          name: 'utilizations',
          path: '/properties/:propertyId/utilizations',
          component: PropertyPage,
        },
        {
          name: 'property-sheet-part-3',
          path: '/properties/:propertyId/property-sheet-part-3',
          component: PropertyPage,
        },
      ],
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties/:propertyId/buildings/:buildingId",
      component: BuildingPage,
      // name: "buildingPage",
      meta: {
        requiresAuth: true,
        permission: "BuildingPage",
        module: "rem"
      },
      children: [
        {
          name: 'building-premises',
          path: '/properties/:propertyId/buildings/:buildingId/premises',
          component: BuildingPage,
        },
        {
          name: 'building-meters',
          path: '/properties/:propertyId/buildings/:buildingId/building-meters',
          component: BuildingPage,
        },
        {
          name: 'building-partners',
          path: '/properties/:propertyId/buildings/:buildingId/building-partners',
          component: BuildingPage,
        },
        {
          name: 'building-documents',
          path: '/properties/:propertyId/buildings/:buildingId/building-documents',
          component: BuildingPage,
        },
        {
          name: 'dataAndMtResp',
          path: '/properties/:propertyId/buildings/:buildingId/dataAndMtResp',
          component: BuildingPage,
        },
        {
          name: 'building-legal-situations',
          path: '/properties/:propertyId/buildings/:buildingId/building-legal-situations',
          component: BuildingPage,
        },
        {
          name: 'building-utilizations',
          path: '/properties/:propertyId/buildings/:buildingId/building-utilizations',
          component: BuildingPage,
        },
        {
          name: 'structure-types',
          path: '/properties/:propertyId/buildings/:buildingId/structure-types',
          component: BuildingPage,
        },
      ],
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties/:propertyId/buildings/:buildingId/premises/:premiseId",
      component: PremisePage,
      // name: "premisePage",
      meta: {
        requiresAuth: true,
        permission: "PremisePage",
        module: "rem"
      },
      children: [
        {
          name: 'premise-data',
          path: '/properties/:propertyId/buildings/:buildingId/premises/:premiseId/premise-data',
          component: PremisePage,
        },
        {
          name: 'premise-meters',
          path: '/properties/:propertyId/buildings/:buildingId/premises/:premiseId/premise-meters',
          component: PremisePage,
        },
        {
          name: 'premise-partners',
          path: '/properties/:propertyId/buildings/:buildingId/premises/:premiseId/premise-partners',
          component: PremisePage,
        },
        {
          name: 'premise-documents',
          path: '/properties/:propertyId/buildings/:buildingId/premises/:premiseId/premise-documents',
          component: PremisePage,
        },
        {
          name: 'cooling-heating-devices',
          path: '/properties/:propertyId/buildings/:buildingId/premises/:premiseId/cooling-heating-devices',
          component: PremisePage,
        },
        {
          name: 'premise-legal-situations',
          path: '/properties/:propertyId/buildings/:buildingId/premises/:premiseId/premise-legal-situations',
          component: PremisePage,
        },
        {
          name: 'premise-utilizations',
          path: '/properties/:propertyId/buildings/:buildingId/premises/:premiseId/premise-utilizations',
          component: PremisePage,
        },
        {
          name: 'special-data',
          path: '/properties/:propertyId/buildings/:buildingId/premises/:premiseId/special-data',
          component: PremisePage,
        },
      ],
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties/:propertyId/buildings/:buildingId/energetics",
      component: EnergeticsPage,
      name: "energeticsPage",
      meta: {
        requiresAuth: true,
        permission: "EnergeticsPage",
        module: "rem"
      },
      children: [
        {
          name: 'insulation',
          path: '/properties/:propertyId/buildings/:buildingId/energetics/insulation',
          component: EnergeticsPage,
        },
        {
          name: 'solar-panel',
          path: '/properties/:propertyId/buildings/:buildingId/energetics/solar-panel',
          component: EnergeticsPage,
        },
        {
          name: 'central-air-conditioner',
          path: '/properties/:propertyId/buildings/:buildingId/energetics/central-air-conditioner',
          component: EnergeticsPage,
        },
        {
          name: 'air-conditioner-outdoor-unit',
          path: '/properties/:propertyId/buildings/:buildingId/energetics/air-conditioner-outdoor-unit',
          component: EnergeticsPage,
        },
        {
          name: 'fuel-cell-generator',
          path: '/properties/:propertyId/buildings/:buildingId/energetics/fuel-cell-generator',
          component: EnergeticsPage,
        },
        {
          name: 'diesel-generator',
          path: '/properties/:propertyId/buildings/:buildingId/energetics/diesel-generator',
          component: EnergeticsPage,
        },
        {
          name: 'ups',
          path: '/properties/:propertyId/buildings/:buildingId/energetics/ups',
          component: EnergeticsPage,
        },
        {
          name: 'central-heating',
          path: '/properties/:propertyId/buildings/:buildingId/energetics/central-heating',
          component: EnergeticsPage,
        },
      ],
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: '/reports',
      component: ReportsPage,
      name: 'powerBiReportPage',
      meta: { 
        requiresAuth: true,
        permission: 'ViewReports',
        module: 'reports'
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/",
      component: SiteDesignSelectorPage,
      name: "siteDesignSelector",
      meta: {
        requiresAuth: true,
        permission: "MainMap",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: '/map/:siteDesignId/:zone?',
      component: MapPage,
      name: "map",
      meta: {
        requiresAuth: true,
        permission: "MapView",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/temperature-map/:siteDesignId",
      component: TemperatureMapPage,
      name: "temperatureMap",
      meta: {
        requiresAuth: true,
        permission: "TemperatureMapView",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    // {
    //   path: '/map/:siteDesignId/heatmap',
    //   component: HeatmapPage,
    //   name: 'heatmap',
    //   meta: {
    //     requiresAuth: true,
    //     permission: 'Heatmap'
    //   },
    //   beforeEnter: requireAuthAndValidTenant
    // },
    {
      path: "/map/:siteDesignId/devices",
      component: DevicesPage,
      name: "devices",
      meta: {
        requiresAuth: true,
        permission: "Devices",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/map/:siteDesignId/edit/:type",
      component: ZoneEditorPage,
      name: "zoneEditor",
      meta: {
        requiresAuth: true,
        permission: "ZoneAdmin",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: '/optionvalueeditor',
      component: VocabularyAdminEditorPage,
      name: 'vocabularyAdmin',
      meta: { 
        requiresAuth: true,
        permission: 'VocabularyAdmin',
        module: 'rem'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/optionvalueeditor/:optionType',
      component: OptionValueEditorPage,
      name: 'optionValueEditor',
      meta: { 
        requiresAuth: true,
        permission: 'VocabularyAdmin',
        module: 'rem'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/riporting',
      component: ReportCreationPage,
      name: 'ReportCreationPage',
      meta: {
        requiresAuth: true,
        permission: 'ReportCreationPage',
        module: 'rem'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/error/permission-denied',
      component: PermissionDeniedPage,
      name: "permissionDenied",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/error/unauthorized-tenant",
      component: UnauthorizedTenantPage,
      name: "unauthorizedTenant",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/responders",
      component: RespondersPage,
      name: "respondersPage",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/responder/:responderId",
      component: ResponderPage,
      name: "responderPage",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/error/token-expired",
      component: TokenExpiredPage,
      name: "tokenExpired",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/rem/task-types",
      component: WorkflowTaskTypes,
      name: "WorkflowTaskTypesPage",
      meta: {
        requiresAuth: true,
        permission: "WorkflowAdmin",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/rem/admin/meta-data",
      component: WorkflowMetaData,
      name: "WorkflowMetaDataPage",
      meta: {
        requiresAuth: true,
        permission: "WorkflowAdmin",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      name: 'workflow-versions',
          path: '/rem/task-types/:taskTypeId/workflow-versions',
          component: WorkflowVersions,
      meta: {
        requiresAuth: true,
        permission: "WorkflowAdmin",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      name: 'workflow-admin',
      path: '/rem/task-types/:taskTypeId/workflow-versions/:workflowId/states',
      component: workflowAdmin,
      meta: {
        requiresAuth: true,
        permission: "WorkflowAdmin",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      name: 'workflow-states',
      path: '/rem/task-types/:taskTypeId/workflow-versions/:workflowId/states/:stateId',
      component: workflowAdmin,
      meta: {
        requiresAuth: true,
        permission: "WorkflowAdmin",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      name: 'workflow-transitions',
      path: '/rem/task-types/:taskTypeId/workflow-versions/:workflowId/transitions/:transitionId',
      component: workflowAdmin,
      meta: {
        requiresAuth: true,
        permission: "WorkflowAdmin",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      name: 'workflow-custom-fields',
      path: '/rem/task-types/:taskTypeId/workflow-versions/:workflowId/custom-fields',
      component: workflowCustomFields,
      meta: {
        requiresAuth: true,
        permission: "WorkflowAdmin",
        module: "rem"
      },
      beforeEnter: requireAuthAndValidTenant,
    },
  ],
});

export default router;
