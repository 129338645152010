<template>
  <div v-if="state.loaded == true">
    <div v-for="group in form" v-bind:key="group.groupName">
      <div v-if="group.fieldList.length > 0">
        <WFLink
          :method="
            () => {
              group.open = !group.open;
            }
          "
          class="uppercase my-4"
          >{{ group.groupName }}</WFLink
        >
        <div v-if="group.open">
          <div v-for="field in group.fieldList" v-bind:key="field.model">
            <!-- eslint-disable -->
            <WFLabelField
              v-model:inputValue="field.value"
              v-model:parent="field.parent"
              v-model:validated="field.validated"
              v-model:readonly="field.defaultReadonly"
              :config="field"
            ></WFLabelField>
            <!-- eslint-enable -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import WFLink from "./WFLink.vue";
import WFLabelField from "./WFLabelField.vue";

import { useRoute } from "vue-router";
import { defineModel, ref, watch, onMounted } from "vue";
import axios from "@/misc/axios";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import backendTypeMapper from "../../../misc/fieldConfigs/backendTypeMapper";
//import utils from "@/misc/fieldConfigs/utils";

const taskType = defineModel("taskType");
//const customFieldSaveEvent = defineModel("customFieldSaveEvent");
const form = defineModel("form");

const props = defineProps(["placing"]);

let route = useRoute();

const state = ref({
  loaded: false,
  formReadonly: route.params.view == 'read',
  //form: [],
});



onMounted(() => {
  setMeUp();
});

const setMeUp = async (taskId) => {
  form.value = [];

  if (!taskId) {
    taskId = route.params.taskId;
  }
  state.value.newTask = taskId == 0;

  if (state.value.newTask == true && !taskType.value) {
    return;
  }

  if (state.value.newTask) {
    const resp = await axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/workflow/task/type/" +
        taskType.value +
        "/start-state-custom-fields",
      {
        onTokenRefreshed: () => {
          this.setMeUp(state);
        },
      }
    );

    if (resp && resp.status == 200) {
      if (resp.data.length == 0) {
        state.value.loaded = true;
        return;
      }

      resp.data.forEach((field) => {
        if (props.placing != field.section) {
          return;
        }
        let group = form.value.find(
          (e) => e.groupName == field.groupName
        );
        if (!group) {
          group = {
            groupName: field.groupName,
            open: false,
            fieldList: [],
          };
          form.value.push(group);
        }
        const fieldConfig = backendTypeMapper.createConfig(field, state.value.formReadonly);

        if (fieldConfig) {
          group.fieldList.push(fieldConfig);
        }
      });
    }

    state.value.loaded = true;
  } else {
    const resp = await axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/workflow/task/" +
        taskId +
        "/custom-field-values",
      {
        onTokenRefreshed: () => {
          this.setMeUp(state);
        },
      }
    );

    if (resp && resp.status == 200) {
      if (resp.data.length == 0) {
        state.value.loaded = true;
        return;
      }

      resp.data.forEach((section) => {
        if (props.placing != section.section) {
          return;
        }
        section.groups.forEach((fieldGroup) => {
        if (props.placing == "") {
          return;
        }
        let group = {
          groupName: fieldGroup.groupName,
          open: false,
          fieldList: [],
        };

        fieldGroup.customFields.forEach((field) => {
          const fieldConfig = backendTypeMapper.createConfig(field, state.value.formReadonly);

          if (fieldConfig) {
            group.fieldList.push(fieldConfig);
          }
        });

        form.value.push(group);
      });

      })

      
    }
    state.value.loaded = true;
  }
};

/*const saveCustomFields = async (id) => {
  state.value.form.forEach((group) => {
    group.fieldList.forEach(async (field) => {
      const resp = utils.saveWFCustomField(id, field)

      if (resp && resp.status == 200) {
        console.log(resp);
      }
    });
  });
};*/

// watchers

if (route.params.taskId == 0) {
  watch(
    () => taskType.value,
    () => {
      setMeUp();
    }
  );
}

watch(
    () => route.params.view,
    (newValue) => {
      state.value.formReadonly = newValue == "read"
      setMeUp();
    }
  );

/*watch(
  () => customFieldSaveEvent.value.counter,
  () => {
    //console.log("Custom field save is not set up");

    saveCustomFields(customFieldSaveEvent.value.id);
  }
);*/
</script>
