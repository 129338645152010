export default {
  data: [
    {
      title: "batteryInstallDate",
      value: "",
      model: "batteryInstallDate",
      type: "date",
    },
    {
      title: "nextMaintenance",
      value: "",
      model: "nextMaintenance",
      type: "date",
    },
    {
      title: "expiration",
      value: "",
      model: "expiration",
      type: "date",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
    {
      title: "maintainedBy",
      value: "",
      model: "maintainedBy",
      type: "text",
    },
    {
      title: "powerIntake",
      value: "",
      model: "energyIntake",
      type: "positiveNumber",
    },
  ]
}