export default {
    data: [
        {
            title: "name",
            value: "",
            model: "name",
            type: "text",
            mandatory: true,
            validated: true,
          },
          {
            title: "mainType",
            value: "",
            model: "partnerMainType",
            type: "option",
            optionValue: "PARTNER_MAIN_TYPE",
          },
          {
            title: "subType",
            value: "",
            model: "partnerSubType",
            type: "option",
            optionValue: "PARTNER_SUB_TYPE",
            onchangeMethod: (options, optionsRepo, value, inputValue,) => {
              options.value = optionsRepo.filter((elem) => elem.parent == value);
              if (options.value.filter((elem) => elem.value == inputValue.value)) {
                inputValue.value = "";
              } 
            },
          },
          {
            title: "privateIndividual",
            value: "",
            model: "privatePerson",
            type: "checkbox",
          },
          {
            title: "taxId",
            value: "",
            model: "taxId",
            type: "integer",
            visibilityCondition: (newValue/*, oldValue*/) => {
              return newValue === "true"
            },
            validationMethod: (options, optionsRepo, value, newValue, oldValue, inputValue, inputError, inputErrorVariables) => {
              const newValueString = String(newValue || "");
              if (!/^\d*$/.test(newValueString)) {          
                inputError.value = "message.integerOnly"
              }
              if (newValueString.length > 10 || (newValueString.length < 10 && newValueString.length > 0)) {
                inputError.value = "message.lengthWarning"
                inputErrorVariables.value = [10];
              } 
              else {
                inputError.value = ""
              }
            }
          },
          {
            title: "taxNumber",
            value: "",
            model: "taxNumber",
            type: "text",
            visibilityCondition: (newValue/*, oldValue*/) => {
              return newValue === "false"
            },
            validationMethod: (
              options,
              optionsRepo,
              value,
              newValue,
              oldValue,
              inputValue,
              inputError
            ) => {
              if (newValue.length == 8 && oldValue.length < 8) {
                inputValue.value = inputValue.value + "-";
              } else if (newValue.length == 10 && oldValue.length < 10) {
                inputValue.value = inputValue.value + "-";
              } 
              if (newValue.length > 13) {
                inputError.value = "message.tooLongTaxNumber";
              }else if (newValue.length < 13) {
                inputError.value = "message.tooShortTaxNumber";
              }else if(!/^\d{8}-\d-\d{2}$/.test(inputValue.value)){
                inputError.value = "message.numberOnly"
              }else {
                inputError.value = ""
              }
            },
          },
    ]
}