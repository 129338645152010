<template>
  <div id="WorkflowMainPage" class="overflow-hidden">
    <BaseLayout>
      <template #leftButtons>
        <BackToMapButton />
      </template>
      <template #mainContent>
        <div class="w-full mx-auto px-2">
          <h2 class="text-lg font-sans font-semibold uppercase text-secondary my-8 bg-gray-800 p-4 rounded-lg">
            {{ $t('message.taskListTitle') }}
          </h2>
          <div class="bg-gray-800 p-4">
            <RemPrimaryButtonSmall
              class="mr-4"
              :label="$t('message.create')"
              :method="
                () => {
                  router.push({
                    name: 'WorkflowTaskPage',
                    params: { taskId: 0, view: 'newfromlist' },
                  });
                }
              "
            ></RemPrimaryButtonSmall>

            <RemPrimaryButtonSmall
              :label="$t('message.taskAssinment')"
              :method="
                () => {
                  router.push({
                    name: 'WorkflowTaskAssign',
                    params: {},
                  });
                }
              "
            ></RemPrimaryButtonSmall>
          </div>

          <RobberVueGrid
            v-if="!state.forceRefresh"
            ref="tasksGrid"
            :config="taskGridConfigComputed"
            class="bg-gray-800 p-4"
          />
        </div>
        <!-- eslint-disable -->
        <AssignTaskModal v-model:showModal="assignModalData.showModal"></AssignTaskModal>
        <ApproveTaskModal
          v-model:showModal="approveModalData.showModal"
          v-model:taskId="approveModalData.taskId"
          @refreshGrid="refreshGrid"
        ></ApproveTaskModal>
        <!-- eslint-enable -->

        <div style="height: 200rem"></div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
//components
import BaseLayout from '../layouts/BaseLayout.vue';
import RobberVueGrid from '@/components/RVG/RobberVueGrid.vue';
import BackToMapButton from '../components/BackToMapButton.vue';
import RemPrimaryButtonSmall from '../components/REM/RemPrimaryButtonSmall.vue';
import ApproveTaskModal from '../components/REM/Workflow/ApproveTaskModal.vue';
import AssignTaskModal from '../components/REM/Workflow/AssignTaskModal.vue';

// utils
import { computedAsync } from '@vueuse/core';
import RVGUtils from '../components/RVG/RobberVueGridUtils.js';
import router from '@/misc/router';
import { ref } from 'vue';
//import User from "../misc/User";
//import Axios from '@/misc/axios';
//import store from '@/misc/vuex-store'
//import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// RVGS
import taskGridConfig from '@/misc/gridConfigs/workflowPage/universalGridConfig.js';

const tasksGrid = ref();

const assignModalData = ref({
  showModal: false,
  taskId: 0,
});

const approveModalData = ref({
  showModal: false,
  taskId: 0,
});

const state = ref({
  forceRefresh: false,
});

const taskGridConfigComputed = computedAsync(async () => {
  const [
    /*priorityMapping,*/
    typeMapping,
    /* partnerSubTypeMapping*/
  ] = await Promise.all([
    //RVGUtils.getFormatterMappingForOptionType("WORKFLOW_TASK_PRIORITY"),
    RVGUtils.getFormatterMappingForEnum(),
  ]);
  return taskGridConfig(t, approveModalData, assignModalData, typeMapping);
}, taskGridConfig(t, approveModalData, assignModalData));

const refreshGrid = () => {
  state.value.forceRefresh = true;
  setTimeout(() => {
    state.value.forceRefresh = false;
  }, 100);
};
</script>

<style scoped></style>
