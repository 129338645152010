let uniqueField = "propertiesGrid";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import store from "@/misc/vuex-store";

export default (state, t, router, endPoint) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      endPoint,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    /*frontendFilter: (record) => {
      
      if (
        !state.value.selectedIds.includes(record.id) &&

        (state.value.searchParams.address.value == "" ||
        record.primaryAddressCompleteAddress.includes(state.value.searchParams.address.value)) &&

        (state.value.searchParams.floc.value == "" ||
        record.basicDataFloc.includes(state.value.searchParams.floc.value))
      ) {
        return true;
      }
      return false;
    },*/
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];
              /*const config = state.value.data.find((e) => e.model == "PROPERTY_ID")
              if (record.id != config.value) {
                buttons.push({
                title: "",
                tooltip: t("message.open"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  const config = state.value.data.find((e) => e.model == "PROPERTY_ID")
                  config.value = record.id;
                  config.displayValue =
                    record.primaryAddressCompleteAddress;
                  //state.value.openPropertyModal = false;
                },
              });
              }*/

              buttons.push({
                title: "",
                tooltip: t("message.open"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  const routeData = router.resolve({
                                      name: "property-buildings",
                                      params: { propertyId: record.id },
                                    });
                                    store.commit("setRemTab", 1)
                                    window.open(routeData.href, "_blank");
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.add"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/><svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  state.value.selectedIds.push(record.id);
                  state.value.rvgUpdate = state.value.rvgUpdate +1;
                  //state.value.openPropertyModal = false;

                 // state.value.updateKey = state.value.updateKey + 1;
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Number",
        },
        exportable: false,
        orderable: true,
      },
      primaryAddressCompleteAddress: {
        title: t("message.address"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.primaryAddressCompleteAddress
            ? record.primaryAddressCompleteAddress
            : "N/A";
        },
      },

      basicDataKng: {
        title: t("message.kng"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataKng ? record.basicDataKng : "N/A";
        },
      },
      basicDataFloc: {
        title: t("message.floc"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFloc ? record.basicDataFloc : "N/A";
        },
      },
    },
  };
};
