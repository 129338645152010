let uniqueField = "propertiesGrid";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import store from "@/misc/vuex-store";

export default (
  state,
  t,
  router,
  endpoint,
  basicDataTypeMapping,
  basicDataClassificationMapping,
  basicDataStatusMapping
) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      endpoint,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];
              /*const config = state.value.data.find((e) => e.model == "PROPERTY_ID")
              if (record.id != config.value) {
                buttons.push({
                title: "",
                tooltip: t("message.open"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  const config = state.value.data.find((e) => e.model == "PROPERTY_ID")
                  config.value = record.id;
                  config.displayValue =
                    record.primaryAddressCompleteAddress;
                  //state.value.openPropertyModal = false;
                },
              });
              }*/

              buttons.push({
                title: "",
                tooltip: t("message.open"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  const routeData = router.resolve({
                    name: "property-buildings",
                    params: { propertyId: record.id },
                  });
                  store.commit("setRemTab", 1)
                  window.open(routeData.href, "_blank");
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.delete"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/><path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  const config = state.value.data.PROPERTY_ID
                  let i = 0;
                  let found = false;

                  if(config.value.length == 1){
                    config.value = []
                  }

                  while (i < config.value.length && found == false) {
                    if (config.value[i] == record.id) {
                      config.value.splice(i, 1);
                    }
                    i++;
                  }
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Number",
        },
        exportable: false,
        orderable: true,
      },
      primaryAddressCompleteAddress: {
        title: t("message.address"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.primaryAddressCompleteAddress
            ? record.primaryAddressCompleteAddress
            : "N/A";
        },
      },
      basicDataPropertySheetAppellation: {
        title: t("message.propertySheetAppellation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPropertySheetAppellation
            ? record.basicDataPropertySheetAppellation
            : "N/A";
        },
      },
      basicDataFloorArea: {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataKng: {
        title: t("message.kng"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataKng ? record.basicDataKng : "N/A";
        },
      },
      basicDataFloc: {
        title: t("message.floc"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFloc ? record.basicDataFloc : "N/A";
        },
      },
      basicDataSap: {
        title: t("message.sap"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataSap ? record.basicDataSap : "N/A";
        },
      },
      basicDataTypeValue: {
        title: t("message.nature"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataTypeValue ? record.basicDataTypeValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataTypeMapping,
        },
      },
      basicDataProtection: {
        title: t("message.protection"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataProtectionNote: {
        title: t("message.protectionNote"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataProtectionNote
            ? record.basicDataProtectionNote
            : "N/A";
        },
      },
      basicDataNumberDedicated: {
        title: t("message.numberDedicated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataNumberCanBeSeated: {
        title: t("message.numberCanBeSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataNumberSeated: {
        title: t("message.numberSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataClassificationValue: {
        title: t("message.classification"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataClassificationValue
            ? record.basicDataClassificationValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataClassificationMapping,
        },
      },
      basicDataStatusValue: {
        title: t("message.status"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStatusValue
            ? record.basicDataStatusValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataStatusMapping,
        },
      },
      basicDataSolarPanel: {
        title: t("message.solarPanel"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataFuelCellGenerator: {
        title: t("message.fuelCellGenerator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataDieselGenerator: {
        title: t("message.dieselGenerator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataEnergyTradingPartner: {
        title: t("message.energyTradingPartner"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
    },
  };
};
