export default {
  data: [
    {
      title: "legalSituation",
      value: "",
      valueLabel: "",
      model: "legalSituationType",
      type: "option",
      optionValue: "LEGAL_SITUATION",
    },
    {
      title: "legalRelationship",
      value: "",
      valueLabel: "",
      model: "legalRelationshipType",
      type: "option",
      optionValue: "LEGAL_RELATIONSHIP_TYPE",
    },
    {
      title: "ownership",
      value: "",
      model: "ownership",
      type: "text",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "floorArea",
      value: "",
      model: "floorArea",
      type: "positiveNumber",
    },
  ]
}