export default {
  data: [
    {
      title: "type",
      value: "",
      model: "type",
      type: "option",
      optionValue: "CENTRAL_HEATING_TYPE",
    },
    {
      title: "canBePhased",
      value: "",
      model: "canBePhased",
      type: "checkbox",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "setPerPremise",
      value: "",
      model: "setPerPremise",
      type: "checkbox",
    },
    {
      title: "energyIntake",
      value: "",
      model: "energyIntake",
      type: "positiveNumber",
    },
    {
      title: "heatingPerformance",
      value: "",
      model: "heatingPerformance",
      type: "positiveNumber",
    },
    {
      title: "installationDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "maintenanceCycle",
      value: "",
      model: "maintenanceCycle",
      type: "text",
    },
    {
      title: "yearOfManufacture",
      value: "",
      model: "yearOfManufacture",
      type: "integer",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "date",
    },
  ]
}