<template>
  <div>
    <div v-for="event in events" :key="event.eventType">
      <div
        v-if="event.eventType == 'REMARK_MODIFIED'"
        class="items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
      >
        <time class="mb-1 text-xs font-normal text-end text-gray-400 sm:order-last sm:mb-0">{{
          new Date(event.triggeredAt).toLocaleString()
        }}</time>
        <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
          <a href="#" class="font-semibold text-blue-600 dark:text-secondary hover:underline">{{
            event.triggeredByUserName
          }}</a>
          megjegyzésekkel kapcsolatos módosítást eszközölt:
          <span
            v-if="event.eventData.dataModificationType"
            class="font-semibold text-gray-900 dark:text-white"
            >{{
              event.eventData.dataModificationType == 'CREATION'
                ? $t('message.creation')
                : event.eventData.dataModificationType == 'MODIFICATION'
                ? $t('message.edit')
                : $t('message.delete')
            }}</span
          >
          <!-- <div
            class="mt-2 p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300"
          >
            {{ comment }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed /*onMounted, ref*/ } from 'vue';
import store from '@/misc/vuex-store';
// import TenantConfigHandler from '@/misc/TenantConfigHandler';
// import Axios from '@/misc/axios';
// import { useRoute } from 'vue-router';

// const route = useRoute()
// const taskId = route.params.taskId

// const state = ref({
//     comments: []
// })

const events = computed(() => {
  return store.getters.getEvent;
});

// const getComments = async () => {
//   const uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/workflow/task/${taskId}/comments`;

//   try {
//     const response = await Axios.get(uri, {
//       onTokenRefreshed: () => {
//         getComments();
//       },
//     });

//     state.value.comments = response.data.content
//     console.log(state.value.comments);

//   } catch (error) {
//     console.log(error);
//   }
// };

// const comment = computed(()=>{
//     let c = null;
//     events.value.forEach(event => {
//         state.value.comments.forEach(comment => {
//             if(event.eventData.commentId === comment.id){
//                 c = comment.comment
//             }
//         })
//     });

//     return c
// })

// onMounted(() => {
//   getComments();
// });
</script>

<style></style>
