<template>
    <div>
        <!-- Modal toggle -->
        <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
        <div class="modal">
            <div class="modal-box max-w-4xl">
                <div class="h-full overflow-y-auto w-full">
                    <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between mb-2">
                        <div class="flex flex-row items-center gap-2 justify-center">
                            <h2
                                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow">
                                <span class="text-secondary">{{
                                    $t("message.assignNew")
                                }}</span>
                            </h2>
                        </div>
                        <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </a>
                    </div>
                    <!--eslint-disable-->
                    <div class="px-5 pb-5">
                        <div class="">
                            <RobberVueGrid ref="connectCFGrid" :config="connectCFGridConfigComputed" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--CONFIRMATION MODAL-->
        <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
        <div class="modal">
            <div class="modal-box">
                <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
                    {{ $t('message.' + state.confirmationModal.message) }}
                </h3>
                <div class="modal-action">
                    <label class="btn btn-sm text-white font-light text-xs" @click="() => {
                        state.confirmationModal.show = false;
                    }
                        ">{{ $t("message.no") }}</label>
                    <label class="btn btn-sm bg-secondary text-white font-light text-xs" @click="() => {
                        state.confirmationModal.show = false;
                    }
                        ">{{ $t("message.yes") }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, watch, computed, ref } from "vue";
// import Axios from "@/misc/axios";
// import toast from "@/misc/toast.js";
import store from "@/misc/vuex-store.js";
import { useI18n } from "vue-i18n";
// import TenantConfigHandler from "@/misc/TenantConfigHandler";
import RobberVueGrid from "@/components/RVG/RobberVueGrid.vue"
import connectCFGridConfig from "@/misc/gridConfigs/workflowAdmin/connectCFGridConfig.js";

const { t } = useI18n();

// const emits = defineEmits(['refreshGrid'])

const state = reactive({
    confirmationModal: {
        show: false,
        message: "",
        function: "",
    }
});

const connectCFGrid = ref()

const props = defineProps(["showModal", "id", "taskTypeId", "workflowId", "trCustomFieldsGrid"]);

// const connectCF = async () => {

// }

let connectCFGridConfigComputed = computed(() => {
    return connectCFGridConfig(state, t, props.taskTypeId, props.workflowId, props.id, props.trCustomFieldsGrid);
});

watch(
    () => props.showModal,
    () => {
        state.showModal = props.showModal;
    }
);

const closeModal = () => {
    store.commit('setIsTrCfModalOpen', false);
}
</script>

<style scoped></style>