<template>
  <div>
    <!-- Modal toggle -->
    <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
    <div class="modal">
      <div class="modal-box">
        <div class="h-full overflow-y-auto w-full">
          <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
            <div class="flex flex-row items-center gap-2 justify-center">
              <h2
                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow">
                <span class="text-secondary">{{
                  !props.editMode ? $t("message.addNew") : $t("message.edit")
                }}</span>
              </h2>
            </div>
            <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>
          <!--eslint-disable-->
          <div class="px-5 pb-5">
            <div class="flex flex-col gap-2 w-full">
              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput v-model:inputValue="state.name" :title="$t('message.name')" type="text"
                  class="w-full mr-4">
                </ImFormInput>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput v-model:inputValue="state.valueType" :title="$t('message.valueType')" type="optionManual"
                  :manualOptions="[
                    { value: 'NUMBER', label: 'number' },
                    { value: 'DOUBLE', label: 'double' },
                    { value: 'STRING', label: 'string' },
                    { value: 'BOOLEAN', label: 'boolean' },
                    { value: 'DATE', label: 'date' }]" class="w-full mr-4">
                </ImFormInput>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput v-model:inputValue="state.inputComponent" :title="$t('message.inputComponent')" type="text"
                  class="w-full mr-4">
                </ImFormInput>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput v-model:inputValue="state.viewComponent" :title="$t('message.viewComponent')" type="text"
                  class="w-full mr-4">
                </ImFormInput>
              </div>

              <div :class="{ hideOpenIndicator: state.businessType }">
                <RemLabel class="mt-2" title="Business type"></RemLabel>
                <div class="dropdown">
                  <select class="input input-sm input-bordered w-full pr-2" v-model="state.businessType">
                    <option v-for="bt in state.businessTypes" v-bind:key="bt" :value="bt">{{
                      bt }}
                    </option>
                  </select>
                  <button v-if="state.businessType" class="clear-button" @click="state.businessType = null"
                    aria-label="Törlés">
                    <span class="text-sm bold">
                      X
                    </span>
                  </button>
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput v-model:inputValue="state.valueConverterParam" :title="$t('message.valueConverterParam')"
                  type="text" class="w-full mr-4">
                </ImFormInput>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput v-model:inputValue="state.dataType" :title="$t('message.dataType')" type="optionManual"
                  :manualOptions="[
                    { value: 'SINGLE', label: 'single' },
                    { value: 'MULTIPLE', label: 'multiple' },]" class="w-full mr-4">
                </ImFormInput>
              </div>

            </div>
          </div>
        </div>
        <div class="modal-action">
          <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
            saveMetaData();
            this.$emit('refreshGrid')
            store.commit('setIsModalOpen', false);
          }
            ">{{ $t("message.save") }}</label>
        </div>
      </div>
    </div>
    <!--CONFIRMATION MODAL-->
    <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
    <div class="modal">
      <div class="modal-box">
        <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
          {{ $t('message.' + state.confirmationModal.message) }}
        </h3>
        <div class="modal-action">
          <label class="btn btn-sm text-white font-light text-xs" @click="() => {
            state.confirmationModal.show = false;
          }
            ">{{ $t("message.no") }}</label>
          <label class="btn btn-sm bg-secondary text-white font-light text-xs" @click="() => {
            state.confirmationModal.show = false;
          }
            ">{{ $t("message.yes") }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
import store from "@/misc/vuex-store.js";
import { useI18n } from "vue-i18n";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import ImFormInput from "@/components/ImFormInput.vue";
import RemLabel from "@/components/REM/imFormInput/RemLabel.vue";

const { t } = useI18n();

// const emits = defineEmits(['refreshGrid'])

const state = reactive({
  name: null,
  valueType: null,
  inputComponent: null,
  viewComponent: null,
  businessType: null,
  valueConverterParam: null,
  dataType: null,
  businessTypes: [],
  confirmationModal: {
    show: false,
    message: "",
    function: "",
  }
});

const props = defineProps(["showModal", "id", 'metaDataGrid', 'metaData', 'editMode']);

const getBusinessTypes = async () => {
  const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/metadata/business-types`

  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        getBusinessTypes()
      }
    })
    state.businessTypes = response.data;
  } catch (error) {
    console.log(error);
  }
}

getBusinessTypes()

const saveMetaData = async () => {
  let uri = ''
  const hermes = {
    name: state.name,
    valueType: state.valueType,
    inputComponent: state.inputComponent,
    viewComponent: state.viewComponent,
    businessType: state.businessType,
    valueConverterParam: state.valueConverterParam,
    dataType: state.dataType,
  }

  if (!props.editMode) {
    uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/metadata`

    try {
      await Axios.post(uri, hermes, {
        onTokenRefreshed: () => {
          saveMetaData()
        }
      })

      toast.success(t("message.successfulSave"))
      emptyForm()
      props.metaDataGrid?.refresh()
    } catch (error) {
      console.log(error);
      toast.error(t("message.notSuccessfulSave"))
    }
  } else if (props.editMode) {
    uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/metadata/${props.metaData.id}`

    try {
      await Axios.put(uri, hermes, {
        onTokenRefreshed: () => {
          saveMetaData()
        }
      })

      toast.success(t("message.successfulSave"))
      emptyForm()
      props.metaDataGrid?.refresh()
    } catch (error) {
      console.log(error);
      toast.error(t("message.notSuccessfulSave"))
    }
  }
}

const fillForm = () => {
  state.name = props.metaData.name
  state.valueType = props.metaData.valueType
  state.inputComponent = props.metaData.inputComponent
  state.viewComponent = props.metaData.viewComponent
  state.businessType = props.metaData.businessType
  state.valueConverterParam = props.metaData.valueConverterParam
  state.dataType = props.metaData.dataType
}

watch(
  () => props.showModal,
  () => {
    state.showModal = props.showModal;
  }
);

watch(
  () => props.editMode,
  () => {
    props.editMode ? fillForm() : emptyForm();
  }
)

const closeModal = () => {
  store.commit('setIsModalOpen', false);
}

const emptyForm = () => {
  state.name = null
  state.valueType = null
  state.inputComponent = null
  state.viewComponent = null
  state.businessType = null
  state.valueConverterParam = null
  state.dataType = null
}
</script>

<style scoped>

select[disabled] {
    @apply border border-gray-600;
}

.im-dropdown-content {
    position: absolute
}

.im-invisible {
    display: none;

}

.dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 96%;
}

.clear-button {
    position: absolute;
    right: 0.6rem;
    background: none;
    border: none;
    color: rgb(175, 170, 170);
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 1;
}

.clear-button:hover {
    cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
    display: none;
}

.dropdown::before {
    position: absolute;
    font-size: 12px;
    content: " \25BC";
    top: 7px;
    right: -8px;
    height: 20px;
    width: 28px;
    pointer-events: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.option-hover {
    padding: 5px 0 5px 5px;
    font-size: 0.875rem;

}

.option-hover:hover {
    background-color: rgb(147, 185, 247);
    color: #242933;
}

ul .option-hover:first-of-type {
    border-radius: 10px 10px 0 0;
}

ul .option-hover:last-of-type {
    border-radius: 0 0 10px 10px;
}
</style>