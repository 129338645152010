import axios from "@/misc/axios.js";
import toast from "@/misc/toast.js";

import TenantConfigHandler from "@/misc/TenantConfigHandler";
import { watch } from "vue";

import remFormUtils from "../../misc/remFormUtils";

export default {
  getMainEnergetics: async function (state, t) {
    axios
      .get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          `/rem/building/${state.value.buildingId}/energetics`,
        {
          onTokenRefreshed: () => {
            this.getMainEnergetics(state);
          },
        }
      )
      .then((resp) => {
        state.value.energeticsId = resp.data.energeticsId;
        for (const [key /*, value*/] of Object.entries(state.value.basicData)) {
          state.value.basicData[key].value =
            resp.data[key] == true ? true : false;
          watch(
            () => state.value.basicData[key].value,
            () => {
              this.saveMainEnergetics(state, t);
            }
          );
        }
      });
  },
  saveMainEnergetics: async function (state, t) {
    const hermes = {};

    for (const [key, value] of Object.entries(state.value.basicData)) {
      hermes[key] = value.value;
    }

    let resp = await axios.put(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/building/${state.value.buildingId}/energetics`,
      hermes,
      {
        onTokenRefreshed: () => {
          this.saveMainEnergetics(state);
        },
      }
    );

    if (resp.status != 200) {
      toast.error(t("message.notSuccessfulSave"));
    }
  },
  saveUniversal: async function (state, formdata, endpoint, tab, t) {
    const hermes = {};

    state.value[formdata + "Data"].forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });

    return remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/building/${state.value.buildingId}/energetics/${endpoint}`,
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/building/energetics/${endpoint}/${state.value[formdata + "Id"]}`,
      hermes,
      formdata,
      tab,
      state,
      t
    );
  },

  deleteUniversal: async function (state, endpoint, formdata, tab, t) {
    let resp = await axios.delete(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/building/energetics/${endpoint}/${state.value[formdata + "Id"]}`
    );
    console.log(resp);
    if (resp.status == 204) {
      state.value.tab = tab;
      state.value[`${formdata}Id`] = 0;
      state.value[`${formdata}Edit`] = false;
      toast.success(t("message.deleteSuccessful"));
    } else {
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
};
