<template>
    <div class="my-2 p-4 overflow-y-auto max-h-96">
        <!-- <div>
            Quick filters:
        </div> -->
        <div class="w-full">
            <ul class="flex flex-wrap">
                <li v-for="item in state.menuItems" :key="item" class="1/3 my-2">
                    <a @click="filtering(item); getEvents()"
                        class="hover:cursor-pointer hover:text-secondary text-white p-1 mx-2 bg-gray-900"
                        :class="{ active: state.selectedItems.includes(item) }" aria-current="page">{{ $t(`message.${item}`) }}</a>
                </li>
            </ul>
        </div>
        <div class="flex justify-end mt-5">
            <button v-if="state.buttonToggle" @click="state.buttonToggle = !state.buttonToggle; getEvents()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" stroke="currentColor" stroke-width="0.2"
                    class="w-7 h-7 btn btn-secondary btn-sm hover:!opacity-80 btn-circle" viewBox="0 0 25 25">
                    <path
                        d="M20 17H23L19 21L15 17H18V3H20V17M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z" />
                </svg>
            </button>
            <button v-if="!state.buttonToggle" @click="state.buttonToggle = !state.buttonToggle; getEvents()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" stroke="currentColor" stroke-width="0.2"
                    class="w-7 h-7 btn btn-secondary btn-sm hover:!opacity-80 btn-circle" viewBox="0 0 25 25">
                    <path
                        d="M18 7H15L19 3L23 7H20V21H18V7M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z" />
                </svg>
            </button>
        </div>

        <div class="">
            <!-- <EventTypeAll v-if="state.selectedItem == 'ALL_EVENTS'"/> -->
            <EventTypeOne v-if="state.selectedItems.includes('DATA_MODIFIED') || state.selectedItems.length == 0"/>
            <EventTypeTwo v-if="state.selectedItems.includes('STATE_TRANSITION') || state.selectedItems.length == 0"/>
            <EventTypeThree v-if="state.selectedItems.includes('REMARK_MODIFIED') || state.selectedItems.length == 0" />
            <EventTypeFour v-if="state.selectedItems.includes('DOCUMENTS_MODIFIED') || state.selectedItems.length == 0"/>
            <EventTypeFive v-if="state.selectedItems.includes('LINKS_MODIFIED') || state.selectedItems.length == 0"/>
            <EventTypeSix v-if="state.selectedItems.includes('SYSTEM_EVENT') || state.selectedItems.length == 0"/>
        </div>

    </div>
</template>

<script setup>

import { onMounted, ref, watch } from 'vue'
import { useRoute } from "vue-router";
import wfUtils from "./workflowTaskUtils"
import store from "@/misc/vuex-store"

//Components
import EventTypeOne from '@/components/REM/Workflow/EventHandler/EventTypeOne.vue';
import EventTypeTwo from '@/components/REM/Workflow/EventHandler/EventTypeTwo.vue';
import EventTypeThree from '@/components/REM/Workflow/EventHandler/EventTypeThree.vue';
import EventTypeFour from '@/components/REM/Workflow/EventHandler/EventTypeFour.vue';
import EventTypeFive from '@/components/REM/Workflow/EventHandler/EventTypeFive.vue';
import EventTypeSix from '@/components/REM/Workflow/EventHandler/EventTypeSix.vue';
// import EventTypeAll from '@/components/REM/Workflow/EventHandler/EventTypeAll.vue';

let route = useRoute();

const state = ref({
    buttonToggle: true,
    menuItems: [/*'ALL_EVENTS'*/ 'DATA_MODIFIED', 'STATE_TRANSITION', 'REMARK_MODIFIED', 'DOCUMENTS_MODIFIED', 'LINKS_MODIFIED', 'SYSTEM_EVENT'],
    selectedItems: [],
});

state.value.taskId = route.params.taskId;

const filtering = (event) => {
    const i = state.value.selectedItems.indexOf(event)
    if(i !== -1){
        state.value.selectedItems.splice(i, 1)
    }else{
        state.value.selectedItems.push(event)
    }
}

const getEvents = async () => {
    const response = await wfUtils.getEvents(state.value.taskId, state.value.selectedItems)
    if(state.value.buttonToggle){
        const r = response.sort((a, b) => a.triggeredAt - b.triggeredAt)
        store.commit("setEvent", r);
    }else{
        const re = response.reverse((a, b) => b.triggeredAt - a.triggeredAt)
        store.commit("setEvent", re);
    } 
}

watch(state.value.selectedItems, ()=>{
    getEvents() 
})

onMounted(()=>{
    getEvents()
})
</script>

<style scoped>
.active {
    @apply text-secondary
}
</style>