let uniqueField = "wfConnectCFGrid";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import axios from "@/misc/axios";
import toast from "@/misc/toast";
import store from "@/misc/vuex-store";
// import router from '../../router';

export default (
  state,
  t,
  taskTypeId,
  workflowId,
  transitionId,
  trCustomFieldsGrid
) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${transitionId}/not-assigned-custom-fields`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: "",
                tooltip: t("message.attach"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="white" stroke="currentColor" stroke-width="0.2" class="w-4 h-4" viewBox="0 0 24 24"><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "editCustomFieldButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: async () => {
                    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${transitionId}/custom-field-config/${record.id}/add`
                    try {
                        await axios.patch(uri)
                        toast.success(t("message.successfulSave"))
                        store.commit('setIsTrCfModalOpen', false)
                        trCustomFieldsGrid.refresh()
                    } catch (error) {
                        console.log(error);
                        toast.error(t("message.notSuccessfulSave"))
                    }
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: t("message.identifier"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Number",
        },
        exportable: false,
        orderable: true,
      },
      name: {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.name ? record.name : "N/A";
        },
      },
      code: {
        title: "Code",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.code ? record.code : "N/A";
        },
      },
      groupName: {
        title: t("message.groupName"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.groupName ? record.groupName : "N/A";
        },
      },
      workflowName: {
        title: t("message.workflowName"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.workflowName ? record.workflowName : "N/A";
        },
      },
      workflowVersion: {
        title: t("message.workflowVersion"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Integer",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.workflowVersion ? record.workflowVersion : "N/A";
        },
      },
    },
  };
};
