import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
//import { watchEffect } from "vue";
import router from "@/misc/router.js";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import Utils from "../../misc/Utils";
import store from "@/misc/vuex-store.js";
import remFormUtils from "../../misc/remFormUtils";

export default {
  asyncGet: async function (state, propertyId /*, formData*/) {
    const response = await Utils.fetchPropertyById(propertyId);

    if (response) {
      state.value.propertyBasicData.forEach((e) => {
        let model =
          (e.model + "").charAt(0).toUpperCase() + (e.model + "").slice(1);
        if (e.type == "option") {
          e.value = response[`basicData${model}Id`];
          e.valueLabel = response[`basicData${model}Value`];
        } else {
          e.value = response[`basicData${model}`];
        }
      });
    }
  },
  saveProperty: async function (state, t, propertyId) {
    const hermes = {};
    hermes.basicData = {};

    state.value.propertyBasicData.forEach((e) => {
      hermes.basicData[e.model] = e.value == "" ? null : e.value;
    });
    hermes.property = propertyId;

    if (propertyId != 0) {
      await Axios.put(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/property/" +
          propertyId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveProperty(state, t, propertyId);
          },
        }
      ).then(async (resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            await this.asyncGet(state, propertyId);
            state.value.basicDataEdit = false;
            store.commit("setRemFormEdit", false);
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  savePartner: async function (state, t, id) {
    const hermes = {};

    state.value.partnerData.forEach((e) => {
      if (e.model != "") {
        hermes[e.model] = e.value == "" ? null : e.value;
      }
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/property/${id}/partner`,
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/partner/` +
        state.value.partnerId,
      hermes,
      "partner",
      3,
      state,
      t
    );
  },
  saveBuilding: async function (state, t, propertyId) {
    const hermes = {
      siteDesignId: "",
      property: propertyId,
      basicData: {
        floorArea: "",
        mtArea: "",
        mainFunction: "",
        premiseNumber: "",
        isAccessible: "",
        numberDedicated: "",
        numberCanBeSeated: "",
        numberSeated: "",
        maxHeight: "",
        status: "",
        floorsBelowSurface: "",
        floorsAboveSurface: "",
        attic: "",
        atticCanBeUsed: "",
        saps: "",
        tcafm: "",
        parcelNumber: "",
        operationResponsibleId: "",
        cleanerResponsibleId: "",
        suppliesResponsibleId: "",
        tuResponsibleId: "",
        ownerOfAssetsId: "",
        assetManagerId: "",
      },
      paymentObligation: "",
      numberOfStairways: "",
      numberOfElevators: "",
      foundation: "",
      bearingMasonry: "",
      slabStructureInter: "",
      slabSubStructureInter: "",
      slabStructureClosing: "",
      slabSubStructureClosing: "",
      flatRoof: "",
      roofType: "",
      roofing: "",
      insulation: "",
    };

    state.value.buildingData.forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });

    state.value.buildingBasicData.forEach((e) => {
      if(e.type=="optionMultiSelect"){
        hermes.basicData[e.model + "s"] = e.value == "" ? null : e.value;
      }else{
        hermes.basicData[e.model] = e.value == "" ? null : e.value;
      }
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/building",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/building/" +
        state.value.buildingId,
      hermes,
      "building",
      1,
      state,
      t
    );
  },
  saveSecondaryAddress: async function (state, propertyId, t) {
    const hermes = {
      propertyId: propertyId,
    };

    state.value.addressData.forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/property/address",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/property/address/" +
        state.value.addressId,
      hermes,
      "address",
      2,
      state,
      t,
      "message.missingFormFields"
    );
  },
  saveUtilization: async function (state, propertyId, t) {
    const hermes = {
      propertyId: propertyId,
      floorArea: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.value.utilizationData.forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization/" +
        state.value.utilizationId,
      hermes,
      "utilization",
      7,
      state,
      t
    );
  },
  saveObligation: async function (state, t, propertyId) {
    const hermes = {
      propertyId: propertyId,
      otherRight: "",
      permission: "",
      obliged: "",
    };

    state.value.obligationData.forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/property/obligation",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/property/obligation/" +
        state.value.obligationId,
      hermes,
      "obligation",
      9,
      state,
      t
    );
  },
  uploadFile: async function (
    state,
    docsInput,
    t,
    propertyId,
    propertyDocumentsGrid,
    allowedExtensions
  ) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/property/${propertyId}/document`;

    const formData = new FormData();
    formData.append("mainType", state.value.selectedMainType);
    formData.append("subType", state.value.selectedSubType);
    formData.append("document", docsInput.value.files[0]);
    formData.append("name", state.value.docuName);
    formData.append("dateOfOrigin", state.value.docuOriginDate);
    formData.append("contractIdentifier", state.value.contractIdentifier);

    try {
      const resp = await Axios.post(uri, formData);
      if (resp.status == 200) {
        state.value.docuName = "";
        state.value.docuOriginDate = "";
        state.value.selectedMainType = "";
        state.value.selectedSubType = "";
        state.value.contractIdentifier = "";
        document.getElementById("file_input").value = "";
        toast.success(t("message.successfulSave"));
      } else {
        toast.error(t("message.saveNotSuccessful"));
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 406) {
        toast.error(
          t("message.fileFormatError") +
            allowedExtensions.value +
            t("message.fileFormatError2")
        );
      } else if (error.response.status == 413) {
        toast.error(t("message.fileSizeError"));
      } else {
        toast.error(t("message.saveError"));
      }
    }

    propertyDocumentsGrid?.value?.refresh();
  },
  saveLegalsit: async function (state, t, propertyId) {
    const hermes = {
      propertyId: propertyId,
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      floorArea: "",
    };

    state.value.legalsitData.forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation/" +
        state.value.legalsitId,
      hermes,
      "legalsit",
      6,
      state,
      t
    );
  },
  deleteLegalSituation: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/legal-situation/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteLegalSituation();
        },
      });
      state.value.tab = 6;
      toast.success(t("message.deleteSuccessful"));
      store.commit("setRemFormEdit", false);
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  toBuilding: function (id) {
    router.push({
      name: "building-premises",
      params: { buildingId: id },
    });
    store.commit("setRemTab", 1);
  },
};
