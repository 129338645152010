import axios from "@/misc/axios";
import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  saveWFCustomField: async (id, field) => {
    if (
      !id ||
      (!field.customFieldId ||
      field.customFieldId == undefined ||
      field.defaultReadonly == true)
    ) {
      return;
    }
    let hermes = [];

    if (field.metaDataType == "SINGLE") {
      let obj = {};
      obj[`${field.backendType.toLowerCase()}Value`] = field.value;
      hermes.push(obj);
    } else {
      field.value.forEach((element) => {
        let obj = {};
        obj[`${field.backendType.toLowerCase()}Value`] = element;
        hermes.push(obj);
      });

    }
    
    const resp = await axios.put(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/workflow/task/" +
        id +
        "/custom-field-value/" +
        field.customFieldId,
      hermes,
      {
        onTokenRefreshed: () => {
          this.saveWFCustomField(id, field);
        },
      }
    );

    return resp;
  },

  formatCustomFieldForSaving: (field) => {
    if (
      !field.customFieldId ||
      field.customFieldId == undefined ||
      field.defaultReadonly == true
    ) {
      return;
    }
    let hermes = {
      customFieldId: field.customFieldId,
      values: []
    };

//console.log(field);

    if (field.metaDataType == "SINGLE") {
      let obj = {};
      obj[`${field.backendType.toLowerCase()}Value`] = field.value;
      hermes.values.push(obj);
    } else {
      field.value.forEach((element) => {
        let obj = {};
        obj[`${field.backendType.toLowerCase()}Value`] = element;
        hermes.values.push(obj);
      });
    }
    

    return hermes;
  },

};
