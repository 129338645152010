<template>
  <div class="w-full border border-gray-700 rounded-lg">
    <v-select
      :options="filteredSuggestions"
      label="value"
      v-model="state.selectedOption"
      :placeholder="t('message.choose')"
      :class="{ hideOpenIndicator: state.selectedOption != null }"
    >
      <template v-slot:no-options>
        {{ $t('message.noMatchingOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script setup>
import 'vue-select/dist/vue-select.css';
import { reactive, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps(['modelValue', 'suggestions', 'parentId', 'hasParent']);
const emits = defineEmits(['update:modelValue']);

const state = reactive({
  selectedOption: props.modelValue || null,
});

const filteredSuggestions = computed(() => {
  const suggestions = props.suggestions.map((suggestion) => ({
    ...suggestion,
    value: suggestion.value + (suggestion.deleted ? t('message.inactiveLabel') : ''),
  }));
  console.log(suggestions);
  
  if (props.hasParent) {
    return suggestions.filter((suggestion) => suggestion.parent.id === props.parentId);
  }
  return suggestions;
});

watch(
  () => props.modelValue,
  (newValue) => {
    state.selectedOption = props.suggestions.find((option) => option.id === newValue) || null;
  }
);

watch(
  () => state.selectedOption,
  (newValue) => {
    if (newValue) {
      emits('update:modelValue', newValue.id);
    } else {
      emits('update:modelValue', null);
    }
  }
);
</script>

<style>
:root,
:host {
  --vs-controls-size: 0.7;
}

.v-select .vs__dropdown-toggle {
  background-color: hsl(220, 17.647%, 20%);
}

.v-select .vs__dropdown-menu {
  @apply border border-gray-700 rounded-md text-sm;
  background-color: hsl(220, 17.647%, 20%);
}

.v-select .vs__selected {
  @apply text-gray-200 text-sm;
}

.v-select .vs__open-indicator {
  @apply text-gray-200 text-sm;
}

.v-select .vs__search::placeholder {
  @apply text-sm;
}

.v-select .vs__clear,
.v-select .vs__open-indicator {
  fill: rgba(255, 255, 255, 0.623);
}
</style>
