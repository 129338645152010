let uniqueField = "buildingsGrid";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import store from "@/misc/vuex-store";

export default (
  state,
  t,
  router,
  endpoint,
  basicDataMainFunctionMapping,
  basicDataStatusMapping,
  basicDataSapMapping,
  basicDataFoundationMapping,
  basicDataBearingMasonryMapping,
  basicDataSlabStructureInterMapping,
  basicDataSlabSubStructureInterMapping,
  basicDataSlabStructureClosingMapping,
  basicDataSlabSubStructureClosingMapping,
  basicDataFlatRoofMapping,
  basicDataRoofingMapping,
  basicDataRoofTypeMapping,
  basicDataInsulationMapping
) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      endpoint,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
   
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];
              buttons.push({
                title: "",
                tooltip: t("message.open"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  
                  store.commit("setRemTab", 1);
                  const routeData = router.resolve({
                    name: "building-premises",
                    params: { buildingId: record.id, propertyId: record.propertyId, },
                  });
                  store.commit("setRemTab", 1);
                  window.open(routeData.href, "_blank");
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.delete"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/><path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  let i = 0;
                  let found = false;

                  while (i < state.value.selectedIds.length && found == false) {
                    if (state.value.selectedIds[i] == record.id) {
                      state.value.selectedIds.splice(i, 1);
                    }
                    i++;
                  }
                  //state.value.openPropertyModal = false;
                  state.value.rvgUpdate = state.value.rvgUpdate + 1
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Number",
        },
        exportable: false,
        orderable: true,
      },
      siteDesignName: {
        title: t("message.siteDesignPlaceholder"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.siteDesignName ? record.siteDesignName : "N/A";
        },
      },
      basicDataMainFunctionValue: {
        title: t("message.mainFunction"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataMainFunctionValue
            ? record.basicDataMainFunctionValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataMainFunctionMapping,
        },
      },
      basicDataFloorArea: {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataMtArea: {
        title: t("message.mtArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataPremiseNumber: {
        title: t("message.numberOfPremises"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPremiseNumber
            ? record.basicDataPremiseNumber
            : "N/A";
        },
      },
      basicDataIsAccessible: {
        title: t("message.isAccessible"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataNumberDedicated: {
        title: t("message.numberDedicated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataNumberCanBeSeated: {
        title: t("message.numberCanBeSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataNumberSeated: {
        title: t("message.numberSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataMaxHeight: {
        title: t("message.buildingMaxHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataMaxHeight ? record.basicDataMaxHeight : "N/A";
        },
      },
      basicDataStatusValue: {
        title: t("message.status"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStatusValue
            ? record.basicDataStatusValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataStatusMapping,
        },
      },
      basicDataFloorsBelowSurface: {
        title: t("message.floorsBelowSurface"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFloorsBelowSurface
            ? record.basicDataFloorsBelowSurface
            : "N/A";
        },
      },
      basicDataFloorsAboveSurface: {
        title: t("message.floorsAboveSurface"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFloorsAboveSurface
            ? record.basicDataFloorsAboveSurface
            : "N/A";
        },
      },
      basicDataAttic: {
        title: t("message.attic"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataAtticCanBeUsed: {
        title: t("message.atticCanBeUsed"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataSapValue: {
        title: t("message.sapBuildingId"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataSapValues ? record.basicDataSapValues : "N/A";
        },
        /*formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSapMapping,
        }, */
      },
      basicDataTcafm: {
        title: t("message.buildingMarking"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataTcafm ? record.basicDataTcafm : "N/A";
        },
      },
      basicDataParcelNumber: {
        title: t("message.parcelNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataParcelNumber
            ? record.basicDataParcelNumber
            : "N/A";
        },
      },
      basicDataOperationName: {
        title: t("message.operation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataOperationName
            ? record.basicDataOperationName
            : "N/A";
        },
      },
      basicDataCleanerName: {
        title: t("message.cleaning"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataCleanerName
            ? record.basicDataCleanerName
            : "N/A";
        },
      },
      basicDataSuppliesName: {
        title: t("message.supplies"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataSuppliesName
            ? record.basicDataSuppliesName
            : "N/A";
        },
      },
      basicDataTuiName: {
        title: "TU",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataTuiName ? record.basicDataTuiName : "N/A";
        },
      },
      basicDataOwnerOfAssetsName: {
        title: t("message.ownerOfAssets"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataOwnerOfAssetsName
            ? record.basicDataOwnerOfAssetsName
            : "N/A";
        },
      },
      basicDataAssetManagerName: {
        title: t("message.assetManager"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataAssetManagerName
            ? record.basicDataAssetManagerName
            : "N/A";
        },
      },
      numberOfStairways: {
        title: t("message.numberOfStairways"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      numberOfElevators: {
        title: t("message.numberOfElevators"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      /*"paymentObligation": {
        title: t("message.paymentObligations"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.paymentObligation ? record.paymentObligation : 'N/A'
        },             
      },*/
      foundationValue: {
        title: t("message.foundation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.foundationValue ? record.foundationValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataFoundationMapping,
        },
      },
      bearingMasonryValue: {
        title: t("message.bearingMasonry"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.bearingMasonryValue
            ? record.bearingMasonryValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataBearingMasonryMapping,
        },
      },
      slabStructureInterValue: {
        title: t("message.slabStructInter"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.slabStructureInterValue
            ? record.slabStructureInterValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSlabStructureInterMapping,
        },
      },
      slabSubStructureInterValue: {
        title: t("message.slabSubStructInter"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.slabSubStructureInterValue
            ? record.slabSubStructureInterValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSlabSubStructureInterMapping,
        },
      },
      slabStructureClosingValue: {
        title: t("message.slabStructClosing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.slabStructureClosingValue
            ? record.slabStructureClosingValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSlabStructureClosingMapping,
        },
      },
      slabSubStructureClosingValue: {
        title: t("message.slabSubStructClosing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.slabSubStructureClosingValue
            ? record.slabSubStructureClosingValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSlabSubStructureClosingMapping,
        },
      },
      flatRoofValue: {
        title: t("message.flatRoof"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.flatRoofValue ? record.flatRoofValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataFlatRoofMapping,
        },
      },
      roofTypeValue: {
        title: t("message.roofType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.roofTypeValue ? record.roofTypeValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataRoofTypeMapping,
        },
      },
      roofingValue: {
        title: t("message.roofing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.roofingValue ? record.roofingValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataRoofingMapping,
        },
      },
      insulationValue: {
        title: t("message.insulation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.insulationValue ? record.insulationValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataInsulationMapping,
        },
      },
    },
  };
};
