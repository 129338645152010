<template>
    <div>
        <input type="checkbox" class="modal-toggle" v-model="showModal" />
        <div class="modal">
            <div class="modal-box">
                <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100 mb-4">
                    {{ $t(`message.evaluation`) }}
                </h3>
                <!-- eslint-disable -->
                <!-- <WFTitleField v-model:inputValue="approvalState" :config="config"
                    textsm="true"></WFTitleField> -->
                <div v-if="showModal">
                    <WFTransitionField v-model:inputValue="state.taskData.state.value"
                        v-model:valueLabel="state.taskData.state.valueLabel" v-model:saveEvent="state.saveEvent"
                        :taskId="taskId" textsm="true"></WFTransitionField>

                </div>

                <!-- eslint-enable -->
                <div class="modal-action">

                    <label class="btn btn-sm bg-secondary text-white font-light text-xs"
                        @click="confirmationTrueMethod">{{ $t("message.save") }}</label>
                    <label class="btn btn-sm text-white font-light text-xs" @click="showModal = false">{{
                        $t("message.cancel") }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// import WFTitleField from './WFTitleField.vue';
import WFTransitionField from './WFTransitionField.vue';
import axios from "@/misc/axios.js"
import workflowTaskUtils from '../../../pages/WorkflowPageComponents/workflowTaskUtils';

//formdata
import taskFormData from "@/misc/remFormdata/workflow/taskData.js";

import { defineModel, ref, watch } from 'vue';
import TenantConfigHandler from '../../../misc/TenantConfigHandler';

const showModal = defineModel('showModal');
const taskId = defineModel('taskId');

const emits = defineEmits(['refreshGrid'])

const state = ref({
    taskId: "",
    saveEvent: 0,
    taskData: {},
})

taskFormData.data.forEach((e) => {
    e.value = "";
    e.valueLabel = ""
    state.value.taskData[e.model] = e;
})

const confirmationTrueMethod = async () => {
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/task/${taskId.value}/transition/${state.value.taskData.state.value}`;

    try {
        await axios.put(uri, {
            onTokenRefreshed: () => {
                confirmationTrueMethod();
            },
        })

        state.value.saveEvent = state.value.saveEvent + 1
        showModal.value = false

        emits('refreshGrid')
    } catch (error) {
        console.log(error);
    }
}

// const config = {
//     title: "approve",
//     optionSearchEndpoint: "uploader",
//     type: "option"
// }

watch(() => taskId.value, (newValue) => {

    if (newValue) {
        state.value.taskId = newValue
        taskFormData.data.forEach((e) => {
            e.value = "";
            e.valueLabel = ""
            state.value.taskData[e.model] = e;
        })
        workflowTaskUtils.getTask(state)
    }
})

</script>
