<template>
    <div>
        <input type="checkbox" class="modal-toggle" v-model="openModal" />
        <div class="modal">
            <div class="modal-box">
                <h2 class="text-lg text-secondary font-bold mb-4">
                    {{ $t("message.wfAddBuilding") }}
                </h2>
                <hr class="mb-4" />
                <span class="text-secondary font-bold my-4">{{
                    $t("message.WfPropertyData")
                    }}</span>
                <!-- eslint-disable -->
                <ImFormInput v-model:inputValue="state.searchParams.address.value"
                    :title="$t('message.' + state.searchParams.address.title)" type="text" class="my-4" />
                <ImFormInput v-model:inputValue="state.searchParams.floc.value"
                    :title="$t('message.' + state.searchParams.floc.title)" type="number" class="mb-4" />
                <!-- eslint-enable -->

                <span class="text-secondary font-bold my-4">{{
                    $t("message.WfBuildingData")
                    }}</span>
                <!-- eslint-disable -->
                <ImFormInput v-model:inputValue="state.searchParams.sapId.value"
                    :title="$t('message.' + state.searchParams.sapId.title)" type="text" class="my-4" />
                <ImFormInput v-model:inputValue="state.searchParams.buildingMarking.value"
                    :title="$t('message.' + state.searchParams.buildingMarking.title)" type="text" class="mb-4" />
                <!-- eslint-enable -->
                <RemPrimaryButtonSmall @click="() => {
                    state.updateKey = state.updateKey + 1;
                }
                    " :label="$t('message.search')" class="mb-4" />

                <div>
                    <span class="text-secondary font-bold mb-4">{{
                        $t("message.wfSearchResult")
                        }}</span>
                    <RobberVueGrid ref="propertiesGrid" :config="buildingsSelectorGridConfigComputed"
                        :key="state.updateKey" />
                </div>

                <div>
                    <span class="text-secondary font-bold mb-4">{{
                        $t("message.wfSelectedObjects")
                        }}</span>
                    <RobberVueGrid ref="buildingGrid" :config="buildingsSelectedConfigComputed"
                        :key="state.updateKey" />
                </div>

                <RemPrimaryButtonSmall :label="$t(`message.save`)" :method="() => {
                    saveBuildingModal()
                    openModal = false;
                }
                    " class="mr-4">
                </RemPrimaryButtonSmall>
                <RemPrimaryButtonSmall :label="$t(`message.cancel`)" :method="() => {

                    openModal = false;
                }
                    ">
                </RemPrimaryButtonSmall>
            </div>
        </div>
    </div>
</template>

<script setup>
import RobberVueGrid from "../../../RVG/RobberVueGrid.vue";
import RemPrimaryButtonSmall from "../../RemPrimaryButtonSmall.vue";
import ImFormInput from "../../../ImFormInput.vue";

import { defineModel, ref, onMounted, watch } from "vue";
import PropertySelectorConfigs from "./PropertySelectorConfigs";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

let router = useRouter();

const { t } = useI18n();

const openModal = defineModel("openModal");
const inputValue = defineModel("inputValue");
const saveEvent = defineModel("modalSaveEvent");

const state = ref({
    rvgUpdate: 0,
    updateKey: 0,
    searchParams: {
        address: {
            title: "address",
            value: "",
        },
        floc: {
            title: "floc",
            value: "",
        },
        sapId: {
            title: "sapBuildingId",
            value: "",
        },
        buildingMarking: {
            title: "buildingMarking",
            value: "",
        },
    },
    selectedIds: inputValue.value ? inputValue.value : [],
    selectorEndpoint: "/rem/building/filtered-buildings",
    selectorFilterinit: "",
    selectedEndpoint: "/rem/building/filtered-buildings",
    selectedFilterinit: "",
});

onMounted(() => {
    setUpSelectorEndPoint();
    setUpSelectedEndPoint();

    state.value.updateKey = state.value.updateKey + 1;
    state.value.loaded = true;
});

let buildingsSelectorGridConfigComputed =
    PropertySelectorConfigs.getBuildingSelectorConfig(state, t, router, state.value.selectorEndpoint);

let buildingsSelectedConfigComputed =
    PropertySelectorConfigs.getBuildingSelectedConfig(state, t, router, state.value.selectedEndpoint);

const setUpSelectorEndPoint = () => {
    state.value.selectorEndpoint = "/rem/building/filtered-buildings";

    state.value.selectorFilterinit = false;

   
    addArraytoEndpoint(state.value.selectedIds, "exclude", "selector");
    if (state.value.searchParams.floc.value != "") {
        addArraytoEndpoint(
            [state.value.searchParams.floc.value],
            "floc",
            "selector"
        );
    }
    if (state.value.searchParams.address.value != "") {
        addArraytoEndpoint([state.value.searchParams.address.value], "address", 'selector');
    }
    if (state.value.searchParams.sapId.value != "") {
        addArraytoEndpoint([state.value.searchParams.sapId.value], "sapId", 'selector');
    }
    if (state.value.searchParams.buildingMarking.value != "") {
        addArraytoEndpoint([state.value.searchParams.buildingMarking.value], "buildingMarking", 'selector');
    }

    buildingsSelectorGridConfigComputed =
        PropertySelectorConfigs.getBuildingSelectorConfig(
            state,
            t,
            router,
            state.value.selectorEndpoint
        );
};

const setUpSelectedEndPoint = () => {
    state.value.selectedEndpoint = "/rem/building/filtered-buildings";

    state.value.selectedFilterinit = false;

    if (state.value.selectedIds.length == 0) {
        addArraytoEndpoint(['0'], "include", 'selected');
    } else {
        addArraytoEndpoint(state.value.selectedIds, "include", 'selected');
    }

    buildingsSelectedConfigComputed =
        PropertySelectorConfigs.getBuildingSelectedConfig(
            state,
            t,
            router,
            state.value.selectedEndpoint
        );

};

const addArraytoEndpoint = async (array, filter, tag) => {
    if (array.length > 0) {
        state.value[`${tag}Endpoint`] += state.value[`${tag}Filterinit`] == false ? "?" : "";
        

        for (let i = 0; i < array.length; i++) {
            if (i != 0 || state.value[`${tag}Filterinit`] == true) {
                state.value[`${tag}Endpoint`] += "&";
            }
            state.value[`${tag}Endpoint`] += `${filter}=${array[i]}`;
        }
        state.value[`${tag}Filterinit`] = true;
    }
};

const saveBuildingModal = () => {
    openModal.value = false;
    inputValue.value = state.value.selectedIds
    saveEvent.value = saveEvent.value + 1
};

watch(
    () => state.value.rvgUpdate,
    () => {

        setUpSelectorEndPoint();
        setUpSelectedEndPoint();
        setTimeout(() => {
            state.value.updateKey = state.value.updateKey + 1;
        }, 500)

    }
);
</script>

<style scoped>
.modal-box {
    width: 75%;
    max-width: 75%;
}
</style>
