<template>
  <div id="PropertyPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <!--Header-->
        <RemFormLayout>
          <template #remformheader>
            <header class="w-full cardBg body-font border border-secondary border-sm rounded-lg border-secondary">
              <div class="items-start px-6 pb-6 mx-auto md:flex-row flex justify-between  pt-4">
                <p
                  class="flex mb-4 text-xl text-secondary p-3 md:mb-0 uppercase font-bold"
                >
                  {{ $t('message.property') }} -
                  {{
                    state.property
                      ? state.property.primaryAddressPostalCode +
                        ' ' +
                        state.property.primaryAddressCity +
                        ', ' +
                        state.property.primaryAddressPublicAreaName +
                        ' ' +
                        state.property.primaryAddressPublicAreaTypeValue +
                        ' ' +
                        state.property.primaryAddressHouseNumber +
                        '.'
                      : ''
                  }}
                </p>
                <RemUpButton remType="property" class=" pt-3"/>
              </div>
            </header>
          </template>
          <template #remformsidemenu>
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(1, 'property-buildings');
                    setRoute('property-buildings');
                  }
                "
                :activeCheck="router.name == 'property-buildings'"
                :icon="'building3_icon'"
                :buttonLabel="'buildings'"
              ></SideMenuButton>
            </div>
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(2, 'addresses');
                    setRoute('addresses');
                  }
                "
                :activeCheck="router.name == 'addresses'"
                :icon="'address_icon'"
                :buttonLabel="'addresses'"
              ></SideMenuButton>
            </div>
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(3, 'partners');
                    setRoute('partners');
                  }
                "
                :activeCheck="router.name == 'partners'"
                :icon="'partner_icon'"
                :buttonLabel="'partners'"
              ></SideMenuButton>
            </div>
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(4, 'documents');
                    setRoute('documents');
                  }
                "
                :activeCheck="router.name == 'documents'"
                :icon="'document_icon'"
                :buttonLabel="'documents'"
              ></SideMenuButton>
            </div>
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(5, 'baseDatas');
                    setRoute('baseDatas');
                  }
                "
                :activeCheck="router.name == 'baseDatas'"
                :icon="'data_icon'"
                :buttonLabel="'baseDatas'"
              ></SideMenuButton>
            </div>
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(6, 'legal-situations');
                    setRoute('legal-situations');
                  }
                "
                :activeCheck="router.name == 'legal-situations'"
                :icon="'legal_icon'"
                :buttonLabel="'legalSituations'"
              ></SideMenuButton>
            </div>
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(7, 'utilizations');
                    setRoute('utilizations');
                  }
                "
                :activeCheck="router.name == 'utilizations'"
                :icon="'system_settings_icon'"
                :buttonLabel="'utilizations'"
              ></SideMenuButton>
            </div>
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(9, 'property-sheet-part-3');
                    setRoute('property-sheet-part-3');
                  }
                "
                :activeCheck="router.name == 'property-sheet-part-3'"
                :icon="'obli_icon'"
                :buttonLabel="'obligations'"
              ></SideMenuButton>
            </div>
          </template>
          <template #remformmain>
            <!-----------BUILDINGS----------->
            <!-- eslint-disable -->
            <RemRVGForm
              v-if="
                router.name == 'property-buildings' &&
                store.getters.getRemTab != 13 &&
                !store.getters.getRemFormEdit
              "
              :title="$t('message.buildings')"
              rvgTab="property-buildings"
              :addNewMethod="
                () => {
                  addNewTab('building', 13);
                  state.buildingEdit = false;
                }
              "
              :rvgConfig="buildingsGridConfigComputed"
              v-model:elementId="state.buildingId"
            >
            </RemRVGForm>
            <!-- eslint-enable -->

            <!--ADD/EDIT BUILDING-->
            <RemFormCard v-if="store.getters.getRemTab == 13">
              <template #remcardheader>
                <RemFormBackbutton
                  :method="
                    () => {
                      setTab(1);
                    }
                  "
                ></RemFormBackbutton>
                <h2 class="text-xl text-secondary w-full font-bold">
                  {{ $t('message.baseDatas') }}
                </h2>
              </template>
              <template #remcardmain>
                <div>
                  <div
                    v-for="item in state.buildingBasicData"
                    v-bind:key="item.model"
                    class="mb-8 mt-4"
                  >
                    <div v-if="store.getters.getRemFormEdit == false">
                      <ImFormLabel :item="item"></ImFormLabel>
                    </div>
                    <div v-if="store.getters.getRemFormEdit == true">
                      <!-- eslint-disable -->
                      <ImFormInput
                        v-model:inputValue="item.value"
                        v-model:parent="item.parent"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :manualOptions="item.manualOptions"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod"
                        :validationMethod="item.validationMethod"
                        :optionSearchEndpoint="item.optionSearchEndpoint"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                      >
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>
                  </div>
                  <div>
                    <h2 class="text-xl text-secondary w-full font-bold">
                      {{ $t('message.additionalInfo') }}
                    </h2>
                    <hr class="mb-8" />
                    <div v-for="item in state.buildingData" v-bind:key="item.model" class="mb-8">
                      <div v-if="store.getters.getRemFormEdit == false">
                        <!-- eslint-disable -->
                        <RemVisibilityWrapper
                          v-model:toWatch="item.parent"
                          :visibilityCondition="item.visibilityCondition"
                        >
                          <ImFormLabel :item="item"></ImFormLabel>
                        </RemVisibilityWrapper>
                        <!-- eslint-enable -->
                      </div>
                      <div v-if="store.getters.getRemFormEdit == true">
                        <!-- eslint-disable -->
                        <ImFormInputVisibilityWraped
                          v-model:inputValue="item.value"
                          v-model:parent="item.parent"
                          v-model:toWatch="item.parent"
                          v-model:validated="item.validated"
                          :config="item"
                        >
                        </ImFormInputVisibilityWraped>
                        <!-- eslint-enable -->
                      </div>
                    </div>
                  </div>
                  <!-- eslint-disable -->
                  <RemFormButtonRowStored :saveMethod="saveBuilding" :deleteMethod="deleteBuilding">
                  </RemFormButtonRowStored>
                  <!-- eslint-enable -->
                </div>
              </template>
            </RemFormCard>
            <!-----------ADDRESSES-------------->
            <!--eslint-disable-->
            <RemRVGForm
              v-if="
                (router.name == 'addresses' && !store.getters.getRemFormEdit) ||
                store.getters.getRemTab == 12
              "
              :title="$t('message.address')"
              rvgTab="addresses"
              formTab="12"
              :addNewMethod="
                () => {
                  addNewTab('address', 12);
                }
              "
              v-model:formData="state.addressData"
              :rvgConfig="addressesGridConfigComputed"
              :saveMethod="saveSecondaryAddress"
              :deleteMethod="deleteAddress"
              :backMethod="
                () => {
                  setTab(2);
                }
              "
              v-model:elementId="state.addressId"
            >
            </RemRVGForm>
            <!--eslint-enable-->

            <!----------UTILIZATIONS------------>
            <!--eslint-disable-->
            <RemRVGForm
              v-if="
                (router.name == 'utilizations' && !store.getters.getRemFormEdit) ||
                store.getters.getRemTab == 11
              "
              :title="$t('message.utilizations')"
              rvgTab="utilizations"
              formTab="11"
              :addNewMethod="
                () => {
                  addNewTab('utilizations', 11);
                }
              "
              v-model:formData="state.utilizationData"
              :rvgConfig="utilizationsGridConfigComputed"
              :saveMethod="saveUtilization"
              :deleteMethod="deleteUtilization"
              :backMethod="
                () => {
                  setTab(7);
                }
              "
              v-model:elementId="state.utilizationId"
            >
            </RemRVGForm>
            <!--eslint-enable-->

            <!-----------LEGAL SITUATIONS-------------->
            <!--eslint-disable-->
            <RemRVGForm
              v-if="
                (router.name == 'legal-situations' && !store.getters.getRemFormEdit) ||
                store.getters.getRemTab == 8
              "
              :title="$t('message.legalSituations')"
              rvgTab="legal-situations"
              formTab="8"
              :addNewMethod="
                () => {
                  addNewTab('legalsit', 8);
                }
              "
              v-model:formData="state.legalsitData"
              :rvgConfig="legalSituationsGridConfigComputed"
              :saveMethod="saveLegalsit"
              :deleteMethod="deleteLegalSituation"
              :backMethod="
                () => {
                  setTab(6);
                }
              "
              v-model:elementId="state.legalsitId"
            >
            </RemRVGForm>
            <!--eslint-enable-->

            <!------------BASEDATA------------->
            <RemFormCard v-if="router.name == 'baseDatas'">
              <template #remcardheader>
                <RemFormBackbutton
                  v-if="store.getters.getRemFormEdit == true"
                  :method="
                    () => {
                      setTab(5);
                    }
                  "
                >
                </RemFormBackbutton>
                <h2 class="text-xl text-secondary w-full font-bold">
                  {{ $t('message.baseDatas') }}
                </h2>
              </template>
              <template #remcardmain>
                <div v-for="item in state.propertyBasicData" v-bind:key="item.model" class="mb-8">
                  <div v-if="store.getters.getRemFormEdit == false">
                    <ImFormLabel :item="item"></ImFormLabel>
                  </div>
                  <div v-if="store.getters.getRemFormEdit == true">
                    <!-- eslint-disable -->
                    <ImFormInput
                      v-model:inputValue="item.value"
                      v-model:parent="item.parent"
                      :title="$t('message.' + item.title)"
                      :type="item.type"
                      :valueLabel="item.valueLabel"
                      :optionValue="item.optionValue"
                      :endPoint="item.endPoint"
                      :manualOptions="item.manualOptions"
                      :displayValue="item.endPoint"
                      :searchable="item.searchable"
                      :onchangeMethod="item.onchangeMethod"
                      :validationMethod="item.validationMethod"
                      :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                      :optionSearchEndpoint="item.optionSearchEndpoint"
                    >
                    </ImFormInput>
                    <!-- eslint-enable -->
                  </div>
                </div>

                <!-- eslint-disable -->
                <RemFormButtonRowStored :saveMethod="saveProperty" deleteMethod="">
                </RemFormButtonRowStored>
                <!-- eslint-enable -->
              </template>
            </RemFormCard>

            <!-----------DOCUMENTS-------------->
            <div
              v-if="router.name == 'documents' && !store.getters.getRemFormEdit"
              class="flex flex-col im-main-card rounded-xl im-main-card-starter"
            >
              <PropertyLists title="documents" :property="state.property" />
            </div>

            <!------------PARTNERS-------------->
            <!-- eslint-disable -->
            <RemRVGForm
              v-if="
                (router.name == 'partners' &&
                  !store.getters.getRemFormEdit &&
                  store.getters.getRemTab != 15) ||
                store.getters.getRemTab == 14
              "
              :title="
                state.partnerRvgType
                  ? $t('message.allRelatedPartner')
                  : $t('message.propertyPartners')
              "
              :addNewTitle="'assignNew'"
              rvgTab="partners"
              formTab="14"
              :addNewMethod="
                () => {
                  setTab(15);
                }
              "
              v-model:formData="state.partnerData"
              :rvgConfig="
                state.partnerRvgType
                  ? allRelatedPartnersGridConfigComputed
                  : partnersGridConfigComputed
              "
              :saveMethod="savePartner"
              :deleteMethod="deletePartner"
              :backMethod="
                () => {
                  setTab(3);
                }
              "
              :partnerTypeSwitcherMethod="
                () => {
                  changePartnerRvgType();
                }
              "
              v-model:elementId="state.partnerId"
            >
            </RemRVGForm>
            <!--eslint-enable-->

            <!-----------OBLIGATIONS------------>
            <!--eslint-disable-->
            <RemRVGForm
              v-if="
                (router.name == 'property-sheet-part-3' && !store.getters.getRemFormEdit) ||
                store.getters.getRemTab == 10
              "
              :title="$t('message.obligations')"
              rvgTab="property-sheet-part-3"
              formTab="10"
              :addNewMethod="
                () => {
                  addNewTab('obligation', 10);
                }
              "
              v-model:formData="state.obligationData"
              :rvgConfig="obligationsGridConfigComputed"
              :saveMethod="saveObligation"
              :deleteMethod="deleteObligation"
              :backMethod="
                () => {
                  setTab(9);
                }
              "
              v-model:elementId="state.obligationId"
            >
            </RemRVGForm>

            <!---------PARTNER-SEARCH----------->
            <RemPartnerAssignSearch
              v-if="store.getters.getRemTab == 15"
              :type="'property'"
              :backFunc="() => setTab(3)"
              :id="propertyId"
              :createFunc="
                () => {
                  addNewTab('partner', 14);
                }
              "
              :partnerMainTypes="partnerMainTypes"
              :partnerSubTypes="partnerSubTypes"
            >
            </RemPartnerAssignSearch>

            <RemFormConfirmationModal
              v-model:showConfirmation="state.confirmation.showConfirmation"
              v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
              v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
              v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod"
            ></RemFormConfirmationModal>
            <!-- eslint-enable -->
          </template>
        </RemFormLayout>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
//components
import BaseLayout from '../../layouts/BaseLayout.vue';
import PropertyLists from '../../components/REM/RemLists.vue';
import ImFormInput from '../../components/ImFormInput.vue';
import ImFormLabel from '../../components/ImFormLabel.vue';
import RemUpButton from '../../components/REM/RemUpButton.vue';
import RemFormLayout from '../../components/REM/RemFormLayout.vue';
import RemRVGForm from '../../components/REM/RemRVGForm.vue';
import SideMenuButton from '../../components/REM/SideMenuButton.vue';
import RemFormCard from '../../components/REM/RemFormCard.vue';
import RemFormButtonRowStored from '../../components/REM/RemFormButtonRowStored.vue';
import RemFormConfirmationModal from '../../components/REM/RemFormConfirmationModal.vue';
import RemFormBackbutton from '../../components/REM/RemFormBackbutton.vue';
import ImFormInputVisibilityWraped from '../../components/REM/ImFormInputVisibilityWraped.vue';
import RemVisibilityWrapper from '../../components/REM/RemVisibilityWrapper.vue';
import RemPartnerAssignSearch from '../../components/REM/RemPartnerAssignSearch.vue';

//Utils
import store from '@/misc/vuex-store.js';
import { computed, onMounted, ref, watch } from 'vue';
import { computedAsync } from '@vueuse/core';
import RVGUtils from '../../components/RVG/RobberVueGridUtils.js';
import Utils from '../../misc/Utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import propertyUtils from './propUtils';
import remFormUtils from '../../misc/remFormUtils.js';
import TenantConfigHandler from '../../misc/TenantConfigHandler.js';
import route from '@/misc/router';
import toast from '@/misc/toast';

//RVG and FOrmdata
import formdata from './FormData';
import buildingData from '../../misc/remFormdata/building/buildingData.js';
import obligationsGridConfig from '../../misc/gridConfigs/propertyPage/propObligationsGridConfig';
import utilizationsGridConfig from '../../misc/gridConfigs/propertyPage/utilizationsGridConfig';
import addressesGridConfig from '../../misc/gridConfigs/propertyPage/addressesGridConfig';
import buildingsGridConfig from '../../misc/gridConfigs/propertyPage/buildingsGridConfig';
import partnersGridConfig from '../../misc/gridConfigs/propertyPage/partnersGridConfig';
import allRelatedPartnersGridConfig from '../../misc/gridConfigs/propertyPage/allRelatedPartnersGridConfig.js';
import legalSituationsGridConfig from '../../misc/gridConfigs/propertyPage/legalSituationsGridConfig';

const partnersGrid = ref();
const buildingsGrid = ref();
const addressesGrid = ref();
const utilizationsGrid = ref();
const obligationsGrid = ref();
const legalSituationsGrid = ref();

const partnerMainTypes = ref([]);
const partnerSubTypes = ref([]);
const partnerMainTypeMapping = computed(() => {
  return RVGUtils.getFormatterMappingForOptionValues(partnerMainTypes.value);
});
const partnerSubTypeMapping = computed(() => {
  return RVGUtils.getFormatterMappingForOptionValues(partnerSubTypes.value);
});
const addressDataVarietyMapping = ref({});

const state = ref({
  tabsToConfirm: {
    13: {
      saveMethod: async () => {
        await saveBuilding();
      },
    },
    8: {
      saveMethod: async () => {
        await saveLegalsit();
      },
    },
    11: {
      saveMethod: async () => {
        await saveUtilization();
      },
    },
    12: {
      saveMethod: async () => {
        await saveSecondaryAddress();
      },
    },
    14: {
      saveMethod: async () => {
        await savePartner();
      },
    },
    10: {
      saveMethod: async () => {
        await saveObligation();
      },
    },
    5: {
      saveMethod: async () => {
        await saveProperty();
      },
    },
  },
  property: null,
  legalsitData: formdata.legalsitData,
  legalsitId: 0,
  obligationData: formdata.obligationData,
  obligationId: 0,
  utilizationData: formdata.utilizationData,
  utilizationId: 0,
  addressData: formdata.addressData,
  addressId: 0,
  buildingData: buildingData,
  buildingBasicData: formdata.buildingBasicData,
  buildingId: 0,
  buildingEdit: false,
  partnerData: formdata.partnerData,
  partnerId: 0,
  isPerson: false,
  partnerRvgType: false,
  siteDesignName: '',
  propertyBasicData: formdata.propertyBasicData,
  addressType: '',
  confirmation: {
    showConfirmation: false,
    confirmationTextToken: '',
    confirmationTrueMethod: () => {},
    confirmationFalseMethod: () => {},
  },
});

const { t } = useI18n();
const router = useRoute();
const propertyId = router.params.propertyId;
propertyUtils.asyncGet(state, propertyId);

//BASIC DATA
const saveProperty = async () => {
  return await propertyUtils.saveProperty(state, t, propertyId);
};

//PARTNERS

const deletePartner = (recordId, cbToRefreshGrid) => {
  remFormUtils.deattachConfirmation(
    state,
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/property/' +
      propertyId +
      '/partner/' +
      recordId,
    'partner',
    3,
    t,
    cbToRefreshGrid
  );
};

const partnersGridConfigComputed = computed(() => {
  return partnersGridConfig(
    state,
    t,
    propertyId,
    deletePartner,
    partnerMainTypeMapping.value,
    partnerSubTypeMapping.value
  );
});

const allRelatedPartnersGridConfigComputed = computed(() => {
  return allRelatedPartnersGridConfig(
    state,
    t,
    propertyId,
    partnerMainTypeMapping.value,
    partnerSubTypeMapping.value
  );
});

const savePartner = async () => {
  const result = await propertyUtils.savePartner(state, t, propertyId);
  partnersGrid?.value?.refresh();
  return result;
};

//BUILDINGS
const buildingsGridConfigComputed = computedAsync(async () => {
  const [
    basicDataMainFunctionMapping,
    basicDataStatusMapping,
    basicDataSapMapping,
    basicDataFoundationMapping,
    basicDataBearingMasonryMapping,
    basicDataSlabStructureInterMapping,
    basicDataSlabSubStructureInterMapping,
    basicDataSlabStructureClosingMapping,
    basicDataSlabSubStructureClosingMapping,
    basicDataFlatRoofMapping,
    basicDataRoofingMapping,
    basicDataRoofTypeMapping,
    basicDataInsulationMapping,
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('BUILDING_MAIN_FUNCTION'),
    RVGUtils.getFormatterMappingForOptionType('BUILDING_STATUS'),
    RVGUtils.getFormatterMappingForOptionType('SAP_BUILDING_IDENTIFIER'),
    RVGUtils.getFormatterMappingForOptionType('FOUNDATION'),
    RVGUtils.getFormatterMappingForOptionType('BEARING_MASONRY'),
    RVGUtils.getFormatterMappingForOptionType('SLAB_STRUCTURE_INTERMEDIATE'),
    RVGUtils.getFormatterMappingForOptionType('SLAB_SUB_STRUCTURE_INTERMEDIATE'),
    RVGUtils.getFormatterMappingForOptionType('SLAB_STRUCTURE_CLOSING'),
    RVGUtils.getFormatterMappingForOptionType('SLAB_SUB_STRUCTURE_CLOSING'),
    RVGUtils.getFormatterMappingForOptionType('FLAT_ROOF'),
    RVGUtils.getFormatterMappingForOptionType('ROOFING'),
    RVGUtils.getFormatterMappingForOptionType('ROOF_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('INSULATION'),
  ]);

  return buildingsGridConfig(
    state,
    t,
    propertyId,
    propertyUtils.toBuilding,
    basicDataMainFunctionMapping,
    basicDataStatusMapping,
    basicDataSapMapping,
    basicDataFoundationMapping,
    basicDataBearingMasonryMapping,
    basicDataSlabStructureInterMapping,
    basicDataSlabSubStructureInterMapping,
    basicDataSlabStructureClosingMapping,
    basicDataSlabSubStructureClosingMapping,
    basicDataFlatRoofMapping,
    basicDataRoofingMapping,
    basicDataRoofTypeMapping,
    basicDataInsulationMapping
  );
}, buildingsGridConfig(state, t, propertyId, propertyUtils.toBuilding));

const saveBuilding = async () => {
  const result = await propertyUtils.saveBuilding(state, t, propertyId);
  buildingsGrid?.value?.refresh();
  return result;
};

const deleteBuilding = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/building/' +
      state.value.buildingId,
    'building',
    1
  );
  buildingsGrid?.value?.refresh();
};

//ADDRESSES
const addressesGridConfigComputed = computedAsync(async () => {
  const [countryMapping, publicAreaTypeMapping, addressVarietyMapping] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('COUNTRY'),
    RVGUtils.getFormatterMappingForOptionType('PUBLIC_AREA_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('PROPERTY_ADDRESS_VARIETY'),
  ]);
  return addressesGridConfig(
    state,
    t,
    propertyId,
    countryMapping,
    publicAreaTypeMapping,
    addressVarietyMapping
  );
}, addressesGridConfig(state, t, propertyId));

const saveSecondaryAddress = async () => {
  const result = await propertyUtils.saveSecondaryAddress(state, propertyId, t);
  addressesGrid?.value?.refresh();
  getProperty();
  return result;
};

const deleteAddress = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/property/address/' +
      state.value.addressId,
    'address',
    2
  );
  addressesGrid?.value?.refresh();
};

//UTILIZATIONS
const utilizationsGridConfigComputed = computedAsync(async () => {
  const [legalRelationshipMapping] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('UTILIZATION_LEGAL_RELATIONSHIP'),
  ]);
  return utilizationsGridConfig(state, t, propertyId, legalRelationshipMapping);
}, utilizationsGridConfig(state, t, propertyId));

const saveUtilization = async () => {
  if (
    state.value.utilizationData[3].value != '' &&
    state.value.utilizationData[2].value > state.value.utilizationData[3].value
  ) {
    toast.error(t('message.startDateLaterThanEndDate'));
    return;
  }
  const result = await propertyUtils.saveUtilization(state, propertyId, t);
  utilizationsGrid?.value?.refresh();
  return result;
};

const deleteUtilization = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/utilization/' +
      state.value.utilizationId,
    'utilization',
    7
  );
  utilizationsGrid?.value?.refresh();
};

//OBLIGATIONS
const obligationsGridConfigComputed = computedAsync(async () => {
  const [otherRightMapping] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('OTHER_RIGHTS'),
  ]);
  return obligationsGridConfig(state, t, propertyId, otherRightMapping);
}, obligationsGridConfig(state, t, propertyId));

const saveObligation = async () => {
  const result = await propertyUtils.saveObligation(state, t, propertyId);
  obligationsGrid?.value?.refresh();
  return result;
};

const deleteObligation = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/property/obligation/' +
      state.value.obligationId,
    'obligation',
    9
  );
  obligationsGrid?.value?.refresh();
};

//LEGAL SITUATIONS
const legalSituationsGridConfigComputed = computedAsync(async () => {
  const [legalRelationshipTypeMapping, legalSituationTypeMapping] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('LEGAL_RELATIONSHIP_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('LEGAL_SITUATION'),
  ]);
  return legalSituationsGridConfig(
    state,
    t,
    propertyId,
    legalRelationshipTypeMapping,
    legalSituationTypeMapping
  );
}, legalSituationsGridConfig(state, t, propertyId));

const saveLegalsit = async () => {
  const result = await propertyUtils.saveLegalsit(state, t, propertyId);
  legalSituationsGrid.value.refresh();
  return result;
};

const deleteLegalSituation = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/legal-situation/' +
      state.value.legalsitId,
    'legalSit',
    6
  );
  legalSituationsGrid?.value?.refresh();
};

const getProperty = async () => {
  const response = await Utils.fetchPropertyById(propertyId);
  state.value.property = response;
};

const getOptionTypes = async () => {
  let propertyAddressVarietyOptions = [];
  [partnerMainTypes.value, partnerSubTypes.value, propertyAddressVarietyOptions] =
    await Promise.all([
      Utils.getAllOptions('PARTNER_MAIN_TYPE'),
      Utils.getAllOptions('PARTNER_SUB_TYPE'),
      Utils.getOptions('PROPERTY_ADDRESS_VARIETY'),
    ]);

  addressDataVarietyMapping.value = [];
  propertyAddressVarietyOptions.forEach((variety) => {
    addressDataVarietyMapping.value[variety.id] = variety.code;
  });
};

onMounted(() => {
  getProperty();
  getOptionTypes();
  //propertyUtils.setup(state)
});

function deleteConfirmation(endpoint, formData, tab, cb, tokenPrefix = 'delete') {
  remFormUtils.deleteConfirmation(state, endpoint, formData, tab, t, cb, tokenPrefix);
}

function addNewTab(name, tab) {
  remFormUtils.addNewTab(name, tab, state);
}

function setTab(tab, name) {
  remFormUtils.setTab(state, tab, name);
}

function setRoute(name) {
  if (!store.getters.getRemFormEdit) {
    route.push({ name: name });
  }
}

const changePartnerRvgType = () => {
  state.value.partnerRvgType = !state.value.partnerRvgType;
};

// WATCHERS
watch(
  () => state.value.partnerData[1].value,
  () => {
    setTimeout(() => {
      state.value.partnerData[2].parent = state.value.partnerData[1].value;
    }, 700);
  }
);

watch(
  () => state.value.buildingData[6].value,
  () => {
    setTimeout(() => {
      state.value.buildingData[7].parent = state.value.buildingData[6].value;
    }, 700);
  }
);

watch(
  () => state.value.buildingData[8].value,
  () => {
    setTimeout(() => {
      state.value.buildingData[9].parent = state.value.buildingData[8].value;
    }, 700);
  }
);

watch(
  () => state.value.buildingData[10].value,
  () => {
    setTimeout(() => {
      state.value.buildingData[11].parent = state.value.buildingData[10].value;
    }, 700);
  }
);

watch(
  () => state.value.addressData[2].value,
  () => {
    setTimeout(() => {
      state.value.addressData[3].parent = state.value.addressData[2].value;
      state.value.addressData[4].parent = state.value.addressData[2].value;
      state.value.addressData[5].parent = state.value.addressData[2].value;
    }, 700);
  }
);

watch(
  () => state.value.addressData[1].value,
  () => {
    setTimeout(() => {
      state.value.addressData[7].parent = state.value.addressData[1].value;
    }, 700);
  }
);

watch(
  () => state.value.addressData[0].value,
  () => {
    const addressTypeValue = state.value.addressData[0].value;
    const addressVarietyCode = addressDataVarietyMapping.value[addressTypeValue];

    if (addressVarietyCode === 'PROPERTY_ADDRESS_LOCATION') {
      state.value.addressData[6].mandatory = false;
      state.value.addressData[7].mandatory = true;
      state.value.addressData[8].mandatory = true;
      state.value.addressData[9].mandatory = true;
      state.value.addressData[13].mandatory = false;
    } else if (addressVarietyCode === 'PROPERTY_ADDRESS_MAILBOX') {
      state.value.addressData[6].mandatory = true;
      state.value.addressData[7].mandatory = false;
      state.value.addressData[8].mandatory = false;
      state.value.addressData[9].mandatory = false;
      state.value.addressData[13].mandatory = false;
    } else if (addressVarietyCode === 'PROPERTY_ADDRESS_PARCEL_NUMBER') {
      state.value.addressData[6].mandatory = false;
      state.value.addressData[7].mandatory = false;
      state.value.addressData[8].mandatory = false;
      state.value.addressData[9].mandatory = false;
      state.value.addressData[13].mandatory = true;
    }
  }
);

watch(
  () => state.value.addressData[1].value,
  () => {
    if (state.value.addressData[1].value == 552) {
      state.value.addressData[3].mandatory = true;
      state.value.addressData[4].mandatory = true;
    } else {
      state.value.addressData[3].mandatory = false;
      state.value.addressData[4].mandatory = false;
    }
  }
);

watch(
  () => state.value.addressData[4].value,
  () => {
    if (state.value.addressData[4].value == 'Budapest') {
      state.value.addressData[5].mandatory = true;
    } else {
      state.value.addressData[5].mandatory = false;
    }
  }
);

watch(
  () => state.value.partnerData[3].value,
  () => {
    setTimeout(() => {
      state.value.partnerData[4].parent = state.value.partnerData[3].value;
      state.value.partnerData[5].parent = state.value.partnerData[3].value;
    }, 700);
  }
);

watch(
  () => state.value.buildingId,
  () => {
    state.value.buildingData[0].buildingId = state.value.buildingId;
  }
);

watch(
  () => state.value.buildingEdit,
  () => {
    if (state.value.buildingEdit) {
      state.value.buildingData[0].optionSearchEndpoint = 'editSiteDesign';
      state.value.buildingData[0].valueLabel = state.value.siteDesignName;
    }
  }
);
</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  overflow-x: auto;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}
</style>
