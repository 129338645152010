export default {
  data:[
    {
      title: "function",
      value: "",
      valueLabel: "",
      model: "coolingHeatingFunction",
      type: "option",
      optionValue: "COOLING_HEATING_FUNCTION",
    },
    {
      title: "type",
      value: "",
      valueLabel: "",
      model: "coolingHeatingType",
      type: "option",
      optionValue: "COOLING_HEATING_TYPE",
      parent: "",
      onchangeMethod: (
        options,
        optionsRepo,
        value,
        inputValue
      ) => {
        
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (!options.value.filter((elem) => elem.value == inputValue.value)) {
          inputValue.value = "";
        } 
      },
    },
    {
      title: "isCentral",
      value: "",
      model: "central",
      type: "checkbox",
    },
    {
      title: "canBeControlled",
      value: "",
      model: "canBeControlled",
      type: "checkbox",
    },
    {
      title: "outdoorUnit",
      value: "",
      model: "outdoorUnit",
      type: "checkbox",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "energyIntake",
      value: "",
      model: "energyIntake",
      type: "positiveNumber",
    },
    {
      title: "coolingPerformance",
      value: "",
      model: "coolingPerformance",
      type: "positiveNumber",
    },
    {
      title: "heatingPerformance",
      value: "",
      model: "heatingPerformance",
      type: "positiveNumber",
    },
    {
      title: "installationDate",
      value: "",
      model: "installationDate",
      type: "date",
    },
    {
      title: "maintenanceCycle",
      value: "",
      model: "maintenanceCycle",
      type: "text",
    },
    {
      title: "yearOfManufacture",
      value: "",
      model: "yearOfManufacture",
      type: "integer",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    }
  ]
}