import axios from '@/misc/axios.js';
import store from '../misc/vuex-store.js';
//import TenantConfigHandler from "@/misc/TenantConfigHandler";
import toast from '@/misc/toast.js';
import route from '@/misc/router';

export default {
  /**
   *
   * @param {*} postendpoint - endpoint for post request
   * @param {*} patchendpoint - endpoint for patch request
   * @param {*} hermes - payload
   * @param {*} schema - formdata name
   *    schemaData : formdata field list
   *    schemaId : record id
   *    schemaEdit : readonly view Toggle
   * @param {*} tab - form tab to route back after succesful save
   * @param {*} state - state object of the form
   * @param {*} t - localization
   */

  patchPost: async (postendpoint, patchendpoint, hermes, schema, tab, state, t) => {
    if (state.value[`${schema}Id`] != 0) {
      try {
        let resp = await axios.patch(patchendpoint, hermes, {
          onTokenRefreshed: () => {
            this.patchPost(postendpoint, patchendpoint, hermes, schema, tab, state, t);
          },
        });

        if (resp && resp.status == 200) {
          toast.success(t('message.successfulSave'));
          state.value[`${schema}Data`].forEach((e) => {
            e.value = '';
          });

          state.value.tab = tab;
          store.commit('setRemTab', tab);
          state.value[`${schema}Id`] = 0;
          state.value[`${schema}Edit`] = false;
          store.commit('setRemFormEdit', false);
        } else {
          toast.error(t('message.notSuccessfulSave'));
        }
      } catch (e) {
        toast.error(t('message.notSuccessfulSave'));
      }
    } else {
      try {
        let resp = await axios.post(postendpoint, hermes, {
          onTokenRefreshed: () => {
            this.patchPost(postendpoint, patchendpoint, hermes, schema, tab, state, t);
          },
        });

        if (resp && resp.status == 201) {
          toast.success(t('message.successfulSave'));
          state.value[`${schema}Data`].forEach((e) => {
            e.value = '';
          });

          state.value.tab = tab;
          store.commit('setRemTab', tab);
          state.value[`${schema}Id`] = 0;
          state.value[`${schema}Edit`] = false;
          store.commit('setRemFormEdit', false);
        } else {
          toast.error(t('message.notSuccessfulSave'));
        }
      } catch (e) {
        toast.error(t('message.notSuccessfulSave'));
      }
    }
  },

  /**
   *
   * @param {*} postendpoint - endpoint for post request
   * @param {*} putendpoint - endpoint for patch request
   * @param {*} hermes - payload
   * @param {*} schema - formdata name
   *    schemaData : formdata field list
   *    schemaId : record id
   *    schemaEdit : readonly view Toggle
   * @param {*} tab - form tab to route back after succesful save
   * @param {*} state - state object of the form
   * @param {*} t - localization
   */

  putPost: async (postendpoint, putendpoint, hermes, schema, tab, state, t, errorMessage) => {
    if (state.value[`${schema}Id`] != 0) {
      try {
        let resp = await axios.put(putendpoint, hermes, {
          onTokenRefreshed: () => {
            this.putPost(postendpoint, putendpoint, hermes, schema, tab, state, t);
          },
        });

        if (resp && resp.status == 200) {
          toast.success(t('message.successfulSave'));
          state.value[`${schema}Data`].forEach((e) => {
            e.value = '';
            if(e.valueLabel){
              e.valueLabel = ''
            }
            if(e.parent){
              e.parent = ''
            }
          });

          state.value.tab = tab;
          store.commit('setRemTab', tab);
          state.value[`${schema}Id`] = 0;
          state.value[`${schema}Edit`] = false;
          store.commit('setRemFormEdit', false);
          return true;
        } else {
          toast.error(t('message.notSuccessfulSave'));
          return false;
        }
      } catch (e) {
        if (e.response.data.message == 'Legal situation start date must be before end date') {
          toast.error(t('message.startDateLaterThanEndDate'));
        } else if (errorMessage) {
          toast.error(t(errorMessage));
        } else {
          toast.error(t('message.notSuccessfulSave'));
        }
        return false;
      }
    } else {
      try {
        let resp = await axios.post(postendpoint, hermes, {
          onTokenRefreshed: () => {
            this.putPost(postendpoint, putendpoint, hermes, schema, tab, state, t);
          },
        });

        if (resp && resp.status == 201) {
          toast.success(t('message.successfulSave'));
          state.value[`${schema}Data`].forEach((e) => {
            e.value = '';
            if(e.valueLabel){
              e.valueLabel = ''
            }
            if(e.parent){
              e.parent = ''
            }
          });

          state.value.tab = tab;
          store.commit('setRemTab', tab);
          state.value[`${schema}Id`] = 0;
          state.value[`${schema}Edit`] = false;
          store.commit('setRemFormEdit', false);
          return true;
        } else {
          toast.error(t('message.notSuccessfulSave'));
          return false;
        }
      } catch (e) {
        if (e.response.data.message == 'Legal situation start date must be before end date') {
          toast.error(t('message.startDateLaterThanEndDate'));
        } else if (errorMessage) {
          toast.error(t(errorMessage));
        } else {
          toast.error(t('message.notSuccessfulSave'));
        }
        return false;
      }
    }
  },
  async onlyPost(postendpoint, hermes, schema, tab, state, t) {
    try {
      let resp = await axios.post(postendpoint, hermes, {
        onTokenRefreshed: () => {
          this.onlyPost(postendpoint, hermes, schema, tab, state, t);
        },
      });

      if (resp && (resp.status == 200 || resp.status == 201)) {
        toast.success(t('message.successfulSave'));
        this.remGetOptions(resp.data, state.value[`${schema}Data`], true);

        state.value.tab = tab;
        store.commit('setRemTab', tab);
        store.commit('setRemFormEdit', false);
        state.value[`${schema}Edit`] = false;
      } else {
        toast.error(t('message.notSuccessfulSave'));
      }
    } catch (e) {
      toast.error(t('message.notSuccessfulSave'));
    }
  },
  async remDelete(state, endpoint, formdata, tab, t) {
    try {
      let resp = await axios.delete(endpoint, {
        onTokenRefreshed: () => {
          this.remDelete(state, endpoint, formdata, tab, t);
        },
      });

      if (resp && resp.status == 204) {
        toast.success(t('message.deleteSuccessful'));
        state.value.tab = tab;
        store.commit('setRemTab', tab);
        state.value[`${formdata}Id`] = 0;
        store.commit('setRemFormEdit', false);
      } else {
        toast.error(t('message.deleteNotSuccessful'));
      }
    } catch (e) {
      toast.error(t('message.deleteNotSuccessful'));
    }
  },
  async getSingleton(state, endpoint, formdata) {
    let resp = await axios.get(endpoint, {
      onTokenRefreshed: () => {
        this.getSingleton(state, endpoint, formdata);
      },
    });
    state.value[`${formdata}Data`].forEach((e) => {
      if (e.type == 'label') {
        return;
      }
      e.value = '';
      if (e.valueLabel) e.valueLabel = '';
    });
    if (resp && resp.data) {
      this.remGetOptions(resp.data, state.value[`${formdata}Data`], true);
    }
  },
  remGetOptions: (source, target, isThereWeirdFormattingForOption) => {
    target.forEach((e) => {
      if (e.type == 'label') {
        return;
      }

      if (e.type == 'option' && isThereWeirdFormattingForOption == true) {
        if (source[e.model + 'Id']) {
          e.value = source[e.model + 'Id'];
          e.valueLabel = source[e.model + 'Value'];
        }
      } else if (e.type == 'checkbox' && source[e.model] == null) {
        e.value = '';
      } else {
        e.value = source[e.model];
      }
      e.originalValue = 'ehh';
      e.originalValue = e.value;
    });
  },

  clearData(vArray) {
    vArray?.forEach((element) => {
      element.value = '';
      element.originalValue = '';
      element.valueLabel = '';
      if(element.parent){
        element.parent = ''
      }
    });
  },

  setBackData(vArray) {
    vArray.forEach((element) => {
      if (element.type != 'label' && !element.readOnly) {
        element.value = element.originalValue;
      }
    });
  },

  setTab(state, tab, name) {
    if (
      state.value.tabsToConfirm[store.getters.getRemTab] &&
      store.getters.getRemFormEdit == true
    ) {
      state.value.confirmation.confirmationTextToken = 'saveConfirmation';

      state.value.confirmation.confirmationTrueMethod = async () => {
        state.value.confirmation.showConfirmation = false;

        const result = await state.value.tabsToConfirm[store.getters.getRemTab].saveMethod();

        if (result == true) {
          store.commit('setRemFormEdit', false);
          setTimeout(() => {
            route.push({ name: name });
            store.commit('setRemTab', tab);
            store.commit('setRemFormEdit', false);
          }, 500);
        }
      };

      state.value.confirmation.confirmationFalseMethod = () => {
        state.value.confirmation.showConfirmation = false;
        if (state.value.tabsToConfirm[store.getters.getRemTab].cancelMethod) {
          state.value.tabsToConfirm[store.getters.getRemTab].cancelMethod();
        }
        setTimeout(() => {
          route.push({ name: name });
          store.commit('setRemTab', tab);
          store.commit('setRemFormEdit', false);
        }, 500);
      };

      state.value.confirmation.showConfirmation = true;
    } else {
      route.push({ name: name });
      store.commit('setRemTab', tab);
      store.commit('setRemFormEdit', false);
    }
  },
  addNewTab(name, tab, state) {
    //state.value[`${name}Edit`] = true;
    this.clearData(state.value[`${name}Data`]);
    this.clearData(state.value[`${name}BasicData`]);
    this.clearData(state.value[`${name}SpecialData`]);
    this.setTab(state, tab);
    state.value[`${name}Id`] = 0;
    store.commit('setRemFormEdit', true);
  },

  deleteConfirmation: (state, endpoint, formData, tab, t) => {
    state.value.confirmation.confirmationTextToken = 'deleteConfirm';
    state.value.confirmation.confirmationTrueMethod = async () => {
      try {
        let resp = await axios.delete(endpoint, {
          onTokenRefreshed: () => {
            this.deleteConfirmation(state, endpoint, formData, tab, t);
          },
        });

        if (resp && resp.status == 204) {
          toast.success(t('message.deleteSuccessful'));
          state.value.tab = tab;
          store.commit('setRemTab', tab);
          state.value[`${formData}Id`] = 0;
          store.commit('setRemFormEdit', false);
        } else {
          toast.error(t('message.deleteNotSuccessful'));
        }
      } catch (e) {
        toast.error(t('message.deleteNotSuccessful'));
      }
      state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.confirmationFalseMethod = () => {
      state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;
  },
  deattachConfirmation: (state, endpoint, formData, tab, t, cbToRefreshGrid) => {
    state.value.confirmation.confirmationTextToken = 'deattachConfirm';
    state.value.confirmation.confirmationTrueMethod = async () => {
      try {
        let resp = await axios.delete(endpoint, {
          onTokenRefreshed: () => {
            this.deattachConfirmation(endpoint, cbToRefreshGrid);
          },
        });

        if ((resp && resp.status == 200) || resp.status == 204) {
          toast.success(t('message.deattachSuccessful'));
          state.value.tab = tab;
          store.commit('setRemTab', tab);
          state.value[`${formData}Id`] = 0;
          store.commit('setRemFormEdit', false);
          cbToRefreshGrid();
        } else {
          toast.error(t('message.deattachNotSuccessful'));
        }
      } catch (e) {
        if (e.response && e.response.status == 403) {
          let tokens = e.response.data.message.split(',').map((x) => t(`message.${x}`));
          toast.error(
            t('message.deattachCouldNotBeFinishedDueTo').replace('%s', tokens.join(', '))
          );
        } else if (e.response && e.response.status == 404) {
          toast.error(t('message.deattachNotFound'));
          cbToRefreshGrid();
        } else {
          toast.error(t('message.deattachNotSuccessful'));
        }
      }
      state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.confirmationFalseMethod = () => {
      state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;
  },
};
