export default [
  {
    title: "numberOfPerson",
    value: "",
    model: "basicDataElevatorNumberPersons",
    type: "text",
  },
  {
    title: "elevatorType",
    value: "",
    model: "basicDataElevatorType",
    type: "text",
  },
  {
    title: "loadCapacity",
    value: "",
    model: "basicDataElevatorLoadCapacity",
    type: "positiveNumber",
  },
  {
    title: "isEmergencyElevator",
    value: "",
    model: "basicDataEmergencyElevator",
    type: "checkbox",
  },
];
