import TenantConfigHandler from "../../TenantConfigHandler";

export default {
  data: [
    /// Header

    {
      value: "",
      title: "taskType",
      valueLabel: "",
      model: "type",
      type: "optionWeirdApi",
      mandatory: true,
      validated: false,
      endPoint:
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/workflow/task/type",
      viewList: ["read", "new", "newfromlist","edit"],
      stateList: [],
      readonlyViewList: ["edit"],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "identifier",
      model: "identifier",
      type: "text",
      defaultReadonly: true,
      viewList: ["read", "edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "subject",
      model: "subject",
      type: "text",
      mandatory: true,
      viewList: ["read", "new", "newfromlist","edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    // Main
    {
      value: "",
      title: "description",
      model: "note",
      type: "textarea",
      viewList: ["read", "new", "newfromlist","edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },

    //Details
    {
      value: "",
      title: "state",
      valueLabel: "",
      model: "state",
      type: "transition",
      viewList: ["read", "edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "responsible",
      model: "responsible",
      optionSearchEndpoint: "uploader",
      type: "optionSearchEndpoint",
      viewList: ["read", "new", "newfromlist","edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "MEDIUM",
      title: "priority",
      model: "priority",
      type: "optionManual",
      mandatory: true,
      validated: false,
      manualOptions: [
        {
          value: "LOW",
          label: "PriorityLow",
        },
        {
          value: "MEDIUM",
          label: "PriorityMedium",
        },
        {
          value: "HIGH",
          label: "PriorityHigh",
        },
      ],
      viewList: ["read", "new", "newfromlist","edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },

    {
      value: "",
      title: "taskCreatedAt",
      model: "createdAt",
      type: "date",
      defaultReadonly: true,
      viewList: ["read"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "createdBy",
      model: "createdBy",
      optionSearchEndpoint: "uploader",
      type: "optionSearchEndpoint",
      defaultReadonly: true,
      viewList: ["read"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "deadline",
      model: "deadline",
      type: "date",
      viewList: ["read", "new", "newfromlist", "edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },

    // not known yet
    {
      value: "",
      title: "updatedAt",
      model: "updatedAt",
      type: "datetime-local",
      defaultReadonly: true,
      viewList: [],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "approvedAt",
      model: "approvedAt",
      type: "datetime-local",
      defaultReadonly: true,
      viewList: [],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "secondaryApprovedAt",
      model: "secondaryApprovedAt",
      type: "datetime-local",
      defaultReadonly: true,
      viewList: ["read"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "resolvedAt",
      model: "resolvedAt",
      type: "datetime-local",
      defaultReadonly: true,
      viewList: [],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
  ],
};
