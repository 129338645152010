export default {
  basic: {
    DATE: {
      name: "Dátum",
      config: {
        backendType: "DATE",
        backendName: "date",
        type: "date",
      },
    },
    USER_DATA: {
      name: "Felhasználó Kiválasztás",
      config: {
        backendType: "NUMBER",
        backendName: "userName",
        type: "optionSearchEndpoint",
        optionSearchEndpoint: "uploader",
      },
    },
    SINGLE_VALUE: {
      name: "Szöveges Mező",
      config: {
        backendType: "STRING",
        backendName: "userName",
        type: "text",
      },
    },
    OPTION_VALUE: {
      name: "Lenyíló Lista",
      config: {
        backendType: "NUMBER",
        backendName: "userName",
        type: "option",
      },
    },
  }
  
};
