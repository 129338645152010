let uniqueField = "premiseGrid";

import TenantConfigHandler from "@/misc/TenantConfigHandler";
import store from "@/misc/vuex-store";

export default (
  state,
  t,
  router,
  endpoint,
  basicDataPremiseFunctionMapping,
  basicDataCremClassMapping,
  basicDataStairwayTypeMapping,
  basicDataElevatorTypeMapping,
  basicDataFloorMapping,
  specialDataFloorCoveringMapping,
  specialDataFalseCeilingMapping,
  specialDataDoorTypeMapping,
  specialDataWindowMaterialMapping,
  specialDataSzepaMapping
) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + endpoint,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];
              //const config = state.value.data.find((e) => e.model == "PREMISE_ID")
              buttons.push({
                title: "",
                tooltip: t("message.open"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  store.commit("setRemTab", 1);
                  const routeData = router.resolve({
                    name: "premise-data",
                    params: {
                      buildingId: record.buildingId,
                      propertyId: record.propertyId,
                      premiseId: record.id
                    },
                  });
                  store.commit("setRemTab", 1);
                  window.open(routeData.href, "_blank");
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.delete"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/><path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  const config = state.value.data.PREMISE_ID;
                  let i = 0;
                  let found = false;

                  if(config.value.length == 1){
                    config.value = []
                  }

                  while (i < config.value.length && found == false) {
                    if (config.value[i] == record.id) {
                      config.value.splice(i, 1);
                    }
                    i++;
                  }
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Number",
        },
        exportable: false,
        orderable: true,
      },
      basicDataPremiseFunctionValue: {
        title: t("message.function"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPremiseFunctionValue
            ? record.basicDataPremiseFunctionValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataPremiseFunctionMapping,
        },
      },
      basicDataCremClassValue: {
        title: t("message.cremClassification"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataCremClassValue
            ? record.basicDataCremClassValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataCremClassMapping,
        },
      },
      basicDataIdentifier: {
        title: t("message.identifier"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataIdentifier
            ? record.basicDataIdentifier
            : "N/A";
        },
      },
      basicDataFloorValue: {
        title: t("message.level"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFloorValue
            ? record.basicDataFloorValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataFloorMapping,
        },
      },
      basicDataPremiseNumber: {
        title: t("message.premiseNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPremiseNumber
            ? record.basicDataPremiseNumber
            : "N/A";
        },
      },
      basicDataFloorArea: {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      basicDataStairwayIdentifier: {
        title: t("message.stairwayId"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStairwayIdentifier
            ? record.basicDataStairwayIdentifier
            : "N/A";
        },
      },
      basicDataStairwayTypeValue: {
        title: t("message.stairwayType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStairwayTypeValue
            ? record.basicDataStairwayTypeValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataStairwayTypeMapping,
        },
      },
      basicDataSmokeFree: {
        title: t("message.isSmokeFree"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataFireRoute: {
        title: t("message.isEscapeRoute"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataElevatorNumberPersons: {
        title: t("message.numberOfPerson"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorNumberPersons
            ? record.basicDataElevatorNumberPersons
            : "N/A";
        },
      },
      basicDataElevatorLoadCapacity: {
        title: t("message.loadCapacity"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorLoadCapacity
            ? record.basicDataElevatorLoadCapacity
            : "N/A";
        },
      },
      basicDataElevatorTypeValue: {
        title: t("message.elevatorType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorTypeValue
            ? record.basicDataElevatorTypeValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataElevatorTypeMapping,
        },
      },
      basicDataEmergencyElevator: {
        title: t("message.isEmergencyElevator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean,
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      basicDataAccessibility: {
        title: t("message.isAccessible"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      specialDataFloorCoveringValue: {
        title: t("message.floorCovering"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataFloorCoveringValue
            ? record.specialDataFloorCoveringValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataFloorCoveringMapping,
        },
      },
      specialDataFalseCeilingValue: {
        title: t("message.isFalseCeiling"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataFalseCeilingValue
            ? record.specialDataFalseCeilingValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataFalseCeilingMapping,
        },
      },
      specialDataNumberDedicated: {
        title: t("message.numberDedicated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataNumberCanBeSeated: {
        title: t("message.numberCanBeSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataNumberSeated: {
        title: t("message.numberSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataEntryNote: {
        title: t("message.notes"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataEntryNote
            ? record.specialDataEntryNote
            : "N/A";
        },
      },
      specialDataDoorTypeValue: {
        title: t("message.doorType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataDoorTypeValue
            ? record.specialDataDoorTypeValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataDoorTypeMapping,
        },
      },
      specialDataOpenableWindow: {
        title: t("message.openableWindow"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      specialDataWindowMaterialValue: {
        title: t("message.windowMaterial"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataWindowMaterialValue
            ? record.specialDataWindowMaterialValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataWindowMaterialMapping,
        },
      },
      specialDataNumberOfWindows: {
        title: t("message.windowNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataWindowWidth: {
        title: t("message.windowWidth"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataWindowHeight: {
        title: t("message.windowHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataWindowDepth: {
        title: t("message.windowDepth"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataMinHeight: {
        title: t("message.minHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataMaxHeight: {
        title: t("message.maxHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      specialDataSzepaValue: {
        title: t("message.szepaClass"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataSzepaValue
            ? record.specialDataSzepaValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataSzepaMapping,
        },
      },
      specialDataSharedUse: {
        title: t("message.sharedUse"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      specialDataDedicatedOrgUnit: {
        title: t("message.dedicatedOrgUnit"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      specialDataCanBeHeated: {
        title: t("message.canBeHeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
      specialDataCanBeCooled: {
        title: t("message.canBeCooled"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: "Status",
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            true: {
              title: t("message.yes"),
            },
            false: {
              title: t("message.no"),
            },
            null: {
              title: "N/A",
            },
          },
        },
      },
    },
  };
};
