import Axios from "@/misc/axios";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  propertyBasicData: [
    {
      title: "baseDatas",
      type: "label",
    },
    {
      title: "propertySheetAppellation",
      value: "",
      model: "propertySheetAppellation",
      type: "text",
    },
    {
      title: "nature",
      value: "",
      valueLabel: "",
      model: "type",
      type: "option",
      optionValue: "PROPERTY_TYPE",
    },
    {
      title: "classification",
      value: "",
      valueLabel: "",
      model: "classification",
      type: "option",
      optionValue: "PROPERTY_CLASSIFICATION",
    },
    {
      title: "status",
      value: "",
      valueLabel: "",
      model: "status",
      type: "option",
      optionValue: "PROPERTY_STATUS",
    },
    {
      title: "floorArea",
      value: "",
      valueLabel: "",
      model: "floorArea",
      type: "positiveNumber",
    },
    {
      title: "mtArea",
      value: "",
      valueLabel: "",
      model: "mtArea",
      type: "positiveNumber",
    },
    {
      title: "kng",
      value: "",
      valueLabel: "",
      model: "kng",
      type: "text",
    },
    {
      title: "floc",
      value: "",
      valueLabel: "",
      model: "floc",
      type: "text",
    },
    {
      title: "sap",
      value: "",
      valueLabel: "",
      model: "sap",
      type: "text",
    },
    {
      title: "protection",
      value: "",
      valueLabel: "",
      model: "protection",
      type: "checkbox",
    },
    {
      // TODO: maxlength 255??
      title: "protectionNote",
      value: "",
      valueLabel: "",
      model: "protectionNote",
      type: "text",
    },
    {
      title: "numberDedicated",
      value: "",
      valueLabel: "",
      model: "numberDedicated",
      type: "integer",
      helperText: "numberDedicatedHelperText"
    },
    {
      title: "numberCanBeSeated",
      value: "",
      valueLabel: "",
      model: "numberCanBeSeated",
      type: "integer",
      helperText: "numberCanBeSeatedHelperText"
    },
    {
      title: "numberSeated",
      value: "",
      valueLabel: "",
      model: "numberSeated",
      type: "integer",
      helperText: "numberSeatedHelperText"
    },
    {
      title: "solarPanel",
      value: "",
      valueLabel: "",
      model: "solarPanel",
      type: "checkbox",
    },
    {
      title: "fuelCellGenerator",
      value: "",
      valueLabel: "",
      model: "fuelCellGenerator",
      type: "checkbox",
    },
    {
      title: "dieselGenerator",
      value: "",
      valueLabel: "",
      model: "dieselGenerator",
      type: "checkbox",
    },
    {
      title: "energyTradingPartner",
      value: "",
      valueLabel: "",
      model: "energyTradingPartner",
      type: "checkbox",
    },
  ],
  propertyAddress: [
    {
      title: "primaryAddress",
      type: "label",
    },
    {
      title: "addressType",
      value: "",
      valueLabel: "",
      model: "addressVariety",
      type: "option",
      optionValue: "PROPERTY_ADDRESS_VARIETY",
      mandatory: true,
      validated: false,
    },
    {
      title: "country",
      value: "",
      valueLabel: "",
      model: "country",
      type: "option",
      optionValue: "COUNTRY",
      mandatory: true,
      validated: false,
    },
    {
      title: "postal",
      value: "",
      model: "postalCode",
      type: "text",
      mandatory: true,
      validated: false,
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue < 1000 || newValue > 9999) {
          inputError.value = "message.postalError";
        } else {
          inputError.value = "";
        }
      },
    },
    {
      title: "county",
      value: "",
      model: "county",
      mandatory: false,
      validated: true,
      type: "optionapi",
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,

        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {
              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  if(!options.value.some(o => o.label == elem.county)){
                    options.value.push({
                      label: elem.county,
                      value: elem.county,
                    });
                  }
                });
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].county;
                }
              }
            });
          } catch (error) {
            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "city",
      value: "",
      model: "city",
      type: "optionapi",
      mandatory: true,
      validated: false,
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,

        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {
              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  options.value.push({
                    label: elem.name,
                    value: elem.name,
                  });
                });
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].name;
                }
              }
            });
          } catch (error) {
            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "district",
      value: "",
      model: "district",
      type: "optionapi",
      mandatory: false,
      validated: true,
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,

        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {             
              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  options.value.push({
                    label: elem.district,
                    value: elem.district,
                  });
                });
                
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].district;
                }
              }
            });
          } catch (error) {
            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "mailboxNumber",
      value: "",
      model: "mailboxNumber",
      type: "text",
      mandatory: false,
      validated: true,
    },
    {
      title: "publicAreaName",
      value: "",
      model: "publicAreaName",
      type: "text",
      mandatory: false,
      validated: true,
    },
    {
      title: "publicAreaType",
      value: "",
      valueLabel: "",
      model: "publicAreaType",
      type: "option",
      searchable: true,
      optionValue: "PUBLIC_AREA_TYPE",
      mandatory: false,
      validated: true,
    },
    {
      title: "houseNumber",
      value: "",
      model: "houseNumber",
      type: "text",
      mandatory: false,
      validated: true,
    },
    {
      title: "stairway",
      value: "",
      model: "stairWay",
      type: "text",
    },
    {
      title: "floor",
      value: "",
      model: "floor",
      type: "text",
    },
    {
      title: "door",
      value: "",
      model: "door",
      type: "text",
    },
    {
      title: "parcelNumber",
      value: "",
      model: "parcelNumber",
      type: "text",
      mandatory: false,
      validated: true
    },
    {
      title: "gpsCoordinatesLatitude",
      value: "",
      model: "gpsCoordinatesLat",
      type: "text",
    },
    {
      title: "gpsCoordinatesLongitude",
      value: "",
      model: "gpsCoordinatesLon",
      type: "text",
    },
    {
      title: "notes",
      value: "",
      model: "notes",
      type: "text",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue.length > 2000) {
          inputError.value = "message.entryNoteWarning";
        } else {
          inputError.value = "";
        }
      },
    },
  ],
};
