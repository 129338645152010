
export default [
    {
      title: "function",
      value: "",
      valueLabel: "",
      model: "basicDataPremiseFunction",
      type: "option",
      searchable: true,
      optionValue: "PREMISE_FUNCTION",
    },
    {
      title: "cremClassification",
      value: "",
      valueLabel: "",
      model: "basicDataCremClass",
      type: "option",
      optionValue: "CREM_CLASSIFICATION",
    },
    {
      title: "identifier",
      value: "",
      model: "basicDataIdentifier",
      type: "text",
    },
    {
      title: "level",
      value: "",
      valueLabel: "",
      model: "basicDataFloor",
      type: "option",
      optionValue: "PREMISE_FLOOR",
    },
    {
      title: "premiseNumber",
      value: "",
      model: "basicDataPremiseNumber",
      type: "positiveNumber",
    },
    {
      title: "floorArea",
      value: "",
      model: "basicDataFloorArea",
      type: "positiveNumber",
    },
    {
      title: "isAccessible",
      value: "",
      model: "basicDataAccessibility",
      type: "checkbox",
    },
  ]