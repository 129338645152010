<template>
    <div>
        <input type="checkbox" class="modal-toggle" v-model="showModal" />
        <div class="modal">
            <div class="modal-box">
                <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
                    {{ $t(`message.assignTask`) }}
                </h3>
                <!-- eslint-disable -->
                <WFTitleField v-model:inputValue="assignUser" :config="config"
                    textsm="true"></WFTitleField>
                <!-- eslint-enable -->
                <div class="modal-action">
                    
                    <label class="btn btn-sm bg-magenta text-white font-light text-xs"
                        @click="confirmationTrueMethod">{{ $t("message.save") }}</label>
                    <label class="btn btn-sm text-white font-light text-xs" @click="showModal = false; assignUser = ''">{{
                        $t("message.cancel") }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import WFTitleField from './WFTitleField.vue';

import { defineModel, ref } from 'vue';
//import TenantConfigHandler from '../../../misc/TenantConfigHandler';

const showModal = defineModel('showModal');
//const taskId = defineModel('taskId');

const assignUser = ref("")

const config = {
    title: "user",
    optionSearchEndpoint: "uploader",
    type: "optionSearchEndpoint"
}


</script>
