<template>
  <div id="VocabularyAdminEditorPage" class="h-screen max-h-screen">
    <BaseLayout>
      <template #leftButtons>
        <button
          class="btn btn-secondary btn-sm btn-circle"
          @click="router.push({ name: 'vocabularyAdmin' })"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        </button>
      </template>
      <template #mainContent>
        <div class="h-full h-max-full mx-auto px-2 k">
          <h2 class="text-lg font-sans font-semibold uppercase text-secondary bg-gray-800 rounded-lg p-3 my-4">
            {{ $t('message.optionEditorPage') }}
          </h2>
          <div class="rounded-xl im-main-card mb-16">
            <h2 class="text-xl text-secondary w-full font-bold">
              {{ $t('message.newOptionValue') }}
            </h2>

            <div class="my-8">
              <label class="label mt-0 pt-0" for="">
                <span class="label-text-alt flex flex-row"
                  >{{ $t('message.value') }}
                  <span :class="{ 'im-mandatory': state.newOptionValue.value == '' }">{{
                    ' *'
                  }}</span>
                </span>
              </label>
              <input
                type="text"
                class="input input-sm input-bordered w-full"
                v-model="state.newOptionValue.value"
              />
            </div>
            <div class="mb-8" v-if="state.parentOptions.length > 0">
              <label class="label mt-0 pt-0" for="">
                <span class="label-text-alt flex flex-row"
                  >{{ $t('message.parent')
                  }}<span :class="{ 'im-mandatory': state.newOptionValue.parent == null || state.newOptionValue.parent == 'null' }">{{
                    ' *'
                  }}</span>
                </span>
              </label>
              <select
                class="input input-sm input-bordered w-full"
                name=""
                id=""
                v-model="state.newOptionValue.parent"
              >
                <option value="null">{{ $t('message.select') }}</option>
                <option
                  v-for="op in state.parentOptions"
                  v-bind:key="op.value + op.label"
                  :value="op.value"
                >
                  {{ op.label }}
                </option>
              </select>
            </div>
            <RemPrimaryButton
              :method="
                () => {
                  createOptionValue();
                }
              "
              :label="$t('message.save')"
            >
            </RemPrimaryButton>
          </div>
          <div>
            <TransitionGroup>
              <draggable
                v-model="state.data"
                group="people"
                @start="drag = true"
                @end="
                  drag = false;
                  saveOrder();
                "
                item-key="id"
              >
                <template #item="{ element, index }">
                  <div class="rounded-xl im-main-card im-main-card-starter mb-16">
                    <div class="flex justify-between">
                      <h2 class="text-xl text-secondary w-full font-bold">{{ element.value }}</h2>

                      <a
                        class="btn btn-sm btn-secondary btn-circle flex-0"
                        @click="
                          state.data[index].openPanel = !state.data[index].openPanel;
                          fetchTranslations(index);
                        "
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
                          />
                        </svg>
                      </a>
                    </div>

                    <div v-if="element.openPanel == true">
                      <!-----                 EDIT OPTIONVALUE              ---->
                      <div class="my-8">
                        <label class="label mt-0 pt-0" for="">
                          <span class="label-text-alt flex flex-row"
                            >{{ $t('message.value') }}
                            <span :class="{ 'im-mandatory': element.value == '' }">{{ ' *' }}</span>
                          </span>
                        </label>
                        <input
                          type="text"
                          class="input input-sm input-bordered w-full"
                          v-model="element.value"
                        />
                      </div>

                      <div class="mb-8" v-if="state.parentOptions.length > 0">
                        <label class="label mt-0 pt-0" for="">
                          <span class="label-text-alt flex flex-row"
                            >{{ $t('message.parent') }}
                            <span :class="{ 'im-mandatory': element.parentDto.id == 'null' }">{{
                              ' *'
                            }}</span>
                          </span>
                        </label>
                        <select
                          class="input input-sm input-bordered w-full"
                          name=""
                          id=""
                          v-model="element.parentDto.id"
                        >
                          <option value="null">{{ $t('message.select') }}</option>
                          <option
                            v-for="op in state.parentOptions"
                            v-bind:key="op.value + op.label"
                            :value="op.value"
                          >
                            {{ op.label }}
                          </option>
                        </select>
                      </div>

                      <RemPrimaryButton
                        class="mr-4"
                        :method="
                          () => {
                            updateOptionValue(element);
                          }
                        "
                        :label="$t('message.save')"
                      ></RemPrimaryButton>

                      <span v-if="element.code != 'null'">
                        {{ $t("message.cannotDeleteOptionWithCode") }}
                      </span>
                      <RemPrimaryButton
                        v-else
                        :method="
                          () => {
                            deleteOptionValue(index);
                          }
                        "
                        :label="$t('message.delete')"
                      ></RemPrimaryButton>

                      <div class="my-8">
                        <label class="label mt-0 pt-0" for="">
                          <span class="label-text-alt flex flex-row">{{
                            $t('message.order')
                          }}</span>
                        </label>
                        <input
                          type="text"
                          class="input input-sm input-bordered w-full"
                          v-model="element.index"
                        />
                      </div>
                      <RemPrimaryButton
                        class="mr-4"
                        :method="
                          () => {
                            sendToIndex(element.index, index);
                          }
                        "
                        :label="$t('message.sendToOrder')"
                      ></RemPrimaryButton>

                      <!-----             TRANSLATION VALUES              ---->
                      <h3 class="text-md text-white w-full font-bold my-16">
                        {{ $t('message.translations') }}
                      </h3>
                      <div v-for="translation in element.translations" v-bind:key="translation.id">
                        <div class="input-group my-8">
                          <input
                            class="input input-bordered"
                            type="text"
                            v-model="translation.translatedValue"
                          />
                          <label class="btn btn-secondary">
                            {{ translation.languageCode }}
                          </label>
                          <button
                            class="btn btn-secondary"
                            @click="
                              () => {
                                updateOptionValueTranslation(translation);
                              }
                            "
                          >
                            {{ $t('message.save') }}
                          </button>
                        </div>
                      </div>

                      <hr />

                      <!-----             NEW TRANSLATION              ---->

                      <div class="input-group my-8">
                        <input
                          class="input input-bordered"
                          type="text"
                          v-model="element.newValue"
                        />
                        <select
                          class="input input-bordered btn-secondary"
                          v-model="element.newLanguage"
                        >
                          <option v-for="language in languages" v-bind:key="language">
                            {{ language }}
                          </option>
                        </select>

                        <button
                          class="btn btn-secondary"
                          @click="
                            () => {
                              addTranslationElement(index);
                            }
                          "
                        >
                          {{ $t('message.newOptionValue') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </TransitionGroup>
          </div>
        </div>
        <div :style="'height:' + state.data.length * 12 + 'rem'"></div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
//components
import BaseLayout from '../layouts/BaseLayout.vue';
import draggable from 'vuedraggable';
import RemPrimaryButton from '../components/REM/RemPrimaryButton.vue';

//utils
import router from '../misc/router';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import optionValueUtils from './VocabularyAdminEditorComponents/optionValueUtils';

const { t } = useI18n();

const route = useRoute();
const optionType = route.params.optionType;

const languages = ['en', 'de', 'hu', 'es', 'el'];

const state = ref({
  data: [],
  parentOptions: [],
  panel: null,
  newOptionValue: {
    value: '',
    parent: null,
  },
});

function setup() {
  optionValueUtils.setup(state, optionType);
}

setup();

// savers

async function updateOptionValue(optionValue) {
  optionValueUtils.updateOptionValue(state.value.parentOptions.length > 0, optionValue, t);
}

async function deleteOptionValue(index) {
  optionValueUtils.deleteOptionValue(state, index, optionType, t);
}

async function createOptionValue() {
  optionValueUtils.createOptionValue(state, optionType, t);
}

function saveOrder() {
  optionValueUtils.saveOrder(state, optionType, t);
}

function fetchTranslations(index) {
  optionValueUtils.fetchTranslations(index, state);
}

function addTranslationElement(index) {
  optionValueUtils.addTranslationElement(index, state, t);
}

function updateOptionValueTranslation(translation) {
  optionValueUtils.updateOptionValueTranslation(translation, t);
}

function sendToIndex(newIndex, index) {
  newIndex--;
  let temp = state.value.data[newIndex];
  state.value.data[newIndex] = state.value.data[index];
  state.value.data[index] = temp;
  saveOrder();
}
</script>

<style scoped>
.im-main-card {
  --tw-bg-opacity: 1;
  transition: height 0.5s ease-out 0;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  height: fit-content;
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-mandatory {
  color: red;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.fade-enter-active {
  transition:
    opacity 1s ease 1s,
    padding 1s ease 1s,
    width 1s ease 1s;
}

.fade-leave-active {
  transition:
    opacity 0.5s ease,
    padding 0.5s ease,
    width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}
</style>
