<template>
  <div id="WFVersions" class="h-screen max-h-screen">
    <BaseLayout>
      <template #leftButtons> </template>

      <template #mainContent>
        <div class="h-full h-max-full mx-auto px-2">
          <div class="bg-gray-800 px-6 pt-2 pb-6 rounded-lg mb-2">
            <div class="flex justify-between">
              <h1 class="text-lg font-sans font-semibold uppercase text-white my-8">
                {{ state.taskType?.name }} ({{ state.taskType?.shortName }}) -
                {{ $t('message.wfVersions') }}
              </h1>
              <RemUpButton remType="wf-versions" class="pt-4" />
            </div>

            <RemPrimaryButtonSmall
              class="mr-4"
              :label="'Import'"
              :method="
                () => {
                  importVersions();
                }
              "
            ></RemPrimaryButtonSmall>

            <RemPrimaryButtonSmall
              class="mr-4"
              :label="'Export'"
              :method="
                () => {
                  exportVersions();
                }
              "
            ></RemPrimaryButtonSmall>

            <RemPrimaryButtonSmall
              class="mr-4"
              :label="$t('message.addNew')"
              :method="
                () => {
                  addNewVersion();
                }
              "
            ></RemPrimaryButtonSmall>
          </div>

          <RobberVueGrid
            v-if="!state.forceRefresh"
            ref="wfVersionsGrid"
            :config="wfVersionsGridConfigComputed"
          />
        </div>

        <AddWorkflowModal
          @refreshGrid="refreshGrid"
          :showModal="modalStatus"
          :wfVersionsGrid="wfVersionsGrid"
          :workflowById="state.workflowById"
          :editMode="state.editMode"
        />
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import RemUpButton from '@/components/REM/RemUpButton.vue';
import BaseLayout from '@/layouts/BaseLayout.vue';
import RobberVueGrid from '@/components/RVG/RobberVueGrid.vue';
import RemPrimaryButtonSmall from '../../components/REM/RemPrimaryButtonSmall.vue';
import AddWorkflowModal from './AddWorkflowModal.vue';

// utils
// import { computedAsync } from '@vueuse/core'
// import RVGUtils from "../../components/RVG/RobberVueGridUtils.js";
// import router from "@/misc/router";
import { useRoute } from 'vue-router';
import { ref, computed, onMounted } from 'vue';
//import User from "../misc/User";
import toast from '@/misc/toast';
import Axios from '@/misc/axios';
import store from '@/misc/vuex-store';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// RVGS
import wfVersionsGridConfig from '@/misc/gridConfigs/workflowAdmin/wfVersionsGridConfig.js';

const wfVersionsGrid = ref();

const router = useRoute();
const taskTypeId = router.params.taskTypeId;

const state = ref({
  workflowById: null,
  editMode: false,
  forceRefresh: false,
  taskType: null,
});

const getTaskType = async () => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}`;

  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        getTaskType();
      },
    });

    state.value.taskType = response.data;
  } catch (error) {
    console.log(error);
  }
};

const addNewVersion = () => {
  state.value.editMode = false;
  store.commit('setIsModalOpen', true);
};

const importVersions = () => {
  alert('TODO: Import');
};

const exportVersions = () => {
  alert('TODO: Export');
};

const refreshGrid = () => {
  state.value.forceRefresh = true;
  setTimeout(() => {
    state.value.forceRefresh = false;
  }, 100);
};

const editWorkflow = async (id) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${id}`;

  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        editWorkflow(id);
      },
    });
    state.value.workflowById = response.data;
  } catch (e) {
    console.log(e.response.data.message);
    if (e.response.data.message == 'Workflow is not editable in ACTIVE status.') {
      toast.error(t('message.activeNotEditable'));
    } else if (e.response.data.message == 'Workflow is not editable in INACTIVE status.') {
      toast.error(t('message.inactiveNotEditable'));
    } else {
      toast.error(t('message.notSuccessfulSave'));
    }
  }

  state.value.editMode = true;
};

const wfVersionsGridConfigComputed = computed(() => {
  return wfVersionsGridConfig(state, t, taskTypeId, refreshGrid, editWorkflow);
});

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});

onMounted(() => {
  getTaskType();
});
</script>

<style scoped></style>
