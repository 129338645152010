<template>
  
  <div class="dropdown">
      <select v-model="state.selectedOption" class="input input-sm input-bordered w-full pr-2" >
          <option v-for="element in filteredSuggestions" v-bind:key="element.id" :value="element.id">
            {{ element.label }}
          </option>
      </select>
      <button v-if="state.selectedOption" class="clear-button" @click="state.selectedOption = null" :aria-label="t('message.delete')">
          <span class="text-sm bold">
              X
          </span>
      </button>
  </div>
</template>

<script setup>
import 'vue-select/dist/vue-select.css';
import { reactive, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps(['modelValue', 'suggestions', 'parentId', 'hasParent']);
const emits = defineEmits(['update:modelValue']);

const state = reactive({
  selectedOption: props.modelValue || null,
});

const filteredSuggestions = computed(() => {
  const suggestions = props.suggestions.map((suggestion) => ({
    ...suggestion,
    label: suggestion.value + (suggestion.deleted ? t('message.inactiveLabel') : ''),
  }));
  if (props.hasParent) {
    return suggestions.filter((suggestion) => suggestion.parent.id === props.parentId);
  }
  return suggestions;
});

watch(
  () => props.modelValue,
  (newValue) => {
    state.selectedOption = props.suggestions.find((option) => option.id === newValue)?.id || null;
  }
);

watch(
  () => state.selectedOption,
  (newValue) => {
    if (newValue) {
      emits('update:modelValue', newValue);
    } else {
      emits('update:modelValue', null);
    }
  }
);
</script>


<style scoped>
.dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.clear-button {
    position: absolute;
    right: 0.6rem;
    background: none;
    border: none;
    color: rgb(175, 170, 170);
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 1;
}

.clear-button:hover {
    cursor: pointer;
}

.dropdown::before {
    position: absolute;
    font-size: 12px;
    content: " \25BC";
    top: 7px;
    right: -8px;
    height: 20px;
    width: 28px;
    pointer-events: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
</style>