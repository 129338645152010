
export default {
  possibleOperations() {
    return {
      'eq': "megegyezik ezzel:"
    };
  },
  getTextual(operation, currentValue, formatter) {
    const possibleOperations = this.possibleOperations();
    
    let value = currentValue;
    if (formatter) {
      value = formatter.mapping[''+currentValue].title;
    }
    
    return '%%fieldname%% ' + possibleOperations[operation] + ' ' + value
  },
  isMatch(recordValue, operation, matchingValue) {
    switch(operation) {
      case 'eq':
        return recordValue == matchingValue;
    }
  }
}